import React from 'react';

const StarRatingFromUserComponent = ({rating}) => {
    const fullStars = Array.from({length: 5}, (_, i) => i + 1);

    return (
        <>
            <div className="star-rating">
                {fullStars.slice(0, rating).map((star, index) => (
                    <span key={index} className="text-emerald-700 text-lg ml-1">
          <i className="fas fa-star"></i>
        </span>
                ))}
                {fullStars.length > rating &&
                    fullStars.slice(rating).map((star, index) => (
                        <span key={index} className="text-gray-400 text-lg ml-1">
            <i className="far fa-star"></i>
          </span>
                    ))}
            </div>


        </>
    )
        ;

};

export default StarRatingFromUserComponent;