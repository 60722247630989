import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    cityZip: [],
    error: '',
    success: false,
}

const url = `${BASE_URL}/cityzip/`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}

export const createCityZip = createAsyncThunk(
  "create/cityzip", async (formData) => {
    return axios
    .post(url, formData, config)
    .then((response) => response)
  }
)

export const updateCityZip = createAsyncThunk(
  "update/cityzip", async (formData) => {
    return axios
    .put(`${url}${formData.id}`, formData, config)
    // .then((response) => formData)
    .then((response) => { 
      response.data.localData = formData.localData;
      return response.data;
  })
  }
)

export const deleteCityZip = createAsyncThunk(
  "delete/cityzip", async (formData) => { 
    return axios
    .delete(`${url}${formData.id}`,  config)
    .then((response) => { 
    // response.data.localData = formData.localData;
    return formData; 
     })
  }
)

export const fetchCityZip = createAsyncThunk("fetch/cityzip", async () => {
  return axios
  .get(url, config)
  .then((response) => response.data);
});

const cityZipSlice = createSlice({
  name: "cityzip",
   initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchCityZip.pending, (state) => {
      state.loading = true;
      state.success = false
    });
    builder.addCase(fetchCityZip.fulfilled, (state, action) => {
      state.loading = false;
      state.cityZip = action.payload;
      state.error = "";
    });

    builder.addCase(fetchCityZip.rejected, (state, action) => {
      state.loading = false;
      // state.cityZip = [];
      state.error = action.error;
    });

    builder.addCase(createCityZip.pending, (state) => {
      state.loading = true;
      state.success = false
    });
    builder.addCase(createCityZip.fulfilled, (state, action) => {
      state.loading = false;
      state.cityZip.push(action.payload.data);
      state.error = "";
      state.success = true
    });
    
    builder.addCase(createCityZip.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
 
    });
    
    builder.addCase(updateCityZip.fulfilled, (state, action) => {
      state.loading = false;
      state.cityZip[action.payload.localData] = action.payload;
      state.error = "";
      console.log(action.payload)
    });
 
    builder.addCase(updateCityZip.rejected, (state, action) => {
      state.loading = false;
      // state.cityZip = [];
      state.error = action.error;
 
    });
    
    builder.addCase(deleteCityZip.fulfilled, (state, action) => {
      state.loading = false;
      state.cityZip.splice(action.payload.localData, 1);
      console.log(action.payload);
      state.error = "";
    });
        
    builder.addCase(deleteCityZip.rejected, (state, action) => {
      state.loading = false;
      // state.cityZip = [];
      state.error = action.error;
      console.log(action.payload);
 
    });
  },
});

export default cityZipSlice.reducer;