// import { Modal, Button, Image } from "react-bootstrap";
// import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
   
import { useLocation, useParams} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from "react";
import { useFormik } from "formik";

// import AreaSelectForm from './AreaSelectForm'; 
// import AreaForm from './AreaForm';
import TestingZone from "./component/TestingZone";
// import TestingZone3 from "./TestingZone3";
import {breadcrumbTree} from '../../reducers/LocalDataSlice';
import LoadingComponent from "../../modal/LoadingComponent";
// import { fetchServiceData } from '../../reducers/ServiceSlice'
// import { createServiceTest } from '../../reducers/FormBuilderSlice';
import { fetchArea, storeState, storeCity } from "../../reducers/AreaSlice";
import Layout from '../../components/Layout';
const AreaConfiguration = ({ serviceTextId, close, newItemAddStatus}) => { 
  const {loading, address} = useSelector((state) => state.area);
  // const [show, setShow] = useState(false); 
  const [data, setData] = useState(false); 
  const [area, setArea] = useState(false); 
  // const [areaTesting, setAreaTesting] = useState(false);
  // const [areaSelect, setAreaSelect] = useState(false);
  const dispatch = useDispatch();
  // const [editorData, setEditorData] = useState('');
  let {state} = useLocation();
  const { title, textId } = state;
    const {id} = useParams();
  let navTree = { ...state.navTree, [title]: textId };

    // console.log('dddddd:',state)
  // const handleArea = () => setArea(area === true && false);
    // const handleClose = () => setShow(false);
   
 
    useEffect(() => {  
      // let url = (loca.length > 3 ? loca[loca.length-1]:'root')
        // dispatch(fetchServiceData(`${loca[loca.length-1]}`))
      dispatch(breadcrumbTree(navTree));
    }, [dispatch])
 

        useEffect(() => {
        const res = dispatch(fetchArea(id));
        res.then((results) => {
            // console.log(results.payload)
            if(results.error){}else{
            setData(results.payload)
            dispatch(storeCity(results.payload.cityArray.filter(item => item.isChecked ===true)))
            dispatch(storeState(results.payload.stateArray))
            }
        })
        res.catch((error) => {
            // console.error('Promise rejected:', error);
        });
    }, [dispatch])

  return (
    <>
        <Layout component={ <>
    {/*{!area && <button onClick={handleArea}  type="button" className="new-configuration-btn add-new"> Add Area</button>}*/}
    {/*{!area && <AreaSelectForm  parentTextId={serviceTextId} close={close}/>}*/}
    {data && <TestingZone address={address} data={data} parentTextId={serviceTextId} close={close}/>}
    {/* {data && <UpdateAreaComponent address={address} data={data} parentTextId={serviceTextId} close={close}/>} */}
    {/*{!area && <TestingZone3  parentTextId={serviceTextId} close={close}/>}*/}

    {/* {area && <AreaForm props={serviceTextId} close={close}/>} */}

    {loading && <LoadingComponent message={''}/>}
            </>
        }></Layout>
    </>
  );
};

export default AreaConfiguration;
