import { Form, Modal, Button } from "react-bootstrap"

const RoleForm = ({roleData, handleClose, show, handleSubmit}) => {
  return (
    <>
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {roleData.textId != null ? 
                <Form.Control type="hidden" name="previousTextId" defaultValue={roleData.textId}></Form.Control>
              : <></>
            }
            <Form.Label htmlFor="textId">TextId</Form.Label>
            <Form.Control type="text" name="textId" defaultValue={roleData.textId}></Form.Control>
            <Form.Label htmlFor="title">Title</Form.Label>
            <Form.Control type="text" name="title" defaultValue={roleData.title}></Form.Control>
            <Button type="submit" className="mt-2">Submit</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RoleForm