import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../BaseUrl";

const initialState = {
    loading: false,
    customers: [],
    error: '',
    profile: '',
    reviewRatingData: '',
}

const url = `${BASE_URL}/enduser`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchEndUser = createAsyncThunk(
    'fetch/customers', async () => {
        return axios
            .get(`${url}/`, config)
            .then((response) => response.data)
    }
)

export const fetchUserProfile = createAsyncThunk(
    'fetch/UserProfilePage', async (textId) => {
        return axios
            .get(`${url}/${textId}/profileDetails/`, config)
            .then((response) => response.data)
    }
)

export const fetchUserWiseReviewRatingApi = createAsyncThunk(
    'fetch/UserWiseReviewRatingList', async (textId) => {
        return axios
            .get(`${url}/${textId}/reviewRatingList/`, config)
            .then((response) => response.data)
    }
)

export const favouriteServiceListApi = createAsyncThunk(
    'fetch/favouriteServiceList', async (textId) => {
        return axios
            .get(`${url}/${textId}/favouriteServiceList/`, config)
            .then((response) => response.data)
    }
)


const customersSlice = createSlice({
    name: "customers",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchEndUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchEndUser.fulfilled, (state, action) => {
            state.loading = false;
            state.customers = action.payload;
            state.error = "";
        });
        builder.addCase(fetchEndUser.rejected, (state, action) => {
            state.loading = false;
            state.customers = [];
            state.error = action.error;
        });

        builder.addCase(fetchUserProfile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.profile = action.payload;
            state.error = "";
            console.log('fetchUserProfile', action.payload)
        });
        builder.addCase(fetchUserProfile.rejected, (state, action) => {
            state.loading = false;
            state.profile = false;
            state.error = action.error;
        });



        builder.addCase(fetchUserWiseReviewRatingApi.fulfilled, (state, action) => {
            // state.loading = false;
            state.reviewRatingData = action.payload;
            // state.error = "";
        });
        builder.addCase(fetchUserWiseReviewRatingApi.rejected, (state, action) => {

            state.profile = false;
            state.error = action.error;
        });

        builder.addCase(favouriteServiceListApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(favouriteServiceListApi.fulfilled, (state, action) => {
            state.loading = false;
            state.serviceList = action.payload;
            state.error = "";
        });
        builder.addCase(favouriteServiceListApi.rejected, (state, action) => {
            state.loading = false;
            state.profile = false;
            state.error = action.error;
        });
    },
});


export default customersSlice.reducer


 
