import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../BaseUrl";
import React from "react";

const initialState = {
    loading: false,
    areas: [],
    address: [],
    states: [],
    cities: [],
    zip_codes: [],
    data: { },
    error: '',
    success: false,
    configuration_data: false
}

const url = `${BASE_URL}/area/`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const selectArea = createAsyncThunk("create/selectArea", async (formData) => {
    console.log(formData);
    return axios
        .post(`${BASE_URL}/service/${formData.textId}/ServicesAreaManagement`, formData, config)
        .then((response) => response.data)
})


export const createArea = createAsyncThunk("create/area", async (formData) => {
    console.log(formData);
    return axios
        .post(`${url}${formData.textId}/ServicesAreaManagement`, formData, config)
        .then((response) => response.data)
})


export const createServicenur = createAsyncThunk("create/serviceItem", async (formData) => {
    const akash = {
        "serviceItemTextId": "2526c620c18174be05e572dd652ae6",
        "categoryTextId": "HouseCategory1",
        "title": "nur service wer11zz",
        "rank": null,
        "status": null,
        "itemDescription": "<p>nur service wer</p>",
        "pricing_by": "areaSize",
        "image": "iuytrew",
        "icon": "oiuytrew",
        "Basic": "gdfhf",
        "Deep": "asfds"
    }
    return axios
        .post(`${BASE_URL}/service/${formData.categoryTextId}/CategoryService`, akash, config)
        .then((response) => console.log(response.data))
})


export const updateArea = createAsyncThunk("update/area", async (formData) => {
    return axios
        .put(`${url}${formData.textId}`, formData, config)
        .then((response) => {
            response.data.localData = formData.localData;
            return response.data;
        })
})


export const deleteArea = createAsyncThunk("delete/area", async (formData) => {
    return axios
        .delete(`${url}${formData.textId}`, config)
        .then((response) => {
            // response.data.localData = formData.localData;
            return formData;
        })
})
export const removeCity = createAsyncThunk("delete/city", async (formData) => {
    return axios
        // .delete(`${url}${formData.textId}`, config)
        .post(`${BASE_URL}/service/deleteServiceCity/`, formData, config)
        .then((response) => {
            // response.data.localData = formData.localData;
            return formData;
        })
})
export const removeZip = createAsyncThunk("delete/zip", async (formData) => {
    return axios
        // .delete(`${url}${formData.textId}`, config)
        .post(`${BASE_URL}/service/deleteServiceZip/`, formData, config)
        .then((response) => {
            // response.data.localData = formData.localData;
            return formData;
        })
})
export const removeState = createAsyncThunk("delete/city", async (formData) => {
    return axios
        // .delete(`${url}${formData.textId}`, config)
        .post(`${BASE_URL}/service/deleteServiceState/`, formData, config)
        .then((response) => {
            // response.data.localData = formData.localData;
            return formData;
        })
})


export const fetchArea = createAsyncThunk('fetch/area', async (param) => {
    return axios
        .get(`${BASE_URL}/service/${param}/ServicesAreaManagement`, config)
        .then((response) => response.data)
})
export const fetchStateListApi = createAsyncThunk('fetch/state', async (param) => {
    return axios
        .get(`${BASE_URL}/service/loadCountryStateList/${param}`, config)
        .then((response) => response.data)
})
export const fetchCityListApi = createAsyncThunk('fetch/cityList', async (param) => {
    return axios
        .post(`${BASE_URL}/service/loadStateCityList/`, param, config)
        .then((response) => response.data)
})
export const fetchZipListApi = createAsyncThunk('fetch/zipList', async (param) => {
    console.log(param)
    return axios
        .post(`${BASE_URL}/service/loadCityZipList/`, param, config)
        .then((response) => response.data)
})

export const zipPauseUnpause = createAsyncThunk('create/zipPauseUnpause', async (param) => {
    return axios
        .post(`${BASE_URL}/service/zipPauseUnpause/`, param, config)
        .then((response) => response.data)
})

export const cityPauseUnpause = createAsyncThunk('create/cityPauseUnpause', async (param) => {
    return axios
        .post(`${BASE_URL}/service/cityPauseUnpause/`, param, config)
        .then((response) => response.data)
})

export const statePauseUnpause = createAsyncThunk('create/statePauseUnpause', async (param) => {
    return axios
        .post(`${BASE_URL}/service/statePauseUnpause/`, param, config)
        .then((response) => response.data)
})


const areaSlice = createSlice({
    name: "area", initialState, reducers: {
        storeCity(state, action) {
            state.cityList = action.payload;
        }, 
        storeState(state, action) {
            state.stateList = action.payload;
        }, 
        changeCountry() {

        }, 
        countryAdd(state, action) {
            state.address = action.payload;
        }, 
        
        appendState(state, action) {
            let country = action.payload[0];
            let cState = action.payload[1];
            let textId = cState.replace(' ', '');
            state.address[country].states.push({c_state: cState, textId: textId, cities: []});
        }, 
        
        appendCity(state, action) {
            let country = action.payload[0];
            let cState = action.payload[1];
            let city = action.payload[2];
            let textId = city.replace(' ', '');
            console.log(action.payload)
            state.address[country].states[0].cities.push({city: city, textId: textId, zip_codes: []});
        }, 
        
        appendZip(state, action) {
            let country = action.payload[0];
            let cState = action.payload[1];
            let city = action.payload[2];
            let zip = action.payload[3];
            console.log(action.payload)
            state.address[country].states[0].cities[0].zip_codes.push(zip);
        },


        createStateList(state, action) {
            let data = action.payload;
            // console.log(data)
            // data = data.split(",");
            let country = data[0];
            let item = data[1];
            let name = data[2];
            //  console.log(data);
            if (data[3] === true) {
                state.states[name] = state.address[country].states[item];
                if (state.data.states[state.address[country].country_iso_code]) {
                    state.data.states[state.address[country].country_iso_code].push(name);
                } else {
                    state.data.states[state.address[country].country_iso_code] = [name];
                }
            } else {
                delete state.states[name];
                state.data.states[state.address[country].country_iso_code].splice(name, 1)
            }
        },


        createCityList(state, action) {
            let data = action.payload;
            // console.log(data)
            // data = data.split(",");
            let sta = data[0];
            let item = data[1];
            let name = data[2];
            //  console.log(data);
            if (data[3] === true) {
                state.cities[name] = state.states[sta].cities[item];
                if (state.data.cities[sta]) {
                    state.data.cities[state.states[sta].textId].push(name);
                } else {
                    state.data.cities[state.states[sta].textId] = [name];
                }
            } else {
                delete state.cities[name];
                state.data.cities[state.states[sta].textId].splice(name, 1)
            }
            // state.zip_codes.push(action.payload);
        },

        createZipList(state, action) {
            let data = action.payload;
            let sta = data[0];
            let item = data[1];
            let name = data[2];
            // console.log('state.cities[sta].textId', data)
            // const index = state.data.zip_codes[sta].indexOf(name);
            if (data[3] === true) {
                if (state.data.zip_codes[sta]) {
                    state.data.zip_codes[sta].push({name: name});
                } else {
                    state.data.zip_codes[sta] = [{name: name}];
                }
            } else {
                const index = state.data.zip_codes[sta].indexOf(name);
                console.log(index)
                // zipCodes.splice(index, 1);
                state.data.zip_codes[sta].splice(index, 1)
            }
        },

        selectAllZipCodes(state, action) {
            let data = action.payload;
            let allZip = [];


            // Object.keys(data).forEach((city,index) => {
            //     allZip=({
            //         city : data[city].zip_codes
            //     });
            // });


            Object.keys(data).forEach((city) => {
                allZip[city] = data[city].zip_codes;

            });


            // Update state with the new structure
            state.data.zip_codes = allZip;
        },
        removeItemInStateList(state, action) {
            console.log(action.payload)
            // state.states=(action.payload);
        },
    }, extraReducers: (builder) => {

        builder.addCase(fetchArea.pending, (state) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(fetchArea.fulfilled, (state, action) => {
            state.loading = false;
            state.address = action.payload.country_list;
            state.error = "";
            // console.log("action.payload.country_list")
        });
        builder.addCase(fetchArea.rejected, (state, action) => {
            state.loading = false;
            state.areas = [];
            state.error = action.error;
        });

        builder.addCase(fetchStateListApi.pending, (state) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(fetchStateListApi.fulfilled, (state, action) => {
            state.loading = false;
            state.states = action.payload.state_list;
            state.error = "";
        });
        builder.addCase(fetchStateListApi.rejected, (state, action) => {
            state.loading = false;
            state.states = [];
            state.error = action.error;
        });


        builder.addCase(fetchCityListApi.pending, (state) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(fetchCityListApi.fulfilled, (state, action) => {
            state.loading = false;
            state.cities.push(action.payload);
            state.error = "";
        });
        builder.addCase(fetchCityListApi.rejected, (state, action) => {
            state.loading = false;
            state.states = [];
            state.error = action.error;
        });
        builder.addCase(fetchZipListApi.pending, (state) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(fetchZipListApi.fulfilled, (state, action) => {
            state.loading = false;
            state.zip_codes.push(action.payload);
            state.error = "";
        });
        builder.addCase(fetchZipListApi.rejected, (state, action) => {
            state.loading = false;
            state.states = [];
            state.error = action.error;
        });

        builder.addCase(selectArea.pending, (state) => {
            state.loading = true;
            state.success = false;
   
            // state.cities = []
        });
        builder.addCase(selectArea.fulfilled, (state, action) => {
            state.loading = false;
            // state.areas=[];
            state.areas.push(action.payload);
            state.error = "";
            state.success = true;
        });
        builder.addCase(selectArea.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.success = false;
        });


        builder.addCase(updateArea.fulfilled, (state, action) => {
            state.loading = false;
            state.areas[action.payload.localData] = action.payload;
            state.error = "";
            console.log(action.payload);
        });

        builder.addCase(updateArea.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            console.log(action.error);
        });

        builder.addCase(deleteArea.fulfilled, (state, action) => {
            state.loading = false;
            state.areas.splice(action.payload.localData, 1);
            state.error = "";
            console.log(action.payload);
        });

        builder.addCase(deleteArea.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            console.log(action.error);
        });

    },
});


export const {
    storeCity,
    storeState,
    changeCountry,
    countryAdd,
    appendState,
    appendCity,
    appendZip,
    createStateList,
    createZipList,
    createCityList,
    removeItemInStateList,
    selectAllZipCodes
} = areaSlice.actions;

export default areaSlice.reducer