import {Form, Modal, Button, Image} from "react-bootstrap";
import Layout from '../../components/Layout';
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {useFormik} from "formik";
import {breadcrumbTree} from '../../reducers/LocalDataSlice'
import {fetchServices,  fetchServiceDataForUpdate, createService} from "../../reducers/ServiceItemSlice";
import {appendNewServices, fetchServiceWithCategory, successConfirmation} from '../../reducers/CategorySlice';
import ServicePlanAddComponent from "../../component/ServicePlanAddComponent";
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import SelectServiceItemComponent from "../../component/SelectServiceItemComponent";
import EditServiceForm from './component/EditServiceForm';


const EditServicePage = () => {
    const { error, categories, serviceList, configuration, title} = useSelector((state) => state.category)
    const {configuration_data, loading, success, serviceDetails} = useSelector((state) => state.serviceItem);
    const [breadcrumb, setBreadcrumb] = useState(false);
    const { state, location } = useLocation();
    const [activePlan, setActivePlan] = useState(state.pricingBy);
    const [servicePlan, setServicePlan] = useState([]);
    const [selected, setSelected] = useState();
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState('');
    const [thumbIcon, setIcon] = useState(false);
    const [thumbImage, setImage] = useState(false);
   

    const createNewItem = (planName, details) => {
        return { planName, details };
    };

    const addServicePlanItem = (planName, details) => {
        const newItem = createNewItem(planName, details);
        setServicePlan((prevServicePlan) => [...prevServicePlan, newItem]);
    };


    //  {serviceDetails && setSelected(serviceDetails.service_data.areaSizeUnit)}

    // let location = useLocation();
    // console.log('serviceDetails', serviceDetails)

   
    // const loca = location.pathname.split("/");
    // const token = localStorage.getItem("token")
    const {item, service = 'root'} = useParams();
    const navigate = useNavigate();
    const [clickCount, setClickCount] = useState(1);

    const [successModal, setSuccessModal] = useState(false);
    const handleSuccess = ()=>dispatch(successConfirmation());
 
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        handleSuccess()
        // console.log('successModal', successModal)
        navigate(successModal);
    };
    // console.log('item:', item)
    // console.log('service:', service)

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const imageType = /^image\//;
        if (imageType.test(file.type)) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImage(e.target.result);
            };

            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };
   
    //  const initialData= {
    //         serviceItemTextId: item,
    //         categoryTextId: service,
    //         title: serviceDetails.service_data.title,
    //         rank: null,
    //         status: null,
    //         itemDescription: null,
    //         service_plan: null,
    //         sub_items: null,
    //         pricing_by: 'perUnit',
    //         image: null,
    //         areaSizeUnit: null,
    //     }

    useEffect(() => {
        // dispatch(fetchServices(state.categoryTextId))
        dispatch(fetchServiceDataForUpdate(service))
        document.title = ''
        // dispatch(breadcrumbTree({
        //     'Service ': 'service',
        //     // [title]: subItem
        // }));

    }, [dispatch])
    // console.table(configuration_data.service_plan)
    // console.table(serviceDetails.itemArray)

    return (
        <Layout component={
            <>

                 {serviceDetails && <EditServiceForm configuration_data={configuration_data} loading={loading} error={error} serviceDetails={serviceDetails} />}
            </>}></Layout>

    );
};

export default EditServicePage;
