import { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
 
import {
  fetchCityZip,
  createCityZip,
  updateCityZip,
  deleteCityZip,
} from "../../reducers/CityZipSlice";

const ManageZipForm = ({ cityData, manageZipClose, zipShow, handleSubmit }) => {
  const [show, setShow] = useState(false);

 

    const cityZip = useSelector((state) => state.cityZip);
  const dispatch = useDispatch();

  const addZip = (event) => {
    event.preventDefault();
 setShow(true);
  };
 
  const updateZipStatus = (data) => {
if(data.cityTextId == cityData.textId){
   dispatch(updateCityZip({'cityTextId':"null",'id':data.id, 'zip':data.zip, 'areaTextId':data.areaTextId}));
}else {
    dispatch(updateCityZip({'cityTextId':cityData.textId,'id':data.id, 'zip':data.zip, 'areaTextId':data.areaTextId}));
   }
  };


  useEffect(() => {
    dispatch(fetchCityZip());
  }, [dispatch]);
  return (
    <>
      <Modal
        show={zipShow}
        // onHide={manageZipClose}
        backdrop="static"
        keyboard={true}
        className="service-form-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>City Information - {cityData.title} -  {cityData.textId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
       
          
     <ul className="zip-list">
          {cityZip.cityZip.map((item) => (
            <li key={item.zip} className={(item.cityTextId == cityData.textId)? 'selected': ' '}>
             <button type="button" onClick={() => { updateZipStatus(item) }}>{item.zip} {item.cityTextId}  {cityData.textId}</button>
            </li>
          ))}
      </ul>
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={addZip}>
            Add Zip Code
          </Button>
          <Button variant="secondary" onClick={manageZipClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageZipForm;
