import React, {useRef} from 'react';
import {Card, Button, Form} from 'react-bootstrap';
import {changeTeamMemberStatus} from "../../reducers/TeamMemberSlice";
import {useDispatch} from "react-redux";
import RemarksComponent from "./RemarksComponent";
import ShimmerAnimation from '../../pages/shimmerAnimation/ShimmerAnimation';
function formatPhoneNumber(number) {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
    }
    // Return the original number if it doesn't match the expected format
    return number;
}



const GeneralInfo = ({ profile, tabClick }) => {
    const phoneNumber = formatPhoneNumber(profile.phone);
    const dispatch = useDispatch();
    const ref = useRef(0);

    const handleButtonClick = () => {
        // Move focus to the element
        ref.current.focus();
        // Alternatively, scroll to the element
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    // const  handleClick = () => {
    //     console.log('wertyuio');
    // }
    return (
        <>


            {/* general info */}
            <div className="content bg-white rounded-12 general-info">
                {/* about */}


                {/* The element to which you want to move the focus */}
               

                <div className="about">
                    <div className="mb-3 strong">Contact Information</div>
                    <div className="about-info">
                        {profile.firstName && <div className="img-icon info-row">
                            <img src="/svg/account.svg" alt=""/> <span> {profile.firstName} {profile.lastName}</span>
                        </div>}
                        {profile.employeeType && <div className="img-icon info-row">
                            <img src="/svg/business.svg" alt=""/>
                            <span>{profile.employeeType}</span>
                        </div>}
                        {/*{profile.employeeType && <div className='info-row'>*/}
                        {/*    <img src="/svg/company.svg" alt=""/> <span>Help Abode</span>*/}
                        {/*</div>}*/}
                    </div>

                </div>


                <div className="contact">
                    <div className="contact-info">
                        {profile.email && <div className='info-row'>
                            <img src="/svg/Email.svg" alt=""/> <span className='email'>{profile.email}</span>
                        </div>}
                        {profile.phone && <div className='info-row'>
                            <img src="/svg/phone.svg" alt=""/> <span>{phoneNumber}</span>
                        </div>}
                    </div>
                </div>
                <div className='remarks-wrapper'>
                    <RemarksComponent sectionId='personalInfo' profile={profile} nextBtn={false}  handleButtonClick={handleButtonClick} defaultStatus='Personal Information'/>
                    {/*<button className="btn btn-success rounded-8 save-next" onClick={handleButtonClick}>Save and*/}
                    {/*    Next*/}
                    {/*</button>*/}
                </div>
            </div>

            {typeof(profile.addressJsonWhileVerification)==='object' && profile.addressJsonWhileVerification&&<div className="content bg-white rounded-12 general-info" ref={ref} tabIndex={0}>  
                
                    <div className="address">  
                        <div className="mb-3 strong">Address</div>
                        <div className="address-info">
                            
                            {/*<span>775 Waterville Drive Brentwood, CA, USA</span>*/}
                        {profile.addressJsonWhileVerification &&profile.addressJsonWhileVerification.map((item,index)=> <div className='info-row'>
                            <img src="/svg/location.svg" alt=""/>{" "}<span
                                className="text-muted mb-0">   {Object.keys(item).map((key, i) => (
                                <>{item[key] && key!=='type' && <span key={key}>{`${item[key]}`}</span>}</>
                                ))}</span>  </div>)}
                        </div>
                    </div>
                <div className='remarks-wrapper'>
                    <RemarksComponent sectionId='mailingInfo' profile={profile} nextBtn={true} handleButtonClick={tabClick} defaultStatus='Mailing Address Required'/>

                </div>
            </div>}


        </>

    );
}

export default GeneralInfo;