 
import React, {useEffect} from "react";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
 

const ErrorComponent = ({close, error, redirectLink}) => {
    const navigate = useNavigate();
    const closeModel = () => navigate(redirectLink);
 
    console.log(error)
    useEffect(() => {
        setTimeout(() => { 
            if(close){close()}else{ closeModel()} 
        }, 5000)
    }, []);

    return (
        <>
        <div className="custom-modal error-modal">
                <div className="modal-dialog"></div>
                <div className="modal-content">
                    <div className="alert-popup-container">
                        <div className="error-checkmark">
                            <div className="circle-border"></div>
                            <div className="circle">
                                <div className="error"></div>
                            </div>
                        </div>
                        {/*{error}*/}
                        {/*<div className="alert-popup-title">{error.name}</div>*/}
                        {/*<div className="alert-popup-title">{error.code}</div>*/}
                        {error && <div className='alert-popup-message'>{error.message}</div>}
                        <button className="btn" onClick={()=>close?close():closeModel()}>Close</button>
                    </div>
                </div>
            </div>
        </>
    );
};


export default ErrorComponent;

