import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { appendItem } from '../../reducers/FormBuilderSlice';
let filed = 0;
function myFunction() {

// Create an "li" node:
const node = document.createElement("input");
node.classList.add('form-control');
node.type='text';
node.name= 'name'+filed;
filed= filed + 1;

// Append the "li" node to the list:
document.getElementById("newFrom").appendChild(node);
}

const FieldComponent = ({ data, setShow }) =>{
  const { register, handleSubmit, control, formState: { errors } } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'option', // Replace with your actual field name
  });
  const dispatch = useDispatch()
  const onSubmit = (data) => {
    console.log("data:",data);
    dispatch(appendItem(data))
    setShow(false)
  };
const actionSubbmit = async (event) => {
    event.preventDefault();
    // alert("citi ma console");
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    // const isValid = await citySchema.isValid(data);
    console.log('data:', data)
    // console.log(isValid);
    // if(isValid){
    // dispatch(createCity(data));
    // handleClose(true);}
    // window.location.reload();
  };
  
  return (
    <div className='field-builder'>
        <form onSubmit={actionSubbmit} id='newFrom'>
          {/* {data} */}

          {/* <input className="form-control" name="name" placeholder='Input Nmae'/>  */}
          <input className="form-control"  placeholder='Input Nmae hidden' type='number'/>
          <input className="form-control" {...register("name", {required: true})} placeholder='Input Nmae' type='text'/>
          

            {fields.map((field, index) => (
              <div key={field.id}>
                <input className='form-control' {...register(`option.${index}.option`)}
                  defaultValue={field.yourInputName} />
                  {field.optionValue}
              </div>
            ))}

                {data=="select" &&  <button type="button" onClick={() => append()}>Add Field</button>  }
                 
               
                 
                <hr />
                 <input type="submit" />
          </form>
 <hr />
 <hr />
 <hr /> 
  <button onClick={()=> {
                    console.log('gggggggggggggg');
                      myFunction();
                    }}>Append...</button>
      </div>
  );
}

export default FieldComponent;
