import React from 'react';

const RatingComponent = ({ratings, averageRating, weeklyTotalReviews, countRating}) => {
    // const totalReviews = ratings.reduce((total, rating) => total + rating.count, 0);
    // const averageRating = ratings.reduce((total, rating) => total + rating.star * rating.count, 0) / totalReviews;

    return (
        <div className="w-full  bg-white rounded-lg p-5 flex items-center h-[207px]">
            <div className=" w-[30%]">
                <div className="flex items-center  w-[100%]">
                    <div className="text-4xl font-semibold">{averageRating}</div>
                    <div className="text-black text-xl ml-1">★</div>
                </div>
                <div className="flex items-center  w-[100%]">
                    <div className="ml-2 text-gray-600">of {countRating} reviews</div>
                    {weeklyTotalReviews>0 && <div className="ml-2 text-emerald-700">+{weeklyTotalReviews} this week</div>}
                </div>
            </div>
            <div className="fiex w-[70%]">
                {Object.keys(ratings).map(key => (
                    // <p key={key}>{key}: {ratings[key]}</p>

                    <div key={key} className="flex items-center my-2">
                <div className="text-gray-600">{key} ★ </div>
                <div className="flex-1 h-4 mx-4 bg-gray-300 rounded">
                    <div className="bg-emerald-700 h-4 rounded"
                         style={{width: `${(ratings[key]/countRating) * 100}%`}}></div>
                </div>
                <div>{ratings[key]}</div>
            </div>
            ))}
            {/*{ratings.map((rating) => (*/}

                {/*))}*/}
            </div>
        </div>
    );

};

export default RatingComponent;