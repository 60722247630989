 
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ArrowRight } from 'react-bootstrap-icons';

const NavigationBar = () =>{
    const { navData } = useSelector((state) => state.navData);
    const [active, setActive] = useState(false);
    const navigate = useNavigate();
  // console.log(navData)
  return (
      <>
        <div className="left-side-bar">
          {/* <div className="logo"><Link to="/dashboard" className="item">
          <img src={logo} alt="My logo" /> </Link >
        </div> */}
          <ul className="level-1">
            {navData && navData.map((root) => (
                <li key={root.link} className='root'>
                    {/* {!root.type? */}
                    <NavLink to={`/${root.link}`} className={`item ${active===root.link ?'active':''}`} onClick={(e)=>{
                         e.preventDefault()
                        if(root.type){
                            setActive(root.link===active?false:root.link)
                           
                            console.log('it is parrent menu'); }
                        else return navigate(`/${root.link}`)
                    }}>
                        {root.icon && <img src={`/images/${root.icon}`} alt={root.name}/>}
                        <span className="item-name">{root.name}</span> 
                    </NavLink>
                    {/* : */}
                    {/* <div className={`item ${active===root.name ?'active':''}`} onClick={()=>setActive(root.name)}>
                        {root.icon && <img src={`/images/${root.icon}`} alt={root.name}/>}
                        <span className="item-name">{root.name}</span> 
                    </div>} */}
                    {root.child.length>0&&<ul key={root.link} className="level-2">
                        {root.child.map((subNav) => (
                            <li key={subNav.link} className='sub-nav1'>
                                <NavLink to={`/${root.link}/${subNav.link}`} className="item">
                                    {subNav.child.length>0&&<i></i>}<span className="item-name">{subNav.name} </span>
                                </NavLink>
                                {subNav.child.length>0&&<ul key={subNav.link} className="level-3">
                                    {subNav.child.map((subNav2) => (
                                        <li key={subNav2.link} className='sub-nav2'>
                                            <NavLink to={`/${root.link}/${subNav.link}/${subNav.link}`} className="item">
                                                {subNav2.child.length>0&&<i>+</i>}<span className="item-name">{subNav2.name} sub2</span>
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>}
                            </li>
                        ))}
                    </ul>}
                </li>
            ))}
          </ul>
        </div>
      </>
  );
}

export default NavigationBar;
