import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    unauthorized: true,
    loading: false,
    data: [],
    notice: [],
    stageValue: [],
    count: 0,
    error: ''
}

const url = `${BASE_URL}/settings`

const token = localStorage.getItem("token")
// const textId = localStorage.getItem("textId")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


 

export const fetchQuestions = createAsyncThunk(
    'fetch/FetchFAQ', async () => {
        return axios
        .get(`${url}/faq/`, config)
        .then((response) => response.data)
    }
)

// export const fetchNotificationBellCount = createAsyncThunk(
//     'fetch/NotificationBellCount', async () => {
//         return axios
//         .get(`${url}/${textId}/notificationBellCount/`, config)
//         .then((response) => response.data)
//     }
// )

 

export const addQuestion = createAsyncThunk(
    "create/FAQQuestionSave", async (data) => {
        console.log('data', data)
        const cUrl = `${url}/faq/`;
        return axios
            .post(cUrl, data, config)
            .then((response) => response.data)
    }
)

export const updateQuestion = createAsyncThunk(
    "update/FAQQuestionUpdate", async (formData) => {
 
        return axios
            .put(`${url}/faq/`, formData, config)
            .then((response) => {
             
                response.data.localData = formData.localData;
            
                return response.data;
            })
    }
)

export const deleteQuction = createAsyncThunk(
    "delete/FAQQuctionDelete", async (formData) => {
      console.log(formData)
        return axios
            .delete(`${url}/faq/${formData.id}`,  config)
            .then((response) => formData)
    }
)

   

const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {

        updateBellCount(state, action) {
            state.count = state.count+1;
            console.log('bell count incrememt!!')
        },
    },
    extraReducers: (builder) => {
       

      builder.addCase(fetchQuestions.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchQuestions.fulfilled, (state, action) => {
        state.loading = false;
         
       console.log(action.payload)
        state.data= action.payload.results;
        state.faq_category= action.payload.faq_category;
        state.error = "";
      });
      builder.addCase(fetchQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });


      builder.addCase(addQuestion.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(addQuestion.fulfilled, (state, action) => {
        state.loading = false;
         
       console.log(action.payload.results)
        state.data.push(action.payload.results);
        state.error = "";
      });
      builder.addCase(addQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });


      builder.addCase(updateQuestion.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.data[action.payload.localData] = action.payload.results;
        state.error = "";
        console.log(action.payload.localData)
      });
      builder.addCase(updateQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      builder.addCase(deleteQuction.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(deleteQuction.fulfilled, (state, action) => {
        state.loading = false;     
        state.data.splice(action.payload.localData, 1);
        state.error = "";
      });
      builder.addCase(deleteQuction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      

      
    },
  });
  
// export const { updateBellCount } = faqSlice.actions;

export default faqSlice.reducer

 
