import DataTable, { createTheme } from "react-data-table-component";
// import "../assets/css/table.css";

const ItemConfigDataTable = ({ columns, data }) => {
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",

  };
  // console.log(data)
  // console.log(columns)

  createTheme(
    "tableTheme",
    {
      text: {
        primary: "#666",
        secondary: "#000",
      },
      background: {
        default: "#fff",
      },
      context: {
        background: "#f00",
        text: "#000",
      },
      divider: {
        default: "#D9D9D9",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );
 

  return (
    <DataTable
      columns={columns}
      data={data}
    //   pagination
    //   selectableRowsNoSelectAll={true}
    //   paginationPerPage={50} // Number of data items per page
    //   paginationComponentOptions={paginationOptions}
      // theme="tableTheme"
      // customStyles={customStyles}
      // noDataComponent={<CustomModelComponent />}
      // onSort={handleSort}
      // fixedHeader
      // selectableRows
    />
  );
};

export default ItemConfigDataTable;
