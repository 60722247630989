import { Form, Modal, Button } from "react-bootstrap"

const PermissionForm = ({permissionData, handleClose, show, handleSubmit}) => {
  return (
    <>
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
      >
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {permissionData.id != null ? 
                <Form.Control type="hidden" name="id" defaultValue={permissionData.id}></Form.Control>
              : <></>
            }
            <Form.Label htmlFor="adminRoleTextId">AdminRoleTextId</Form.Label>
            <Form.Control type="text" name="adminRoleTextId" defaultValue={permissionData.adminRoleTextId}></Form.Control>
            <Form.Label htmlFor="adminModuleTextId">AdminModuleTextId</Form.Label>
            <Form.Control type="text" name="adminModuleTextId" defaultValue={permissionData.adminModuleTextId}></Form.Control>
            <Form.Label htmlFor="read">Read</Form.Label>
            {permissionData.read ? 
            <Form.Check name="read" defaultChecked={permissionData.read}></Form.Check> : 
            <Form.Check name="read" defaultChecked={permissionData.read}></Form.Check>}
            <Form.Label htmlFor="create">Create</Form.Label>
            {permissionData.create ? 
            <Form.Check name="create" defaultChecked={permissionData.create}></Form.Check> : 
            <Form.Check name="create" defaultChecked={permissionData.create}></Form.Check>}
            <Form.Label htmlFor="update">Update</Form.Label>
            {permissionData.update ? 
            <Form.Check name="update" defaultChecked={permissionData.update}></Form.Check> : 
            <Form.Check name="update" defaultChecked={permissionData.update}></Form.Check>}
            <Form.Label htmlFor="delete">Delete</Form.Label>
            {permissionData.delete ? 
            <Form.Check name="delete" defaultChecked={permissionData.delete}></Form.Check> : 
            <Form.Check name="delete" defaultChecked={permissionData.delete}></Form.Check>}
            <Button type="submit" className="mt-2">Submit</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PermissionForm