import { useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import { fetchFranchise } from '../../reducers/FranchiseSlice'
import { Form } from 'react-bootstrap'
import Layout from '../../components/Layout'

const FranchiseMemberList = () => {
    
    const dataList = useSelector((state) => state.franchise)
    const dispatch = useDispatch()

    const columns = [
        {name: "ID", sortable: true, cell: (row) => row.id},
        {name: "TextId", sortable: true, cell: (row) => row.textId},
        {name: "Company Name", sortable: true, cell: (row) => row.companyName},
        {name: "workerTextId", sortable: true, cell: (row) => row.workerTextId},
        {name: "Is Help Abode Company", sortable: true, cell: (row) => row.isHelpAbodeCompany},
        {name: "Status", sortable: true, cell: (row) => row.status},
        {name: "Created", sortable: true, cell: (row) => row.created},
    ]

    useEffect(() => {
        dispatch(fetchFranchise())
    }, [dispatch])

  return (
    <Layout component={
        <>
    {dataList.loading && <div>Loading ...</div>}
    {!dataList.franchise && dataList.error ? <div>Error: {dataList.error}</div> : null}
    {!dataList.loading ? 
       <CustomDataTable data={dataList.franchise} columns={columns}/>
  : null }
  </>
    }></Layout>
  )
}

export default FranchiseMemberList;