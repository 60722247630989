import { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {searchCategorie, createCategoryService, deleteCategoryService, deleteCategoryServiceAll, deleteCategoryServiceItem, fetchCategoryService} from "../../reducers/CategoryServiceSlice";
import { fetchCategory, createCategory, updateCategory, deleteCategory } from '../../reducers/CategorySlice';
const ManageCategoryService = ({ active, close }) => {
  // console.log("🚀 ~ file: ManageCategoryService.jsx:7 ~ active:", active)
  const [query, setQuery] = useState(false);
  const [btn, setBtn] = useState(false);
 
  const [remove, setRemove] = useState(false);
  const [haveSelecetdCategory, setHaveSelecetdCategory] = useState(false);
 
  const dispatch = useDispatch();
  const selectedList = Array();
  const queryData = useSelector((state) => state.categoryService);
  const categoryData = useSelector((state) => state.category);
 
  // console.log(queryData);
  const addSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let data = Object.keys(Object.fromEntries(formData));
    const currentData = {'categories': data,'serviceTextId': active.textId,};
    // console.log("🚀 ~ file: ManageCategoryService.jsx:24 ~ addSubmit ~ currentData:", currentData)
    dispatch(createCategoryService(currentData));
    setQuery(false)
    setBtn(false)
  }; 
  
  const checkSearch = (e) => {
      if(e.target.checked == true){
         selectedList.push(e.target.name);
      } else {
        var index = selectedList.indexOf(e.target.name);
        selectedList.splice(index, 2);
      }
      selectedList.length ? setBtn(true) :setBtn(true)
  }; 
  const removeBulkSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
   
    let data = Object.keys(Object.fromEntries(formData));
     console.log(data);
    const currentData = {csid: data, serviceTextId: active.textId}; 
 
    // console.log(currentData)
    //  console.log('removeAllItem ###############');
    dispatch(deleteCategoryService(currentData));
  }; 
  
  const removeAllItem = () => { 
    // console.log('removeAllItem ******'+ active.textId)
    dispatch(deleteCategoryServiceAll(active.textId));
  }; 
  
  const removeItem = (e,i) => {
    //  setRemove(true);
    const data ={ serviceTextId: active.textId, id: e.target.name, localData: i}
    // setRemove(true)
    dispatch(deleteCategoryServiceItem(data));
  }; 
  useEffect(() => {
    dispatch(fetchCategory());
    dispatch(fetchCategoryService(active.textId));
    // dispatch(searchCategorie({que: active.textId}));
  }, [dispatch]);
  return (
    <>
    <div id="emailSent" className="custom-modal">
      <div className="modal-dialog" onClick={close}></div>
      <div className="modal-content">
        <div className="modal-header">
          {/* <h2>Service Category </h2> */}
           <h5>{active.title} </h5>
          <span className="close-modal"  onClick={close}>&times;</span>
          </div>
          <div className="modal-body">
       
           
            <input name="search" placeholder="search" type="text" className="form-control" value={query? query : ''} onChange={(e)=> setQuery(e.target.value)}/>
            <Form onSubmit={addSubmit} encType="multipart/form-data">
              
              <ul className="search-suggestion">
                {categoryData.categories.filter(item=>item.textId.toLowerCase().includes(query)).map((item) => (
                  <li key={item.id} className='item'>
                    <input id={item.id} type="checkbox"  name={item.breadcrumb} onChange={checkSearch}/>
                    <label htmlFor={item.id}>{item.title}  </label>
                  </li>
                ))}
              </ul>
           
              {btn? <Button type="submit" className="btn close-modal">Submit..</Button>:'' }
          
          </Form> 
          {/* {queryData.selected.categories.length?  */}
          {queryData.selected.categories ? 
             <Form onSubmit={removeBulkSubmit} encType="multipart/form-data">
          <div className="selected-items"> 
          {queryData.selected.categories && queryData.selected.categories.map((each,i) => ( 
            
            <div key={each.id} className='item'>
              <label htmlFor={each.id}>{each.categoryBreadcrumb}  </label>
              <input id={each.id} type="checkbox" value={each.id} rel={i} name={each.id} onChange={(e)=>{removeItem(e,i)}}/>   
              {/* <input id={each.id} type="checkbox" rel={i} name={each.id} />    */}
            </div>
            ))
          }
        </div>
         {/* { <Button type="submit" className="btn close-modal">Submit</Button> } */}
         {remove && <Button type="submit" className="btn close-modal">Submit</Button> }
 
        <span className="btn close-modal" onClick={removeAllItem}>Delete All </span> 
            </Form>:''}
            </div>
            {/* <div className="modal-footer">  <span className="btn close-modal" onClick={close}> Close  </span> </div> */}
     
        </div>
    </div>
    </>
  );
};
 

export default ManageCategoryService;
