import React, {useState, useEffect} from "react";

const LoadingComponent = ({close, message}) => {

    return (
        <div id="loading" className="custom-modal loadming-modal">
            <div className="custom-dialog close-modal"></div>
            <div className='loader-area'>
                <div className='message'>{message}</div>
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};


export default LoadingComponent;

