//  import { Form, Button } from "react-bootstrap";
 import { Form, Modal, Button, Image } from "react-bootstrap";
 import { useDispatch, useSelector } from "react-redux";
 import { useState, useEffect } from "react";
//  import axios from "axios";
 import { BASE_URL } from "../BaseUrl";
 
 import LoadingComponent from "../modal/LoadingComponent";
//  import {changeCategoryRequestStatus } from "../reducers/ServiceCustomizationSlice";
 import {changeCategoryRequestStatus, fetchCategoryRequest} from "../reducers/CategoryRequestSlice";
const CustomModal = ({ data, close, loading }) => {
    const [status, setStatus] = useState(false);
    const dispatch = useDispatch()
    const handleSelect=(e) => setStatus(e.target.value)
    const handleSubmit=() =>{
         const formData = {
            status: status,
            workerTextId: data.workerTextId,
            franchiseTextId: data.franchiseTextId,
            categoryTextId: data.categoryTextId,
        }
        const res = dispatch(changeCategoryRequestStatus(formData))
            res.then((results) => {
                if(results.error){
                    
                }else {
                    close()
                     dispatch(fetchCategoryRequest())
                }
            })
            res.catch((error) => {
                console.error('Promise rejected:', error);
            });

    }


  return (
      <>
          <div id="emailSent" className="custom-modal">
              <div className="modal-dialog"></div>
              <div className="modal-content">
                  <div className="modal-header"><h2>{data.categoryTitle}</h2>
                      <span className="close-modal" onClick={close}>&times;</span>
                  </div>
                  <div className="modal-body">
                    <div className="category-desc mb-3">
                          <p>{data.details}</p>
                      </div>
                      <div className="img-card rounded-8">
                            <img src={`${BASE_URL}/${data.categoryImage}`} alt={data.categoryTitle}/>
                      </div>
                    <div className="pt-[15px] mt-[20px] border-t-2 border-slate-400 border-dashed">
                        <label className="pb-[8px] font-bold">Update The Category Status:</label>
                    <Form.Select aria-label={'status'} onChange={(e) => handleSelect(e)}>
                        <option selected disabled>{'status'}</option>
                        <option value='Pending'>Pending</option>
                        <option value="Approved">Approved</option>
                        <option value='Rejected'>Rejected</option>
                        <option value='Pause'>Pause</option>
                    </Form.Select>
                </div>
                  
                       
                  </div>
                  {loading && <LoadingComponent message={''}/>}
                    <div className="modal-footer">
                        <Button type="submit" className="btn btn-success rounded-pill px-4" onClick={() => handleSubmit()}> Confirm </Button>
                        &nbsp; &nbsp;
                        <button className="btn bg-white rounded-pill border-red px-4" onClick={close}>Cancel </button>
                        
                    </div>
              </div>
          </div>
      </>
  );
};


export default CustomModal;

