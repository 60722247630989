import DataTable, { createTheme } from "react-data-table-component";
import CustomModelComponent from "../component/DataNotAvailableComponent";
// import "../assets/css/table.css";

const ReviewssDataTable = ({ columns, data }) => {
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",

  };
  // console.log(data)
  // console.log(columns)


  return (
    <DataTable className={`reviewTable`}
      columns={columns}
      data={data}
      pagination
      selectableRowsNoSelectAll={true}
      paginationPerPage={10} // Number of data items per page
      paginationComponentOptions={paginationOptions}

    />
  );
};

export default ReviewssDataTable;
