import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    services: [],
    serviceDetails: false,
    error: '',
    success: false,
    configuration_data:false
}

const url = `${BASE_URL}/service/`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
        "content-type": "multipart/form-data"
    }
}
const config1 = {
    headers: {
        Authorization: `Token ${token}`
    }
}



export const serviceImageUpdate = createAsyncThunk(
    "settings/add", async (formData) => {

        const cUrl = `${BASE_URL}/service/${formData.categoryTextId}/ServiceCreation`;
        return axios
            .post(cUrl, formData, config)
            .then((response) => response.data)
    }
)

export const fetchServices = createAsyncThunk(

    'fetch/serviceItem', async (param) => {
           
        return axios
            .get(`${BASE_URL}/service/${param}/ServiceCreation`, config)
            .then((response) => response.data)
    }
)
export const quickViewSubItem = createAsyncThunk(
    'fetch/quickView', async (textId) => {
        // {{base_url}}/service/subItemDetails/94273cf862b0b8be583d2a0f1dc03c
        return axios
            .get(`${BASE_URL}/service/subItemDetails/${textId}`, config)
            .then((response) => response.data)
    }
)

export const fetchServiceDataForUpdate = createAsyncThunk(
    'fetch/UpdateService', async (param) => {
        return axios
            .get(`${BASE_URL}/service/${param}/UpdateService/`, config)
            .then((response) => response.data)
    }
)

export const fetchServiceDetails = createAsyncThunk(
    'fetch/ServiceDetails', async (param) => {
        return axios
            .get(`${BASE_URL}/service/${param}/ServiceDetails/`, config)
            .then((response) => response.data)
    }
)

export const createService = createAsyncThunk(
    "create/serviceItem", async (formData) => {
         console.log('service',formData)
        return axios
            .post(`${BASE_URL}/service/${formData.attributeGroupTextId}/ServiceCreation`, formData, config)
            .then((response) => console.log(response.data))
    }
)


const serviceItemSlice = createSlice({
    name: "serviceItem",
    initialState,

    extraReducers: (builder) => {
        builder.addCase(fetchServices.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchServices.fulfilled, (state, action) => {
            state.loading = false;
            state.configuration_data = action.payload;
            state.error = "";
            // console.log(action.payload)
        });
        builder.addCase(fetchServices.rejected, (state, action) => {
            state.loading = false;
            state.configuration_data = false;
            state.success = false;
        });

        builder.addCase(fetchServiceDataForUpdate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchServiceDataForUpdate.fulfilled, (state, action) => {
            state.loading = false;
            state.serviceDetails = action.payload;
            state.error = "";
            // console.log('ddddddddd',action.payload )
        });
        builder.addCase(fetchServiceDataForUpdate.rejected, (state, action) => {
            state.error = action.error;
            state.loading = false;
        });

        builder.addCase(fetchServiceDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchServiceDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.serviceDetails = action.payload;
            state.error = "";
            // console.log('ddddddddd',action.payload )
        });
        builder.addCase(fetchServiceDetails.rejected, (state, action) => {
            state.error = action.error;
            state.loading = false;
        });


        builder.addCase(createService.pending, (state) => {
          // state.loading = true;
          state.success= false;
        });
        builder.addCase(createService.fulfilled, (state, action) => {
          // state.services = action.payload;
          state.services=action.payload;

          state.success= true
          state.loading = false;
        });
        builder.addCase(createService.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error;
          state.success= false;
          // state.services = [];
        });
    },
});


export default serviceItemSlice.reducer