import {Form, Modal, Button, Image} from "react-bootstrap";
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
 
 
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'; 
import { useDispatch, useSelector } from "react-redux";
import React, {useEffect, useState} from "react";
import {NameIcon} from "../../utility/StringImageIcon";
import moment from "moment";
import { formatDistanceToNowStrict,isBefore, isWithinInterval  } from 'date-fns';
import {fetchNotificationData, readNotification} from "../../reducers/NotificationSlice";
import LoadingComponent from "../../modal/LoadingComponent";

const NotificationModalSideBar = ({mark}) => {
    const {notice, loading} = useSelector((state) => state.notification);
    const [active, setActive] = useState('N');
    const [unredLength, setUnredLength] = useState('N');
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
 
    let dataLength = notice.filter(item => item['isSeen']?.toLowerCase().includes(unredLength.toLowerCase()))
    let data = notice.filter(item => item['isSeen']?.toLowerCase().includes(active.toLowerCase()))
 console.log('kkkk', notice.length)

    

    useEffect(() => {
        dispatch(fetchNotificationData()); 
    }, [dispatch]); 

    return (
        <>
            <div className="w-full relative flex items-center text-base font-semibold font-['Inter'] capitalize text-center custom-borser-buttom">
                <div key="N" className={`w-[244px] cursor-pointer ${active==='N'?'text-emerald-700':'text-stone-500'}`}
                onClick={()=>setActive('N')}> Unread ({dataLength.length})
                     <div className={`w-full h-[2px] mt-3 rounded-tl rounded-tr ${active==='N'?'bg-emerald-700': 'white'}`}></div>
                </div>
                <div key="Y" className={`w-[244px]   cursor-pointer ${active==='Y'?'text-emerald-700':'text-stone-500'}`}
                onClick={()=>setActive('Y')}> Read
                      {/* {active==='Y' && <div className={`w-full h-[2px] mt-3 rounded-tl rounded-tr bg-emerald-700`}></div>} */}
                      <div className={`w-full h-[2px] mt-3 rounded-tl rounded-tr ${active==='Y'?'bg-emerald-700': 'white'}`}></div>
                </div> 
            </div>

            {notice.length===0 &&loading && <LoadingComponent message={' '}/>}
            <ol className="self-stretch flex-col justify-start items-start gap-2 flex p-0">
                    
                {data.map((item, index)=> {let link = '';
                if(item.optionJson && item.optionJson.orderTextId){
                       link = `/order/${item.optionJson.orderTextId}`;
                }else {
                    link = `/${item.senderType}/${item.senderTextId}`;
                }
                
                return ( 
                <li key={item.id} onClick={()=>{
                    if(active==='N'){
                    const res=dispatch(readNotification({id: item.id, index: index}));
                    res.then(results => {
                        console.log('results', results)
                        // close();
                        }).catch(error => {
                            alert('error ---- false')
                        });
                    }

                    navigate(link , {
                        state: item
                    })

                }}
                className="w-full" > 
                <div className="w-full flex-col justify-start items-start gap-5 inline-flex cursor-pointer pr-2">
                    <div className="self-stretch px-3 bg-white justify-start items-start gap-2 inline-flex">
                        <div className="justify-start items-center gap-0.5 flex">
                            <div className={`w-2.5 h-2.5 rounded-full ${active==='N'?'bg-emerald-700':'bg-gray-200'}`}></div>
                            <div className="w-11 h-11 p-1 rounded-[200px] border border-gray-200 justify-center items-center flex">
                                {item && item.image?<div className="profile-icon w-9 h-9 rounded-full truncate" style={{backgroundImage: `url(${BASE_URL}/${item.image})`}}></div> :
                                    <strong className="profile-icon w-9 h-9 rounded-full bg-[green] text-center text-white py-2.5 pt-[8px]">
                                        {NameIcon(item.username)} 
                                    </strong>}
                            </div>
                             
                        </div>
                        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                            <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                                <div className="text-black text-base font-['Inter']"><strong>{item.username} </strong> {item.title}</div>
                                <div className="self-stretch text-neutral-500 text-sm font-normal font-['Inter']">{moment(new Date(item.created)).format("dddd DD MMM YYYY")} at {moment(new Date(item.created)).format(" h:mm A")}</div>
 
                            </div>
                            <hr className="self-stretch h-[0px] border-1 border-slate-400" />
                            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                                <div className="self-stretch text-black text-[13px] font-normal font-['Inter'] leading-[18px]">{item.messages}</div>
                                <div className="self-stretch justify-between items-center inline-flex">
                                    <div className="px-3 py-[7px] bg-gray-200 rounded-[100px] text-stone-500 text-sm font-normal font-['Inter'] leading-[19px]">{moment(item.created).isSameOrAfter(moment().subtract(1, 'minute')) && moment(item.created).isBefore(moment())? <>Just now</>:<>{formatDistanceToNowStrict(item.created)} ago</>} </div>
                                    {/* <div className="px-3 py-[7px] bg-gray-200 rounded-[100px] justify-start items-center gap-2 flex">
                                        <div className="text-center text-black text-sm font-semibold font-['Inter'] leading-tight">View order details Demo</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                {/* <div className="w-full h-[0px] border border-zinc-300 my-[8px]"></div> */}
                  <hr className="w-full h-[0px] border border-zinc-300 my-[8px]" />
                </li>)}
                )}

            </ol>
    
        </>
    );
};

export default NotificationModalSideBar;
