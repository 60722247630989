import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams  } from "react-router-dom";

import { Button, Image } from 'react-bootstrap';
import ServiceSubItemForm from './ServiceSubItemForm';
import { BASE_URL } from '../../BaseUrl';
import NavBarUpdate from "./NavBarUpdate";
import Layout from '../../components/Layout';
import ServiceItemConfiguration from './ServiceItemConfiguration';
import { fetchServiceSubItem } from '../../reducers/CategorySlice';
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
// import {  itemRankUpdateApi, fetchServiceItem } from '../../reducers/FormBuilderSlice';  
const token = localStorage.getItem("token");
 
const ServiceItemList = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let {item}= useParams();
  const {title} = useSelector((state) => state.category)
    console.log('userId', item)
  const [ categoryData, setCategoryData ] = useState({
        update: false,
        // id: null,
        // textId: null,
        title: null,
        categoryTextId: 'root',
        // breadcrumb: null,
        rank: null,
        status: null,
        itemDescription: null,
        image: null,
        icon: null,
        // zoneTextId: null
    })
    const dispatch= useDispatch()
  const handleClose = () => { navigate(-1)};


    if(title){
        document.title = title
        dispatch(breadcrumbTree({
            'Service Item List': 'service-items',
            [title]: item,
            'add New Item':'add'
        }));}

    useEffect(() => {
        dispatch(fetchServiceSubItem(`${item}/serviceSubItemManagement/`))
    }, [dispatch, location])

    return (
    <> <Layout component={
           <>

         {/*<ServiceItemAddForm data={categoryData} close={handleClose}/>*/}
             <ServiceSubItemForm data={categoryData} close={handleClose}/>
           </>
      }></Layout>
    </>
  )
}

export default ServiceItemList;