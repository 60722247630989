import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {BASE_URL} from "../../BaseUrl";


const UserGeneralInformation = ({profile}) => {

    return (
        <>
            <link rel="stylesheet"
                  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>


            <div className="w-[100%] flex-col justify-start items-start gap-4 inline-flex mt-3">
                {profile.user_data && <div className="flex-col justify-start items-start gap-6 flex">
                    <div className="text-black text-base font-semibold font-['Inter']">About</div>
                    <div className="justify-start items-center gap-4 inline-flex">
                        <div className="w-7 h-[28.88px] relative">
                            <img src="/svg/account.svg" className="w-7 h-7  rounded-full border-4 border-white"/>
                        </div>
                        <div
                            className="text-black text-base font-medium font-['Inter']">{profile.user_data.firstName} {profile.user_data.lastName}</div>
                    </div>
                </div>}
                <div className="self-stretch h-[0px] border border-neutral-100 opacity-[25%]"></div>
                <div className="flex-col justify-start items-start gap-6 flex">
                    <div className="text-black text-base font-semibold font-['Inter']">Contact</div>
                    <div className="flex-col justify-start items-start gap-4 flex">
                        {profile.user_data.email &&
                            <div className="justify-start items-center gap-4 inline-flex text-[26px]">

                                <i className="w-7 h-7 relative fa fa-envelope-o text-[#AAA6A6]"
                                   aria-hidden="true"></i>
                                <div
                                    className="text-neutral-600 text-sm font-normal font-['Inter']">
                                    {profile.user_data.email}
                                </div>
                            </div>}
                        {profile.user_data.phone &&
                            <div className="justify-start items-center gap-4 inline-flex  text-[26px]">
                                <i className="w-7 h-7 relative fa fa-phone text-[#AAA6A6]"
                                   aria-hidden="true"></i>
                                <div className="text-neutral-600 text-sm text-lg font-['Inter']">
                                    {profile.user_data.phone}
                                </div>
                            </div>}
                    </div>
                </div>
                <div className="self-stretch h-[0px] border border-neutral-100 opacity-[25%]"></div>
                <div className="self-stretch  flex-col justify-start items-start gap-0 flex">
                    <div className="text-black text-base font-semibold font-['Inter']">Addresses</div>
                    {profile.address_data.map((item) => (
                        <>

                            <div
                                className={`w-[100%] p-4 rounded-md justify-start items-center  inline-flex ${item.textId === profile.user_data.endUserAddressTextId ? 'bg-neutral-100' : 'bg-white'}`}>
                                <div className="grow shrink basis-0 h-10 justify-between items-center flex">
                                    <div className=" justify-start items-center gap-3 flex text-[50px]">

                                        <div className={`relative text-[22px] ${item.textId === profile.user_data.endUserAddressTextId ? 'text-[#008951]' : ''}`}>
                                            <i className="fa fa-map-marker"></i>
                                        </div>
                                        <div className={`relative ${item.textId === profile.user_data.endUserAddressTextId ? 'text-emerald-700' : ''}`}>
                                            <div
                                                className={`text-base font-medium font-['Inter']`}>

                                                {item.addressLine1 && <span>{item.addressLine1}</span>}
                                                {item.addressLine2 && <span>, {item.addressLine2}</span>}


                                            </div>
                                            <div
                                                className="text-sm font-normal font-['Inter']">
                                                {item.city && <span> {item.city}</span>}
                                                {item.state && <span>, {item.state}</span>}
                                                {item.countryIso2Code && <span>, {item.countryIso2Code}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    {item.textId === profile.user_data.endUserAddressTextId &&
                                        <div className="text-black text-sm font-normal font-['Inter']">Default</div>}
                                </div>

                            </div>
                        </>
                    ))}

                </div>
            </div>
        </>
    );
};

export default UserGeneralInformation;
