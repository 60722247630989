import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
 



// interface UsersState {
//   entities: []
//   loading: 'idle' | 'pending' | 'succeeded' | 'failed'
// }
 const initialState = {
    loading: false,
    data: [],
    error: '',
    success: false,
    res: false,
} 

const url = `${BASE_URL}/settings/attributeManagement/`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchAttribute = createAsyncThunk(
    'fetch/attribute', async () => {
        return axios
        .get(url, config)
        .then((response) => response.data)
    }
)
// export const attributeRead = createAsyncThunk(
//     'fetch/attribute', async () => {
//         return axios
//         .get(url, config)
//         .then((response) => response.data)
//     }
// )

 export const createAttribute = createAsyncThunk(
  "create/attribute", async (formData) => {
    return axios
    .post(url, formData, config)
    .then((response)=> {
      console.log("🚀 ~ file: AttributeSlice.js:43 ~ .then ~ response:", response)
      
      return response.data;
    }
      ) 
  }
)
 

export const updateAttribute = createAsyncThunk(
  "update/attribute", async (formData) => {
    return axios
    .put(`${url}${formData.textId}`, formData, config)
    .then((response) =>{ 
    response.data.localData = formData.localData;
    console.log("🚀 ~ file: AttributeSlice.js:59 ~ .then ~ formData:", formData)
    return response.data; 
     } 
  )
  }
)

export const deleteAttribute = createAsyncThunk(
  "delete/attribute", async (formData) => {
    return axios
    .delete(`${url}${formData.textId}`, config)
    .then((response) =>{ 
    return formData; 
     })
  }
)

const attributeSlice = createSlice({
    name: "attribute",
    initialState,
    reducers: {
    updateState(state, action) {
      state.res = action.payload;
    },
    removeSuccessMessage(state, action) {
      state.success = action.payload;
    },
  },
    extraReducers: (builder) => {
      builder.addCase(fetchAttribute.pending, (state) => {
        state.loading = true;
        state.success = false;
      });
      
      builder.addCase(fetchAttribute.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
         
      });

      builder.addCase(fetchAttribute.rejected, (state, action) => {
        state.loading = false;
        // state.data = [];
        state.error = action.error;
        if(action.error.code === 'ERR_BAD_REQUEST'){  
        
          }
           
      });
       
      builder.addCase(createAttribute.pending, (state, action) => {
        state.loading = true;
        state.success = false;
      }); 
       
      builder.addCase(createAttribute.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data.push(action.payload);
        state.error = "";
      }); 

      builder.addCase(createAttribute.rejected, (state, action) => {
      state.loading = false;
      // state.data = [];
      state.error = action.error;
     
    });

      
    builder.addCase(updateAttribute.fulfilled, (state, action) => {
      state.loading = false;
      state.data[action.payload.localData] = action.payload;
      state.error = "";
      console.log(action)
    });
 
    builder.addCase(updateAttribute.rejected, (state, action) => {
      state.loading = false;
      // state.data = [];
      state.error = action.error;
 
    });
    
    builder.addCase(deleteAttribute.fulfilled, (state, action) => {
      state.loading = false;
      state.data.splice(action.payload.localData, 1);
      state.error = "";
    });
        
    builder.addCase(deleteAttribute.rejected, (state, action) => {
      state.loading = false;
      // state.data = [];
      state.error = action.error;
 
    });
    },
  });
  



export const { updateState, removeSuccessMessage } = attributeSlice.actions;
export default attributeSlice.reducer