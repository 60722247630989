import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../BaseUrl";

const initialState = {
    loading: false,
    order: [],
    order_details: false,
    error: '',
    success: false,
    res: false,
    orderTracking: false,
}

const url = `${BASE_URL}/enduser/order/`
const token = localStorage.getItem("token")
const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchOrder = createAsyncThunk(
    'fetch/order', async () => {
        return axios
            .get(url, config)
            .then((response) => response.data)
    }
)

 
export const fetchOrderDetails = createAsyncThunk(
    'fetch/OrderDetails', async (data) => {
        return axios
            .get(`${url}details/${data}`, config)
            .then((response) => response.data)
    }
)
export const fetchOrderTracking = createAsyncThunk(
    'fetch/OrderTracking', async (data) => {
        return axios
            .get(`${url}OrderTrackingData/${data}`, config)
            .then((response) => response.data)
    }
)


export const updateOrder = createAsyncThunk(
    "update/order", async (formData) => {
        return axios
            .put(`${url}${formData.textId}`, formData, config)
            .then((response) => {
                    response.data.localData = formData.localData;
                    console.log("🚀 ~ file: CitySlice.js:59 ~ .then ~ formData:", formData)
                    return response.data;
                }
            )
    }
)


const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchOrder.pending, (state) => {
            state.loading = true;
            state.success = false;
        });

        builder.addCase(fetchOrder.fulfilled, (state, action) => {
            state.loading = false;
            state.order = action.payload.order_list;
            state.error = "";

        });

        builder.addCase(fetchOrder.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });

        builder.addCase(fetchOrderDetails.pending, (state) => {
            state.loading = true;
            state.success = false;
        });

        builder.addCase(fetchOrderDetails.fulfilled, (state, action) => {
            state.loading = false;
            // state.order = action.payload;
            state.order_details= action.payload.order_details;
            state.error = "";

        });

        builder.addCase(fetchOrderDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });

        builder.addCase(fetchOrderTracking.pending, (state) => {
            state.loading = true;
            state.success = false;
        });

        builder.addCase(fetchOrderTracking.fulfilled, (state, action) => {
            state.loading = false;
            state.orderTracking = action.payload;
            // state.order_details= action.payload.order_details;
            state.error = "";


        });

        builder.addCase(fetchOrderTracking.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });


        builder.addCase(updateOrder.fulfilled, (state, action) => {
            state.loading = false;
            state.order[action.payload.localData] = action.payload;
            state.error = "";
            console.log(action)
        });

        builder.addCase(updateOrder.rejected, (state, action) => {
            state.loading = false;
            // state.order = [];
            state.error = action.error;

        });

    },
});


// export const { updateState, removeSuccessMessage } = orderSlice.actions;
export default orderSlice.reducer