import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    allRequest: [],
    profile:'',
    error: ''
}

const url = `${BASE_URL}/settings`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}

// http://192.168.1.19:5000/settings/workerAreasAcceptReject/
export const fetchAreaRequest = createAsyncThunk(
    'fetch/areaRequest', async () => {
        return axios
            .get(`${url}/workerAreasAcceptReject/`, config)
            .then((response) => response.data)
    }
)

export const changeAreaRequestStatus = createAsyncThunk(
    "update/areaRequest", async (formData) => {
        return axios
            .post(`${url}/workerAreasAcceptReject/`, [formData], config)
            .then((response) => console.log(response.data))
    }
)


const areaRequestSlice = createSlice({
    name: "AreaRequest",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(fetchAreaRequest.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchAreaRequest.fulfilled, (state, action) => {
        state.loading = false;
          state.allRequest = [];
          console.log(action.payload)
          if(action.payload.area_list){
              action.payload.area_list.forEach((item) => {
                  if (item.status !== 'Approved') {
                      state.allRequest.push(item);
                  }
              });}
        state.error = "";
      });
      builder.addCase(fetchAreaRequest.rejected, (state, action) => {
        state.loading = false;
        state.allRequest = [];
        state.error = action.error;
      });



    },
  });
  


export default areaRequestSlice.reducer


 
