import {Form, Modal, Button, Image} from "react-bootstrap";
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
 
 
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'; 
import { useDispatch, useSelector } from "react-redux";
import React, {useEffect, useState} from "react";
import {NameIcon} from "../../utility/StringImageIcon";
import moment from "moment";
import { formatDistanceToNowStrict,isBefore, isWithinInterval  } from 'date-fns';
import {fetchNotificationData, readNotification} from "../../reducers/NotificationSlice";
import NotificationModalSideBar from './NotificationModalSideBar';

const NotificationModal= ({close, count}) => {
    const {notice} = useSelector((state) => state.notification);
    const [active, setActive] = useState('N');
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    let data = notice.filter(item => item['isSeen']?.toLowerCase().includes(active.toLowerCase()))

    useEffect(() => {
        dispatch(fetchNotificationData());
    }, []); 

    return (
        <>
      <div className="offset-layer" onClick={()=>close()}></div>
         <div className='notification-modal'> 
           <div className="w-[490px] bg-white pt-3 flex-col justify-start items-start gap-3 inline-flex">
            <div className="w-full  flex-col justify-start items-start inline-flex">
                <div className="self-stretch justify-between items-center inline-flex mx-7">
                    <div className="justify-start items-center gap-3 flex">
                        <div className="w-[50px] h-[50px] p-2 bg-neutral-100 rounded-[50px] justify-center items-center gap-2.5 flex hover:bg-emerald-700 hover:text-white cursor-pointer"
                        onClick={()=>close()}>
                             <i className="fa fa-times text-lg" aria-hidden="true"></i>  
                        </div>
                        <div className="text-black text-2xl font-semibold font-['Inter']">Notifications</div>
                    </div>
                   {count>0 && <div className="px-2 py-1 bg-emerald-700 rounded-3xl justify-start items-center gap-2 flex"> 
                         <div className="text-center text-white text-xs font-semibold font-['Inter'] leading-tight">{count} new</div>
                    </div>}
                </div>
                {/* <hr className="caption-bottom border-slate-400 w-full" /> */}
            </div>
            <div className="w-full h-[0px] border border-zinc-300"></div>
             <NotificationModalSideBar />
            </div>
            </div>
        </>
    );
};

export default NotificationModal;
