 
import { Form, Modal, Button } from "react-bootstrap"; 
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState} from "react";
import { createCity, updateCity, updateState } from "../../reducers/CitySlice";
import { citySchema } from "../../schemas/city";
import { useFormik } from "formik";
 
const CityForm = ({ data, close, show }) => {
const dispatch = useDispatch();
const feachAreaData = useSelector((state) => state.area); 
const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
     initialValues: data,
     onSubmit: (values, action) => {
       if(data.update == true){
          dispatch(updateCity(values));
       }else{
          dispatch(createCity(values));
       }
       close(true);
     },
     validationSchema: citySchema,
   });
  // useEffect(() => {
  //   // dispatch(fetchArea());
  // }, []);
  return (
    <>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={true}
        className="service-form-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>City Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
          <Form onSubmit={handleSubmit}>
          {/* <Form onSubmit={data.textId ==null ? actionSubbmit : actionUpdate }> */}
          
            <div className="mb-3">
             {/* {data.textId==null?<Form.Label htmlFor="textId">TextId</Form.Label>:null} */}
            
             <Form.Control placeholder="TextId" type={data.textId !=null ? 'hidden':'text'} name="textId" value={values.textId} onChange={handleChange} onBlur={handleBlur}></Form.Control>
                {errors.textId && <p className="red">{errors.textId}</p>} 
           </div>
 
            <Form.Control type="text" name="title" placeholder="Title" value={values.title} onChange={handleChange} onBlur={handleBlur}></Form.Control>
               {errors.title && <p className="red">{errors.title}</p>} 
            
            {/* <Form.Select aria-label="Default select example" className="form-control" name="areaTextId"  value={values.areaTextId} onChange={handleChange} onBlur={handleBlur}>
                      <option value=''>Select Area</option>
                      {feachAreaData.areas.map(({ textId, title }, index) => <option value={textId} >{title}</option>)}
            </Form.Select>
               {errors.areaTextId && <p className="red">{errors.areaTextId}</p>}  */}
 
            <Form.Control type="text" placeholder="PriceVariationJson" name="priceVariationJson" as={"textarea"}   value={values.priceVariationJson} onChange={handleChange} onBlur={handleBlur}></Form.Control>
              {errors.priceVariationJson && <p className="red">{errors.priceVariationJson}</p>}   

            <Form.Control type="text" placeholder="PreBufferHour" name="preBufferHour"   value={values.preBufferHour} onChange={handleChange} onBlur={handleBlur} ></Form.Control>
              {errors.preBufferHour && <p className="red">{errors.preBufferHour}</p>} 
              <Button type="submit" className="mt-2">  Submit </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CityForm;
