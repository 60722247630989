import {useEffect, useState} from 'react' 
import {useDispatch, useSelector} from 'react-redux';
import ItemConfigDataTable from '../../dataTables/ItemConfigDataTable' 
import {Button, Image, Form} from 'react-bootstrap';
import ServiceItemAddForm from './ServiceItemAddForm';
// import FormBuilder from './FormBuilder';
import {BASE_URL} from '../../BaseUrl';
import NavBarUpdate from "./NavBarUpdate";
import Layout from '../../components/Layout';
import ServiceItemConfiguration from './ServiceItemConfiguration';
import {
    updateItemColumns,
     fetchServiceItem, 
} from '../../reducers/CategorySlice';
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import {getStatusName} from '../../utility/GetStatusName';

import * as moment from 'moment'
// import {  itemRankUpdateApi, fetchServiceItem } from '../../reducers/FormBuilderSlice';
import { ToastContainer, toast } from 'react-toastify';

 

const ServiceItemList = () => {
    const { items } = useSelector((state) => state.category)

    const [configuration, setConfiguration] = useState(true);
    const [show, setShow] = useState(false);
    
    const dispatch = useDispatch()

const columns = [
        {name: "Item Title", sortable: true, cell: (row) => row.title},
        {name: "Description", minWidth: '220px', cell: (row) => row.details},
        {name: "Rank", sortable: true,  cell: (row) => row.rank},

        {name: "Registration Process",   cell: (row) => <div className='item-switch'>
        <Form.Check type="switch" name='isRegistrationProcess' defaultChecked={row.isRegistrationProcess === 'Y'? true:false}  
        onChange={(e)=>{
            
            let localData = null;
            items.map((e, index)=>e.id === row.id? localData = index:''); 
            const item ={};
            item.id =  row.id;
            item.textId =  row.textId;
            item.localData = localData;
            item.name= e.target.name;
            item.value= e.target.checked?'Y':'N';
            // console.log(item)
            const res= dispatch(updateItemColumns(item));
            res.then(results => {
                if(results.error) {toast(` Something went wrong. Please try again!`) }
            }).catch(error => {
                alert('error ---- false')
            });
    }}/>
    </div>
        },
                
        {name: "Show in the app",   cell: (row) =>  <div className='item-switch'>
        <Form.Check type="switch" name='isAppTopMenu' defaultChecked={row.isShowInAppTop === 'Y'? true:false}  
        onChange={(e)=>{
            
            let localData = null;
            items.map((e, index)=>e.id === row.id? localData = index:''); 
            const item ={};
            item.id =  row.id;
            item.textId =  row.textId;
            item.localData = localData;
            item.name= e.target.name;
            item.value= e.target.checked?'Y':'N';
            // console.log(item)
            const res= dispatch(updateItemColumns(item));
            res.then(results => {
                if(results.error) {toast(` Something went wrong. Please try again!`) }
            }).catch(error => {
                alert('error ---- false')
            });
    }}/>
    </div>
    },
        {name: "Skippable or not",   cell: (row) => 
            <div className='item-switch'>
        <Form.Check type="switch" name='isSkippable' defaultChecked={row.isSkippable === 'Y'? true:false}  
        onChange={(e)=>{
            
            let localData = null;
            items.map((e, index)=>e.id === row.id? localData = index:''); 
            const item ={};
            item.id =  row.id;
            item.textId =  row.textId;
            item.localData = localData;
            item.name= e.target.name;
            item.value= e.target.checked?'Y':'N';
            // console.log(item)
            const res= dispatch(updateItemColumns(item));
            res.then(results => {
                if(results.error) {toast(` Something went wrong. Please try again!`) }
            }).catch(error => {
                alert('error ---- false')
            });
    }}/>
    </div>
        },
        {name: "Is House or not",   cell: (row) => <div className='item-switch'>
        <Form.Check type="switch" name='isHouse' defaultChecked={row.isHouse === 'Y'? true:false}  
        onChange={(e)=>{
            
            let localData = null;
            items.map((e, index)=>e.id === row.id? localData = index:''); 
            const item ={};
            item.id =  row.id;
            item.textId =  row.textId;
            item.localData = localData;
            item.name= e.target.name;
            item.value= e.target.checked?'Y':'N';
            // console.log(item)
            const res= dispatch(updateItemColumns(item));
            res.then(results => {
                if(results.error) {toast(` Something went wrong. Please try again!`) }
            }).catch(error => {
                alert('error ---- false')
            });
    }}/>
    </div>},
        {name: "Is Bundle Item",   cell: (row) => <div className='item-switch'>
        <Form.Check type="switch" name='isBundleItem' defaultChecked={row.isBundleItem === 'Y'? true:false}  
        onChange={(e)=>{
            
            let localData = null;
            items.map((e, index)=>e.id === row.id? localData = index:''); 
            const item ={};
            item.id =  row.id;
            item.textId =  row.textId;
            item.localData = localData;
            item.name= e.target.name;
            item.value= e.target.checked?'Y':'N';
            // console.log(item)
            const res= dispatch(updateItemColumns(item));
            res.then(results => {
                if(results.error) {toast(` Something went wrong. Please try again!`) }
            }).catch(error => {
                alert('error ---- false')
            });
    }}/>
    </div>},
 
        {name: "Status", cell: (row) => <span className={`status ${row.status}`}> {getStatusName(row.status)}  </span>},
        {name: "created", cell: (row) => moment(new Date(row.created)).format("DD MMM YYYY,  h:mm A ")  },
    ]

    useEffect(() => {
        dispatch(fetchServiceItem())
        dispatch(breadcrumbTree({'Item Configuration ': ''}));

        document.title = 'Item Configuration';
    }, [dispatch])


    return (
        <>
            <Layout page_title={'Item Configuration'} 
            component={ <>
                <ItemConfigDataTable data={items} columns={columns} sortable={true} />
                </>
            }></Layout>
        </>
    )
}

export default ServiceItemList;