import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import AddNewTemplateForNotification from "../../component/notificationSetting/AddNewTemplateForNotification";
import { useDispatch, useSelector } from "react-redux";
 
import {fetchAllStageValue, fetchNotificationTemplateData} from "../../reducers/NotificationSlice";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import CustomDataTable from "../../components/CustomDataTable";
import Layout from "../../components/Layout";
import ExportFile from "../../component/utility-component/ExportFile";

const NotificationTemplate = () => {
  const [show, setShow] = useState(false);
  const [zipShow, setZipShow] = useState(false);
  const [query, setQuery] = useState('');
  const [alertSuccess, setAlertSuccess] = useState(false);
  const handleShow = () => setShow(true);

  const manageZipClose = () => setZipShow(false);
  const manageZipShow = () => setZipShow(true);

  const {data, stageValue} = useSelector((state) => state.notification);
  const dispatch = useDispatch();
 
  const handleClose = () => {
    setShow(false)
   setInitialData(() => ({
            update: false,
            notificationFrom: null,
            notificationTo: null,
            stageTitle: null,
            titleTemplate: null,
            messageTemplate: null, 
            }))};
   const [ initialData, setInitialData ] = useState({
      update: false,
      question: null,
      answer: null,
      faqCategory: null,
      faqDestination: null,
      rank:0,
      status: false,
    })

  useEffect(() => {
    dispatch(fetchNotificationTemplateData());
    dispatch(fetchAllStageValue());
    dispatch(breadcrumbTree({'Setting': 'setting','Notification Template List':''}));
    document.title = 'HelpAbode Notification Template Create by Admin';
    
  }, []);

  const columns = [
    // { name: "ID", sortable: true, cell: (row) => row.textId },
    { name: "Notification From", sortable: true, cell: (row) => row.notificationFrom },
    { name: "Notification To", sortable: true, cell: (row) => row.notificationTo },
    { name: "Stage Title", sortable: true, cell: (row) => row.stageTitle },
    { name: "Title Template", sortable: true, cell: (row) => row.titleTemplate },
    { name: "Message Template", sortable: true, cell: (row) => row.messageTemplate },
    { name: "status", minWidth: '100px', sortable: true, cell: (row) => row.status},
     
    {
      name: "Action",
      cell: (row) => (
        <span className="btn btn-default"
          onClick={() => {
            let localData = null;
            data.map((e, index)=>e.textId === row.textId? localData = index:'');
              setInitialData((initialData) => ({
              ...initialData,
              update: true,
              id: row.id, 
              notificationFrom: row.notificationFrom,
              notificationTo: row.notificationTo,
              stageTitle: row.stageTitle,
              titleTemplate: row.titleTemplate,
              messageTemplate: row.messageTemplate,
              status: row.status==='Y'?true:false,
              localData: localData
            }));
            setShow(true)
          }}
        >
          Configure
        </span>
    
      ),
    },
     
    // {
    //   name: "Delete",
    //   cell: (row) => (
    //     <Button
    //       variant="danger"
    //       onClick={() => {
    //         const approval = window.confirm("Are you sure you want to delete?");
    //         if (approval === true) {
              
    //             let updateIndex = null;
    //             data.map((e, index)=>{
    //               if(e.textId === row.textId) {return updateIndex = index;}
    //             });
    //             const data ={};
    //             data.textId =  row.textId;
    //             data.localData =  updateIndex;
 
    //           // dispatch(deleteCity(data));
    //         }
    //       }}
    //     >
    //       Delete
    //     </Button>
    //   ),
    // },
  ];
  return (
    <Layout page_title={'HelpAbode Admin Notification Template List'}
      component={
        <> 
            {stageValue&&show ? <AddNewTemplateForNotification data={initialData} stageArr={stageValue} close={handleClose} />:
            <div className='data-table-wrapper newNotification-table'>
               <div className="w-[100%] flex gap-2.5 relative mb-3">
                                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                                {/* <Button variant="primary" onClick={handleShow}> Add Attribute </Button> */}
                                <Button className="new-configuration-btn add-new" onClick={handleShow}> Add Item </Button>

                                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px] relative'>
                                    <input name="search" placeholder={'Search by Services Title or customer...'}
                                           type="text"
                                           className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                                           value={query ? query : ''}
                                           onChange={(e) => {
                                               setQuery(e.target.value)
                                           }}/>
                                    <div className={"absolute left-[12px] top-[12px]"}>
                                      <i className="fa fa-search" aria-hidden="true"></i>
                                    </div>
                                     
                                </div>
<div
                                        className="export-btn absolute right-0 top">
                                        <ExportFile data={data} fileName={'Notification-template-data'}/>
                                    </div>
                            </div>
              
              <CustomDataTable data={data} columns={columns} /></div>}

            
 
          
        </>
      }
    ></Layout>
  );
};

export default NotificationTemplate;
