import {Form, Modal, Button, Image} from "react-bootstrap";
import Layout from '../../components/Layout';
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {useFormik} from "formik";
import {breadcrumbTree} from '../../reducers/LocalDataSlice'
import {fetchServices, createService} from "../../reducers/ServiceItemSlice";
import {appendNewServices, fetchServiceWithCategory, successConfirmation} from '../../reducers/CategorySlice';
import ServicePlanAddComponent from "../../component/ServicePlanAddComponent";
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import {NameIcon} from "../../utility/StringImageIcon";


const ProdiverCardComponent = ({ data, worker, title, show,photo}) => {
    const [active, setActive] = useState(show);
    // const dispatch = useDispatch();
    // const [thumbImage, setImage] = useState(false);
    // const {item, service } = useParams();
    // const navigate = useNavigate();
    // const [clickCount, setClickCount] = useState(0);

    const handleToggle= () => setActive(!active);


    // const user_data = data.user_data;
    // const payment_summary = data.payment_summary;
    // const paymentArray = data.paymentArray;
    const address = worker.address.replaceAll("'",'"');
    const addressArr = JSON.parse(address)

console.log('worker1:', worker.address)
console.log('worker2:', addressArr)
// console.log('worker address', addressArr)

    return (
        <>
            <div
                className="w-[100%] mt-4 p-3 bg-white rounded-xl flex-col justify-start items-start gap-0 inline-flex">
                <strong>{title}</strong>
                <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>
                <div className="w-[100%] self-stretch pr-3 justify-start items-start gap-2 flex relative">
                    {/*{worker.selfieData &&*/}
                    {/*    <img className="w-11 h-11 rounded-full" src={`data:image/jpeg; base64, ${worker.selfieData}`} alt={worker.workerName}/> }*/}

                    {worker && worker.selfieData ?  <div className="profile-icon w-11 h-11 rounded-full truncate" style={{backgroundImage: `url(${BASE_URL}/${worker.selfieData})`}}>
                        {/* <img className="max-w-full" src={worker && `${BASE_URL}${worker.selfieData}`}/> */}
                        </div> :
                        <strong
                            className="w-11 h-11 rounded-full bg-[green] text-center text-white py-2.5 pt-[12px]">
                            {NameIcon(worker.workerName)}
                        </strong>}


                    {/*<img className="w-11 rounded-full"*/}
                    {/*     src="https://via.placeholder.com/44x44"/>*/}
                    <div className="flex-col justify-start items-start  inline-flex">
                        <strong>{worker && worker.workerName}</strong>
                        <span>{worker && worker.totalWorkerOrder} Orders </span>

                    </div>
                    <i
                        className={`right-[5px] top-[5px] absolute fa cursor-pointer ${
                            active ? 'fa-chevron-down':'fa-chevron-right' 
                        }`}
                        aria-hidden="true"
                        onClick={handleToggle}
                    ></i>
                </div>


                {active && <>
                    <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>
                    <strong> Services</strong>
                    {/* <div className="flex"><i className="fa fa-envelope mt-1" aria-hidden="true"></i>
                        <span className="email ml-3"> {worker && worker.email}</span>
                    </div> */}

                    <div className="flex capitalize"> <i className="fa fa-cogs" aria-hidden="true"></i>
                        <span className="email ml-3">{worker && worker.serviceTitle}</span></div>


                    <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>
                    <strong> Contact info</strong>
                    <div className="flex items-center"><i className="fa fa-envelope mt-1" aria-hidden="true"></i>
                        <span className="email ml-3"> {worker && worker.email}</span></div>

                    <div className="flex items-center"><i className="fa fa-phone mt-1" aria-hidden="true"></i>
                  
                        <span className="email ml-3">{worker && worker.phone}</span>
                    </div> 


                    <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>
                    {worker && addressArr.lenght>0 && (
                        <div className="flex-col justify-start items-start gap-2 flex">
                            <div className="text-black text-sm font-medium font-['Inter']">Address</div>
                            <div className="justify-start items-start gap-2.5 inline-flex">
                                <div className="text-neutral-600 text-sm font-normal font-['Inter'] leading-snug capitalize">
                                
                                    <ol className="address-list">
                                        {addressArr.map((item, index) => (
                                        <>{item.type==='Default Address'&&<li key={index} className="text-muted mb-0">
                                            {Object.keys(item).map((key, subIndex) => (
                                            <span key={key}>
                                                {key!=='type' && (
                                                <>{subIndex ? ', ' : ''}{item[key]}</>
                                                )}
                                            </span>
                                            ))}
                                        </li>}</>
                                        ))}
                                    </ol>
                                    
                                </div>
                                <div className="w-3.5 h-3.5 rounded-[75px]"></div>
                            </div>
                        </div>
                    )}  

                </>}
            </div>
            <div><br/></div>
        </>

    );
};

export default ProdiverCardComponent;
