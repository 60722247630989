 import React from 'react';
 import {useState, useEffect} from "react";

const SelectFieldComponent =  ({data, handleChangeOption, handleOptionRemove, reset}) => {
    const [active, setActive] = useState(0);
//  console.log('data:',data)
     useEffect(() => {
        setActive(0)
        const selecetd = data.options.findIndex(option => option.isActive)
        if(selecetd>0) {setActive(selecetd)}else{
            handleChangeOption( 0, true); 
        };
 console.log('active:',active, data, selecetd)
    
  }, [reset]);
   
  
 return (
<>
<select className={`form-select`} name={data.label} defaultValue={active}
          onChange={(e) =>{  handleChangeOption(active, null); handleChangeOption( e.target.value, true); setActive(e.target.value) }}>
{typeof(data.options) !=='string' && data.options.map((option, r) => (
    <option value={r} selected={r===active} > {option.title}</option> 
))}
</select>
 

</>
 )
}

export default SelectFieldComponent;