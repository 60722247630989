import { useEffect } from 'react';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
import  {initializeApp} from "firebase/app";
import { toast, ToastContainer } from "react-toastify";
import {getAuth} from "firebase/auth";
import Message from "./Message";
import {updateBellCount} from "../../reducers/NotificationSlice";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
// ... your Firebase configuration

function Firebase() {
  const dispatch = useDispatch(); 
  const firebaseConfig = {
    apiKey: "AIzaSyCDHIzO2Y8LAQnZ2P5egGqmoGhIH6W_GtE",
    authDomain: "team-abode.firebaseapp.com",
    projectId: "team-abode",
    storageBucket: "team-abode.appspot.com",
    messagingSenderId: "1005492825344",
    appId: "1:1005492825344:web:cc7b14b7a6d07fd66557e5",
    measurementId: "G-6TJYGFJPJB"
  };
    const VapidKey = 'BJD5wtSw0j1p_3Jm-tdTq88IxZAJPLetyJ2alf0ixEunKq-RC9VD4iUn1rvN0r9zxiPKHxEJyMTwvySK4Fd4Dr8';

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging();
    // const token = getToken(messaging);
    // console.log(token)

    // function requestNotificationPermission() {
    //     console.log('Requesting permission...');
    //     Notification.requestPermission()
    //         .then((permission) => {
    //             const requestNotifications = async () => {
    //                 try {
    //                     const permission = await getMessaging(app).requestPermission();
    //                     if (permission === 'granted') {
    //                         // Get the token
    //                         const token = await getToken(getMessaging(app));
    //                         console.log('FCM Token:', token);
    //                         // Send the token to your server for future use (optional)
    //                         // ...
    //                     } else {
    //                         console.log('Notification permission denied.');
    //                         // Handle permission denial (e.g., inform the user)
    //                     }
    //                 } catch (error) {
    //                     console.error('Error requesting notification permission/getting token:', error);
    //                 }
    //             };
    //             requestNotifications()
    //             console.log('Notification: ',Notification)
    //         })
    //         .catch((error) => {
    //             console.error('Error requesting notification permission:', error);
    //         });
    // }

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    Notification.requestPermission().then((result) => {
    console.log('Notification in useEffect:',result);
    });
 
    if (permission === "granted") {
 

     getToken(messaging, { vapidKey: VapidKey }).then((currentToken) => {
 
  if (currentToken) {
    console.log('currentToken: ', currentToken)
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});

      
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  // const messaging = getMessaging();


  useEffect(() => {
    requestPermission();

 
  }, [dispatch]);

  onMessage(messaging, (payload) => {
    // console.log("incoming msg",payload);
    dispatch(updateBellCount())

    toast(<Message notification={payload.notification} />);
  });
    return (
        <> 
        <ToastContainer />
        {/* <button onClick={() => requestNotificationPermission()}> ddddddddd</button> */}
        </>

    )


}

export default Firebase;

 
