import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import category from "../assets/images/category.png";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {pageTitle} from "../reducers/LocalDataSlice";
import {fetchServiceItem} from "../reducers/CategorySlice";

export default function NotFound() {
    const {title} = useSelector((state) => state.localData)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(pageTitle('404 Not Found | Help Abode Admin Panel'))
    }, [])
    return (
        <div className="container">
            <div className="left-side-bar">
                {/* <div className="logo">
                    <Link to="/dashboard" className="item"><img src={logo} alt="My logo" /> </Link >
                </div> */}
 
            </div>
            <div className="wrap"><div className="header-section"><span className="page-title">{title}</span>
             
        </div>
        <div className="content-block">
            <div className="block"> 
               <h1>404 Not Found</h1>
            <p>Here are some helpful links:</p>
            <Link to='/'>Home</Link>
          
            </div>
            </div>
            </div>
            </div>
         
    )
}