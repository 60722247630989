import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    pendingTeamMembers: [],
    error: ''
}

const url = `${BASE_URL}/worker`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchPendingTeamMembers = createAsyncThunk(
    'fetch/pendingTeamMembers', async () => {
        return axios
        .get(`${url}/pendings/`, config)
        .then((response) => response.data)
    }
)


const pendingTeamMembersSlice = createSlice({
    name: "pendingTeamMembers",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(fetchPendingTeamMembers.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchPendingTeamMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.pendingTeamMembers = action.payload;
        state.error = "";
      });
      builder.addCase(fetchPendingTeamMembers.rejected, (state, action) => {
        state.loading = false;
        state.pendingTeamMembers = [];
        state.error = action.error;
      });
    },
  });
  


export default pendingTeamMembersSlice.reducer


export const changeTeamMemberStatus = createAsyncThunk(
    "update/teamMemberStatus", async (formData) => {
        return axios
        .put(`${url}/status/${formData.textId}/`, {"status": formData.status}, config)
        .then((response) => console.log(response.data))
    }
)
