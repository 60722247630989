import React, { useState } from 'react';

const DragAndDrop = () => {
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (event, item) => {
    setDraggedItem(item);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    // Do something with the dropped item
    console.log('Dropped:', draggedItem);
    setDraggedItem(null);
  };

  return (
    <div>
      <div
        draggable
        onDragStart={(event) => handleDragStart(event, 'Item 1')}
      >
        Item 1
      </div>
      <div
        draggable
        onDragStart={(event) => handleDragStart(event, 'Item 2')}
      >
        Item 2
      </div>
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{ border: '1px dashed #ccc', padding: '10px', marginTop: '20px' }}
      >
        Drop here
      </div>
    </div>
  );
};

export default DragAndDrop;
