import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    modules: [],
    error: ''
}

const url = `${BASE_URL}/admin/user/module`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchModules = createAsyncThunk(
    'fetch/modules', async () => {
        return axios
        .get(url, config)
        .then((response) => response.data)
    }
)


const moduleSlice = createSlice({
    name: "modules",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(fetchModules.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchModules.fulfilled, (state, action) => {
        state.loading = false;
        state.modules = action.payload;
        state.error = "";
      });
      builder.addCase(fetchModules.rejected, (state, action) => {
        state.loading = false;
        state.modules = [];
        state.error = action.error;
      });
    },
  });
  


export default moduleSlice.reducer


export const createModule = createAsyncThunk(
  "create/module", async (formData) => {
    return axios
    .post(url, formData, config)
    .then((response) => response.data)
  }
)

export const updateModule = createAsyncThunk(
  "update/module", async (formData) => {
    return axios
    .put(`${url}/${formData.previousTextId}`, formData, config)
    .then((response) => response.data)
  }
)


export const deleteModule = createAsyncThunk(
  "delete/module", async (textId) => {
    return axios
    .delete(`${url}/${textId}`, config)
    .then((response) => response.data)
  }
)