

import React, { useState } from 'react';

function SubCategotyList({ data, values, category, handleSub_categoty }) {
  const [selectedSubItem, setSelectedSubItem] = useState(values);
  const [defaultData, setDefaultData] = useState(data);

  const handleCheckboxChange = (item) => {
//    if (item.target.checked) {
  // console.log(item.target.value);
//   setSelectedSubItem([...selectedSubItem, item.target.value]);
       handleSub_categoty(item.target.value, item.target.checked)
// } else {
//   setSelectedSubItem(selectedSubItem.filter(val => val !== item.target.value));
//   handleSub_categoty(selectedSubItem.filter(val => val !== item.target.value))
// }
  

  };

//   const handleViewClick = (item) => {
//     // Handle view logic here, e.g., open a modal or navigate to another page
//   };
  // console.log('--------------',values.length)
  console.log('in  category data: ',data)
  return (
    <ol>
      {data.length>0 && data.map((item, index) => ( 
        <li key={item.textId}>
            <div className="form-row">
              {item.textId ===category && values.length===0?<input name='sub_categories' type="checkbox" checked={true} id={`sub_category_${item.textId}`} value={item.textId} onChange={(e) => {
                  handleCheckboxChange(e);
                  // setDefaultData({ ...item, checked: true });
                  // handleSub_items()
                  }} />:<input name='sub_categories' type="checkbox" id={`sub_category_${item.textId}`} value={item.textId} onChange={(e) => {
                  handleCheckboxChange(e);
                 
                  // handleSub_items()
                  }} /> }
                
                {/* <input type="checkbox" id={`index${index}`} value={item.textId} checked={selectedSubItem === item.textId} onChange={(e) => {setSelectedSubItem(e.target.value);handleCheckboxChange(e)}} /> */}
                <label htmlFor={`sub_category_${item.textId}`}>{item.title}</label>
                 
          </div>
          {item.subcategories && <SubCategotyList data={item.subcategories} values={selectedSubItem} category={category} handleSub_categoty={handleSub_categoty}/>}
        </li>
      ))}
    </ol>
  );
}

export default SubCategotyList;