import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import CustomDataTable from '../../components/CustomDataTable'
import { Button, Image } from 'react-bootstrap'
import CategoryForm from './CategoryForm'
import FormBuilder from './FormBuilder'
import { BASE_URL } from '../../BaseUrl'
import Layout from '../../components/Layout'
import { fetchCategory, createCategory, updateCategory, deleteCategory } from '../../reducers/CategorySlice' 
const token = localStorage.getItem("token")
 
const CategoryList = () => {
  const feachData = useSelector((state) => state.category)
    const [query, setQuery] = useState('');
    const [show, setShow] = useState(false); 
    const handleClose = () => {
    setShow(false)
    setCategoryData(() => ({update: false}))};
    const handleShow = () => setShow(true);
    let location = useLocation();

    
  let tableData = feachData.categories;
  // if(typeof feachData.categories!=='undefined' && query){
  // tableData = feachData.categories.filter(item=>item.categoryTextId.toLowerCase().includes(query.toLowerCase())) 
  // }

  //  console.log(tableData)
    const dispatch = useDispatch()
    const [ categoryData, setCategoryData ] = useState({
        update: false,
        // id: null,
        // textId: null,
        title: null,
        categoryTextId: 'root',
        // breadcrumb: null,
        rank: null,
        status: null,
        itemDescription: null,
        image: null,
        icon: null,
        // zoneTextId: null
      })

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData)
        dispatch(createCategory(data)) 
         handleClose(true); 
      }
  
      const handleUpdate = (event) => {
        event.preventDefault();
    
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData)
            
        let updateIndex = '';
        feachData.categories.map((e, index)=>{
            if(e.id === categoryData.id) {return updateIndex = index;}
        });
 
         data.localData =  updateIndex;
 
        dispatch(updateCategory(data)) 
          handleClose(true);

      }
 
//  console.log(location.hash)
    useEffect(() => {
      if(location.hash == '#add-item')setShow(true);
      const urlArr = location.pathname.split("/")
        dispatch(fetchCategory(urlArr))
        
    }, [dispatch,location])

    const columns = [
        // {name: "ID", sortable: true, cell: (row) => row.id},
        // {name: "TextId", sortable: true, cell: (row) => row.textId},
        {name: "Title", sortable: true, cell: (row) => row.title},
        // {name: "ZoneTextId", sortable: true, cell: (row) => row.zoneTextId},
        {name: "CategoryTextId", sortable: true, cell: (row) => row.categoryTextId},
        // {name: "Breadcrumb", sortable: true, cell: (row) => row.breadcrumb},
        {name: "Rank", sortable: true, cell: (row) => row.rank},
        {name: "Status", sortable: true, cell: (row) => row.status},
        {name: "Image", sortable: true, cell: (row) => <Image src={`${BASE_URL}${row.image}`} height={'40'} width={'80'}/>},
        {name: "Icon", sortable: true, cell: (row) => <Image src={`${BASE_URL}${row.icon}`} height={'40'} width={'80'}/>},
       
        {name: "Update", cell: (row) => <Button onClick={() => {
          let localData = null;
           feachData.categories.map((e, index)=>{ if(e.id === row.id) {return localData = index; }});
            setCategoryData((categoryData) => ({
            ...categoryData,
            update: true,
            // id: row.id,
            textId: row.textId,
            title: row.title,
            // zoneTextId: row.zoneTextId,
            categoryTextId: row.categoryTextId,
            // breadcrumb: row.breadcrumb,
            rank: row.rank,
            status: row.status,
            image: '',
            icon: '',
            localData:localData
          }))
          setShow(true)
        }}>Update</Button>},
        {name: "Delete", cell: (row) => <Button variant="danger" onClick={() => {
          const approval = window.confirm("Are you sure you want to delete?")
          if (approval === true){
            let updateIndex = '';
                // tableData.map((e, index)=>{
                //   if(e.textId === row.textId) {return updateIndex = index;}
                // });
                const data ={};
                data.textId =  row.textId;
                data.localData = updateIndex.toString();
                console.log("🚀 ~ file: CategoryList.jsx:106 ~ data:", data)

            dispatch(deleteCategory(data))
            // window.location.reload()
          }
        }}>Delete</Button>},
    ]
    console.log(location)

    return (
    <>
       <Layout title={!show ? 'Service Item Home Page': 'Add Service Item' } component={
        <>
      
       {/* {show ? null :   <div className="search-header-area">
          <Button variant="primary" onClick={handleShow}> Add Category </Button>
        <input name="search" placeholder="Search by Category" type="text" className="form-control" value={query? query : ''} onChange={(e)=> setQuery(e.target.value)}/>
        </div>}
         {query && <span>{query}</span>} */}
         <div className='top-btn-area'>

         {!show && <button onClick={handleShow}  type="button" className="add-new-btn"> Add Service Item </button>}
          
          {!feachData.loading && tableData.length>0 ? tableData.map((item) => (
        <div className="card"> 
          <Link to={`${location.pathname}/${item.textId}`}>
            <img src={item.icon && `${BASE_URL}${item.icon}`} className='icon' /> 
            {item.title} 
            </Link>
              <span className='qty' 
              onClick={(e)=>{ console.log(item.textId, item.rank) }}>
                {item.rank}</span>          
              </div>
        ))
      : null }
      </div>
      
        {/* {show && <FormBuilder />} */}
        {/* <hr/>
        <hr/>
        <hr/> */}
          {show && <CategoryForm data={categoryData} close={handleClose}  show={show}/>}  
    
        {feachData.loading && <div>Loading ...</div>}
        {feachData.error ? <div className="alert alert-danger">Error: {feachData.error}</div> : null}
        {typeof feachData.success!='undefined' && feachData.success===true?<div className="alert alert-success">Success: Your Request has been successfully updated</div>:null}
           
           {/* {show ? null : <CustomDataTable data={tableData} columns={columns}/> } */}
        {/* {!feachData.loading ? 
            <CustomDataTable data={tableData} columns={columns}/>  
      : null } */}
        </>
      }></Layout>
    </>
  )
}

export default CategoryList