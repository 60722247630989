
import 'react-datepicker/dist/react-datepicker.css';
import React, {useEffect, useState} from "react";
import { useDispatch,useSelector } from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import {fetchProviderPriceListApi} from '../../reducers/TeamMemberSlice'
import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import {Link} from "react-router-dom";
import {fetchActiveServiceItem} from "../../reducers/CategorySlice";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import DatePicker from "react-datepicker";
import * as moment from 'moment'
import CustomDatePickerInput from '../../component/CustomDatePickerInput';
import SetProviderStatus from "../../component/provider/SetProviderStatus";
import GeneralInfo from "../../component/provider/GeneralInfo";
import BusinessInfo from "../../component/provider/BusinessInfo";
import CategoryRequested from "../../component/provider/CategoryRequested";
import UserOrders from "../../component/provider/ProviderOrders";
// import Button from 'react-bootstrap/Button';
import {filterDataTable3Col} from "../../utility/FilterDataTable";
import ExportFile from "../../component/utility-component/ExportFile";

const PriceManagement = () => {
    const [startDate, setStartDate] = useState(new Date());
    const {providerListByPrice, loading, error } = useSelector((state) => state.teamMembers) 
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = React.useState(new Date());
 
    const [query, setQuery] = useState('');
    const [filterBy, setFilterBy] = useState('');
    const [filterByStatus, setFilterByStatus] = useState('');

 let tableData = providerListByPrice.filter(item => item['status']?.toLowerCase().includes(filterByStatus.toLowerCase())) 
    // let tableData = tableData1.filter(item => item['employeeType']?.toLowerCase().includes(filterBy.toLowerCase())) 
    let tableData2 = tableData.filter(item => item['serviceTitle']?.toLowerCase().includes(query.toLowerCase()))
    let tableData3 = tableData.filter(item => item['workerName']?.toLowerCase().includes(query.toLowerCase()))
    let tableData4 = tableData.filter(item => item['email']?.toLowerCase().includes(query.toLowerCase())) 

    const uniqueData = tableData2;
    //  const uniqueData = filterDataTable3Col('email',tableData2, tableData3, tableData4);

    const columns = [
        // {name: "ID", sortable: true, cell: (row) => row.id},
        // {name: "TextId", sortable: true, cell: (row) => row.textId},

        {name: "Services", sortable: true, selector: (row) => row.serviceTitle},
        {name: "service provider", sortable: true, selector: (row) => row.providerName},
        {name: "Email", sortable: true, cell: (row) => row.franchiseTextId},
        {name: "Requested date", sortable: true, selector: (row) => moment(new Date(row.requested_date)).format("DD MMM YYYY,  h:mm A")},  
        {name: "Status", sortable: true, selector: (row) => <span className={`status ${row.status}`}> {row.status}</span>},
        // {name: "Status", sortable: true, cell: (row) => <>
        // <Form.Select aria-label={`${row.status}`} onChange={(e) => {
        //     const formData ={
        //         textId: row.textId,
        //         status: e.target.value
        //     }
        //     const approval = window.confirm("Are you sure you want to change the status?")
        //     if(approval){
        //         dispatch(changeTeamMemberStatus(formData))
        //     }
        // }}>
        //     <option selected disabled>{row.status}</option>
        //     <option value='Pending'>Pending</option>
        //     <option value='Pre Approved'>Pre Approved</option>
        //     <option value="Approved">Approved</option>
        // </Form.Select>
        // </>},
       
        {name: "Action", selector: (row) => <Link to={`/provider/${row.workerTextId}/${row.serviceTextId}`}
                                              className="btn btn-default"> Details</Link>},
        
    
    ]

    useEffect(() => {
        let res=dispatch(fetchProviderPriceListApi())
        res.then((results) => {
            dispatch(breadcrumbTree({' Provider Request':'request', ' Manage Price ':'manage-price'}));
            document.title = 'Provider Listing';
            // dispatch(breadcrumbTree([{'Service ': 'service-area'},results.payload.pageBreadcrumb]));
            // dispatch(breadcrumbTree({'Service ':'service-area', 'nur':'dddd'}));
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [dispatch])



  return (
    <Layout page_title={'Price Request List'}
     component={
        <>
    {loading && <div>Loading ...</div>}
    {!providerListByPrice && error ? <div>Error: {error}</div> : null}
    {!loading ?
        <>
        <div className='data-table-wrapper order-table'>
 

            <div className="w-[100%] flex gap-2.5 relative mb-3">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                    <input name="search" placeholder={'Search by Services Title or customer...'}
                            type="text"
                            className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                            value={query ? query : ''}
                            onChange={(e) => {
                                setQuery(e.target.value)
                            }}/>
                    <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search"
                                                                            aria-hidden="true"></i>
                    </div>
                    <div
                        className="export-btn absolute right-0 top">
                        <ExportFile data={uniqueData} fileName={'Price-request-list'}/>
                    </div>
                </div>

            </div>
            <CustomDataTable data={uniqueData} columns={columns}/>
            </div>
        </>
        : null}
        </>
    } 
    top_content={
        <div className="search-header-area">
           
           
            <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                            value={filterByStatus} onChange={(e) => {
                setFilterByStatus(e.target.value); 
            }}>
                <option value=''>Filter By Status</option> 
                <option value='Pending'>Pending</option>
                <option value="Approved">Approved</option>
                <option value='Rejected'>Rejected</option>
                <option value='Pause'>Pause</option>
            </Form.Select>
        </div>
    }></Layout>
  )
}

export default PriceManagement