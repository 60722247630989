import 'react-datepicker/dist/react-datepicker.css';

import Layout from '../../components/Layout'

import Firebase from "../../component/firebase/Firebase";
import  {useEffect} from "react";




const TestingFCMNotification = () => {

 

    return (
        <Layout component={
            <>
                <hr />
                <hr />
                <hr />
                <Firebase />
                <hr />
                <hr />
                <hr />

nur

            </>
        }  ></Layout>
    )
}

export default TestingFCMNotification