import React from 'react';
import {useForm, useFieldArray} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux'
import {useState} from "react";
import {updateSubItemPriceConfiguration} from '../../../reducers/FormBuilderSlice';

const ConfigurationPriceComponent = ({data, index, callFrom}) => {
    //   const [query, setQuery] = useState('');
    //   const [label, setLabel] = useState('Label Title');
    //   const { register, handleSubmit, control, formState: { errors } } = useForm();
    //
    const dispatch = useDispatch()
    //   const handleUngroupService = (val) => {
    //   console.log(typeof(val))
    //   if(val === 'true'){dispatch(updateGroup([index, column, false, parrent]))
    //   }else{    dispatch(updateGroup([index, column, true, parrent]))}
    //
    // };
    // console.log('data:', data)

    const handleIsPrice = (column, val) => {

        // console.log([index[0], column, val, index[1],callFrom])
        // console.log(callFrom,'lllllllllllllllllllllll')
        // dispatch(updateGroup([index[0], column, val, index[1]]))

        if (val === 'true') {
            dispatch(updateSubItemPriceConfiguration([index[0], column, "Y", index[1], callFrom]))
        } else {
            dispatch(updateSubItemPriceConfiguration([index[0], column, 'N', index[1], callFrom]))
        }
    };

    return (
        <>
            <div className='is-price'>
                <span className='title'>Allow for Pricing Configuration</span>
                <input
                    type="radio"
                    id={`${index[0]}_${index[1]}priceYes`}
                    name={`${index[0]}_${index[1]}priceYes`}
                    value="true"
                    checked={data.isPrice === 'Y'}
                    onChange={(e) => handleIsPrice('isPrice', e.target.value)}
                />
                <label htmlFor={`${index[0]}_${index[1]}priceYes`}>Yes</label>
                <input
                    type="radio"
                    id={`${index[0]}_${index[1]}priceNo`}
                    name={`${index[0]}_${index[1]}priceYes`}
                    value="false"
                    checked={data.isPrice === 'N'}
                    onChange={(e) => handleIsPrice('isPrice', e.target.value)}
                />
                <label htmlFor={`${index[0]}_${index[1]}priceNo`}>No</label>
            </div>
        </>
    );
}

export default ConfigurationPriceComponent;
