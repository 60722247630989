//  import { Form, Button } from "react-bootstrap";
 import { Form, Modal, Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

 import { useDispatch, useSelector } from "react-redux";
 import React, { useState, useEffect } from "react";
//  import axios from "axios";
//  import { BASE_URL } from "../BaseUrl";
 import { createServiceCustomization, fetchServicesCustomization } from "../reducers/ServiceCustomizationSlice";

const SuccessComponent = ({ close, message }) => {

  // const serviceCustomization = useSelector((state) => state.serviceCustomization);
  // const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(()=>{close()}, 1600)
  }, []);

  return (
    <> 
    
        <div className="custom-modal success-modal">
        <div className="modal-dialog"></div>
            <div className="modal-content">
                <div className="alert-popup-container">

                    <div className="success-checkmark">
                        <div className="check-icon">
                            <span className="icon-line line-tip"></span>
                            <span className="icon-line line-long"></span>
                            <div className="icon-circle"></div>
                            <div className="icon-fix"></div>
                        </div>
                    </div>

                    {/*<i className="fa fa-exclamation-triangle" aria-hidden="true"></i>*/}
                    <div className="alert-popup-title">Success!!</div>
                    <div className='alert-popup-message'>{message}</div>
                    {/*<div className="alert-popup-title">Success!!!</div>*/}
                    <button className="btn" onClick={close}>OK, Got it!</button>

                </div>

            </div>
        </div>
    </>
  );
};


export default SuccessComponent;

