 import React from 'react';
 import {useState, useEffect} from "react";

const RadioFieldComponent = ({data, handleChangeOption, handleOptionRemove, reset}) => {
    const [active, setActive] = useState(0);
//  console.log('data:',data)
     useEffect(() => {
        const selecetd = data.options.findIndex(option => option.isActive)
        if(selecetd>0) {setActive(selecetd)}else{
            setActive(0)
            handleChangeOption( 0, true); 
        };
//  console.log('active:',active, data, selecetd)
    
  }, [reset]);
   
  
 return (
<>
{typeof(data.options) !=='string' && data.options.map((option, r) => (
    <div className='radio-field'>
            <span className='remove-option'  onClick={()=>{handleOptionRemove(r)}}><i className="fa fa-times" aria-hidden="true"></i></span>
        <input type={data.labelType} id={`${data.label}_${r}`} name={data.label} value={option.title} // Set default value
checked={r===active} onChange={(e) =>{  handleChangeOption(active, null); handleChangeOption( r, true); setActive(r) }}
            />
        <label className='span' htmlFor={`${data.label}_${r}`}>{option.title}</label>
        {/* {child.isActive&&setActive(r)} */}
    </div>
    
))}
</>
 )
}

export default RadioFieldComponent;