import React, {useState} from 'react';
import CategoriesModal from "../../modal/CategoriesModal";
import ZipCodeModal from "../../modal/ZipCodeModal";

const AreaComponent = ({data, zoneIndex, zipIndex, zoneTextId, workerTextId}) => {
    const [show, setShow] = useState(true)
    const [model, setModel] = useState(false);
    const handleClose = () => setModel(false)
    const handleClick = (e) => show ? setShow(false) : setShow(true);

    const appendData = {
        ...data, // Spread the existing object
        zoneRow: zoneIndex,
        zipRow: zipIndex,
        zoneTextId: zoneTextId,
        workerTextId: workerTextId
    };
    console.log('AreaComponent',data.zip)
    return (
        <>
            {/*{zoneIndex} |{zipIndex}*/}
            <div className="item d-flex justify-content-between align-items-center bg-light rounded-8"
                 onClick={() => setModel(true)}>
                <div className='address'> {data.zip && <span>{data.zip}</span>}{data.city && <span>, {data.City}</span>}{data.state && <span>, {data.state},</span>}{data.country && <span>, {data.country},</span>}   </div>
                <span className={`status-${data.status}`}>{data.status}</span>


            </div>
            {model && <ZipCodeModal data={appendData} close={handleClose}/>}

        </>
    );
};

export default AreaComponent;