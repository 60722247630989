 import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FieldsetBuilderForm from "./FieldBuilderForm";
import { useDispatch, useSelector } from "react-redux";
 
import {
  allfieldset,
  createfieldset,
  // deletefieldset,
  // fetchfieldset,
  // updatefieldset,
} from "../../reducers/FieldsetBuilderSlice";
import CustomDataTable from "../../components/CustomDataTable";
import Layout from "../../components/Layout";

const FieldsetBuilderList = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const fieldset = useSelector((state) => state.fieldset);
  const fieldsetBuilder = useSelector((state) => {
    return state.fieldsetBuilder;
  });
  const dispatch = useDispatch();

  const [fieldsetBuilderData, setfieldsetBuilderData] = useState({
    update: false,
    id: null,
    fieldId: null,
    label: null,
    delaultData: null,
    placeholder: null,
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    // console.log(data);
    dispatch(allfieldset(data));
    // dispatch(createfieldset(data));

    // window.location.reload();
        setShow(false);
  };

  const handleUpdate = (event) => {
    // event.preventDefault();


    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    // dispatch(updateCity(data));
    // window.location.reload();
  };

  // useEffect(() => {
  //   dispatch(allfieldset());
  // }, [dispatch]);

  const columns = [
    { name: "ID", sortable: true, cell: (row) => row.id },
    { name: "Field Id", sortable: true, cell: (row) => row.fieldId },
    { name: "label", sortable: true, cell: (row) => row.label },
    { name: "delaultData", sortable: false, cell: (row) => row.delaultData },
    { name: "placeholder", sortable: false, cell: (row) => row.placeholder },
  ];
  return (
 
    <Layout
      component={
        <>
          <Button variant="primary" onClick={handleShow}>
            Add City
          </Button>
      
        
          <FieldsetBuilderForm
            fieldsetBuilderData={fieldsetBuilderData}
            handleClose={handleClose}
            handleSubmit={fieldsetBuilderData.update ? handleUpdate : handleSubmit}
            show={show}
          />
            {console.log(fieldsetBuilderData)}
            {console.log('---------')}
          {fieldsetBuilder.loading && <div>Loading ...</div>}
          {!fieldsetBuilder.loading && fieldsetBuilder.error ? (
            <div>Error: {fieldsetBuilder.error}</div>
          ) : null}
          {!fieldsetBuilder.loading ? ( 
               <CustomDataTable data={fieldsetBuilder.fieldsetBuilder} columns={columns} />
          ) : null}
      {console.log(fieldsetBuilder)}
   
 
        </>
      }
    ></Layout>
  );
};

export default FieldsetBuilderList;
