import { Form, Modal, Button } from "react-bootstrap";

const ModuleForm = ({ moduleData, handleClose, show, handleSubmit }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
        className="service-form-modal"
      >
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {moduleData.textId != null ? (
              <Form.Control
                type="hidden"
                name="previousTextId"
                defaultValue={moduleData.textId}
              ></Form.Control>
            ) : (
              <></>
            )}
            <Form.Label htmlFor="textId">TextId</Form.Label>
            <Form.Control
              type="text"
              name="textId"
              defaultValue={moduleData.textId}
            ></Form.Control>
            <Form.Label htmlFor="title">Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              defaultValue={moduleData.title}
            ></Form.Control>
            <Button type="submit" className="mt-2">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModuleForm;
