import React, {useState} from 'react';
import AreaComponent from "./AreaComponent";

const AreasListComponent = ({data, zoneIndex, workerTextId}) => {
    const [show, setShow] = useState(true)
    const handleClick = (e) => show?setShow(false):setShow(true);
console.log(data)
    return (
        <>

            <div className={show ? 'accordion-item active':'accordion-item'}>
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                    <button onClick={() => handleClick(show)}
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                    >
                        {data.zoneTitle}
                    </button>
                </h2>
                <div
                    id="panelsStayOpen-collapseOne"
                    className={show ? 'accordion-collapse collapse show':'accordion-collapse collapse'}
                    aria-labelledby="panelsStayOpen-headingOne"
                >

                    <div className={show ? "accordion-body active" : "accordion-body"}>

                        {data.zipcode.length>0 && data.zipcode.map((item, index) => (
                            <AreaComponent data={item} zoneIndex={zoneIndex} zipIndex={index} zoneTextId={data.zoneTextId} workerTextId={workerTextId}/>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AreasListComponent;