
import 'react-datepicker/dist/react-datepicker.css';
import React, {useEffect, useState} from "react";
import { useDispatch,useSelector } from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import {Link} from "react-router-dom";
import {fetchActiveServiceItem} from "../../reducers/CategorySlice";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import DatePicker from "react-datepicker";
import {filterDataTable3Col} from "../../utility/FilterDataTable";
import ExportFile from "../../component/utility-component/ExportFile";
import {changeAreaRequestStatus, fetchAreaRequest} from "../../reducers/AreaRequestSlice";
// import Button from 'react-bootstrap/Button';

const CategoryRequestList = () => {
    const [startDate, setStartDate] = useState(new Date());
    const { allRequest, loading, error } = useSelector((state) => state.areaRequest)
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = React.useState(new Date());
 
 
    const [query, setQuery] = useState('');
    const [filterBy, setFilterBy] = useState('');
    const [filterByStatus, setFilterByStatus] = useState('');

 let tableData = allRequest.filter(item => item['status']?.toLowerCase().includes(filterByStatus.toLowerCase())) 
    // let tableData = tableData1.filter(item => item['employeeType']?.toLowerCase().includes(filterBy.toLowerCase())) 
    let tableData2 = tableData.filter(item => item['serviceTitle']?.toLowerCase().includes(query.toLowerCase()))
    let tableData3 = tableData.filter(item => item['workerName']?.toLowerCase().includes(query.toLowerCase()))
    let tableData4 = tableData.filter(item => item['email']?.toLowerCase().includes(query.toLowerCase())) 

    const uniqueData = tableData2;
    //  const uniqueData = filterDataTable3Col('email',tableData2, tableData3, tableData4);

    const columns = [
        {name: "Service Title", sortable: true, cell: (row) => row.serviceTitle},
        {name: "Email", sortable: true, cell: (row) => row.email},
        {name: "Worker Name", sortable: true, cell: (row) => row.workerName},
        // {name: "Worker TextId ", sortable: true, cell: (row) => row.workerTextId},
        {name: "zip Code", sortable: true, cell: (row) => row.zipCode},
        {name: "Status", sortable: true, cell: (row) => <span className={`status ${row.status}`}> {row.status}</span>},
        {name: "Status", sortable: true, cell: (row) => <>
                <Form.Select aria-label={`${row.status}`} onChange={(e) => {
                    const formData = {
                        status: e.target.value,
                        workerTextId: row.workerTextId,
                        franchiseTextId: row.franchiseTextId,
                        zipCode:row.zipCode,
                        serviceTextId: row.serviceTextId,
                        serviceTitle: row.serviceTitle,
                        workerName: row.workerName
                    }
                    const approval = window.confirm("Are you sure you want to change the status?")
                    if (approval) {
                        dispatch(changeAreaRequestStatus(formData))
                    }
                }}>
                    <option selected disabled>{row.status}</option>
                    <option value='Pending'>Pending</option>
                    <option value="Approved">Approved</option>
                    <option value='Rejected'>Rejected</option>
                    <option value='Pause'>Pause</option>
                </Form.Select>
            </>
        },
        // {name: "Update", cell: (row) => <Link to={`/provider/${row.workerTextId}`}
        //                                       className="btn btn-default"> Details</Link>},

    ]

    useEffect(() => {
        let res=dispatch(fetchAreaRequest())
        if(res){
            dispatch(breadcrumbTree({' Provider Request':'request', ' Area ':'area'}));
            document.title = 'Provider Listing';
        }
    }, [dispatch])



    return (
        <Layout page_title={'Service Request Area List'}
         component={
            <>
                {loading && <div>Loading ...</div>}
                {/*{!teamMembers && error ? <div>Error: {error}</div> : null}*/}
                {!loading ?
                    <div className='data-table-wrapper order-table'>
 

                            <div className="w-[100%] flex gap-2.5 relative mb-3">
                                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                                    <input name="search" placeholder={'Search by Services Title or customer...'}
                                           type="text"
                                           className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                                           value={query ? query : ''}
                                           onChange={(e) => {
                                               setQuery(e.target.value)
                                           }}/>
                                    <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search"
                                                                                          aria-hidden="true"></i>
                                    </div>
                                    <div
                                        className="export-btn absolute right-0 top">
                                        <ExportFile data={uniqueData} fileName={'Category-request-list'}/>
                                    </div>
                                </div>

                            </div>
                        <CustomDataTable data={uniqueData} columns={columns}/>
                    </div>
                    : null }
            </>
        }
        top_content={
        <div className="search-header-area">
           
           
            <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                            value={filterByStatus} onChange={(e) => {
                setFilterByStatus(e.target.value); 
            }}>
                <option value=''>Filter By Status</option> 
                <option value='Pending'>Pending</option>
                <option value="Approved">Approved</option>
                <option value='Rejected'>Rejected</option>
                <option value='Pause'>Pause</option>
            </Form.Select>
        </div>
    }
    ></Layout>
    )
}

export default CategoryRequestList