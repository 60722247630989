import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    roles: [],
    error: ''
}

const url = `${BASE_URL}/admin/user/role`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchRoles = createAsyncThunk(
    'fetch/roles', async () => {
        return axios
        .get(url, config)
        .then((response) => response.data)
    }
)


const roleSlice = createSlice({
    name: "roles",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(fetchRoles.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload;
        state.error = "";
      });
      builder.addCase(fetchRoles.rejected, (state, action) => {
        state.loading = false;
        state.roles = [];
        state.error = action.error;
      });
    },
  });
  


export default roleSlice.reducer


export const createRole = createAsyncThunk(
  "create/role", async (formData) => {
    return axios
    .post(url, formData, config)
    .then((response) => response.data)
  }
)

export const updateRole = createAsyncThunk(
  "update/role", async (formData) => {
    return axios
    .put(`${url}/${formData.previousTextId}`, formData, config)
    .then((response) => response.data)
  }
)


export const deleteRole = createAsyncThunk(
  "delete/role", async (textId) => {
    return axios
    .delete(`${url}/${textId}`, config)
    .then((response) => response.data)
  }
)