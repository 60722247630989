import {Form, Modal, Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
//  import Firebase from "../../component/firebase/Firebase";
import CountriesTables from "./CountriesTables";
 


const TestingFilterComponent = ({close, parentTextId}) => {

    return (
        <>
         
        </>
    );
};

export default TestingFilterComponent;
