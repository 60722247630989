import React from 'react';
 

const ShimmerAnimation = ({data, width, height, borderRadius = 8 }) => {
  return (
    <div
      className="shimmer-animation"
      style={{ width: width, height: height, borderRadius: borderRadius }}
    ></div>
  );
};

export default ShimmerAnimation;
