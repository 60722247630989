import * as Yup from "yup";

export const serviceSchema = Yup.object({
  // textId: Yup.string().min(3).max(99).required("Please enter TextId"),
  title: Yup.string().max(99).required("Please enter Title"),
  shortDescription: Yup.string().required("Please enter Service Description"),
  // sub_categories: Yup.array().required("Select a category item"),
  sub_items: Yup.array().of(Yup.string()).required("Select a Configuration"),
  // optionJson: Yup.string().required("Please enter priceVariationJson"),
  // priceVariationOnTimeJson: Yup.string().required("Please enter state"),
  // priceVariationForNonWorkingDayJson: Yup.string().required("Please enter countryIso2Code"),
  // priceVariationJson: Yup.string().required("Please enter zoneTextId"),
  // hourNeeded: Yup.number().required("Please enter zoneTextId"),
});

 