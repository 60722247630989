import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

 
 const WebSocket = () => {
  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState('ws://enduser.dev.helpabode.com:54238/ws/notifications/555fccab2d7fa69952ad19698262c894/');
  // const [socketUrl, setSocketUrl] = useState('ws://192.168.1.17:54238/ws/notifications/555fccab2d7fa69952ad19698262c894/');
  const [messageHistory, setMessageHistory] = useState([]);

  const { sendJsonMessage, lastJsonMessage,  sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
//   onOpen: () => console.log('WebSocket connection opened!'),
//   onClose: () => console.log('WebSocket connection closed!'),
//   onError: (event) => console.error('WebSocket error:', event),
  // onMessage: (event) => console.log('Received message:', event.data),
  onMessage: (event) => {
      try {
        console.log('Received message:', JSON.parse(event.data));
      } catch (error) {
        console.error('Error parsing message data:', error);
      }
    },
});

  useEffect(() => {
    if (lastJsonMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastJsonMessage));
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    console.log("Connection state changed")
    if (readyState === ReadyState.OPEN) {
      // sendJsonMessage({
      //   event: "subscribe",
      //   data: {
      //     channel: "general-chatroom",
      //   },
      // })
    }
  }, [readyState])

    useEffect(() => {
    console.log(`Got a new message: ${lastJsonMessage}`)
  }, [lastJsonMessage])

  const handleClickChangeSocketUrl = useCallback(
    // () => setSocketUrl('ws://192.168.1.17:54238/ws/notifications/555fccab2d7fa69952ad19698262c894/'),
    () => setSocketUrl('ws://enduser.dev.helpabode.com:54238/ws/notifications/555fccab2d7fa69952ad19698262c894/'),
    []
  );
//   console.log('sendMessage', sendMessage)
  console.log('lastMessage', lastMessage)
  console.log('readyState', readyState)

  const handleClickSendMessage = useCallback(() => sendMessage('Hello'), []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <div>
      <button className='btn' onClick={handleClickChangeSocketUrl}>
        Click Me to change Socket Url
      </button> || 
      <button className='btn'
        onClick={handleClickSendMessage}
        disabled={readyState !== ReadyState.OPEN}
      >
        Click Me to send 'Hello'
      </button>

      <br />
      <span>The WebSocket is currently {connectionStatus}</span>
      <br />
      {lastMessage ? <span>Last message: {lastMessage.data}</span> : null}
      <ul>
        {messageHistory.map((message, idx) => (
          <span key={idx}>{message ? message.data : null}</span>
        ))}
      </ul>
    </div>
  );
}
export default WebSocket