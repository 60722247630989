import { useDispatch, useSelector } from "react-redux";
import { getToken, getMessaging, onMessage, isSupported } from 'firebase/messaging';
import  {initializeApp} from "firebase/app";
import { toast, ToastContainer } from "react-toastify";
// import {getAuth} from "firebase/auth";
import Message from "./Message";
import { saveFCMTokenPost, removeFCMTokenPost } from '../reducers/AuthSlice';
import {updateBellCount} from "../reducers/NotificationSlice";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import {saveFCMToken, removeFCMToken} from '../utility/FcmTokenSaveVsRemove';
 
import "react-toastify/dist/ReactToastify.css";
// ... your Firebase configuration

function Firebase() {
  // const user = useSelector((state) => state.auth)
  const [fcmToken, setFcmToken] = useState(false);
  const dispatch = useDispatch();
    const firebaseConfig = {
      apiKey: "AIzaSyCDHIzO2Y8LAQnZ2P5egGqmoGhIH6W_GtE",
      authDomain: "team-abode.firebaseapp.com",
      projectId: "team-abode",
      storageBucket: "team-abode.appspot.com",
      messagingSenderId: "1005492825344",
      appId: "1:1005492825344:web:cc7b14b7a6d07fd66557e5",
      measurementId: "G-6TJYGFJPJB"
    };
    const VapidKey = 'BJD5wtSw0j1p_3Jm-tdTq88IxZAJPLetyJ2alf0ixEunKq-RC9VD4iUn1rvN0r9zxiPKHxEJyMTwvySK4Fd4Dr8';

    const app = initializeApp(firebaseConfig);

     
  
    

    
  const fcmtoken = localStorage.getItem("fcmToken");
  async function requestPermission(messaging) {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    Notification.requestPermission().then((result) => {
    // console.log('Notification in useEffect:',result);

    });
  
 
 
    if (permission === "granted") {
        //  messaging = getMessaging(app);
     getToken(messaging, { vapidKey: VapidKey }).then((currentToken) => {
      // console.log(fcmtoken)
      // console.log(currentToken)
        if (currentToken === fcmtoken) {
          
        } else {
            const res= dispatch(removeFCMTokenPost(removeFCMToken()));
            res.then(()=>{
              localStorage.setItem("fcmToken",currentToken); 
              dispatch(saveFCMTokenPost(saveFCMToken()));
            })
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        
      });
      
    } else if (permission === "denied") {
       const res= dispatch(removeFCMTokenPost(removeFCMToken()));
        res.then(()=>{
              // localStorage.removeItem("fcmToken");
            })
    }else {
       const res=dispatch(removeFCMTokenPost(removeFCMToken()));
          res.then(()=>{
              // localStorage.removeItem("fcmToken");
            })
    }
     
  }

  useEffect(() => {
    const check= isSupported(app);
    check.then((result)=>{
    if(result=== true){
          requestPermission(getMessaging(app)); 
            onMessage(getMessaging(app), (payload) => {
          dispatch(updateBellCount())
          toast(<Message notification={payload.notification} />);
        });
    }
    })
  }, []);

 


    return (
        <>
          <ToastContainer />
          {/* <div> test Firebase Notification</div> */}
        </>

    )

}

export default Firebase;

 
