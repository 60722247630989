//  import { Form, Button } from "react-bootstrap";
import {Form, Modal, Button, Image} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import React, {useState, useEffect} from "react";
//  import axios from "axios";
import {BASE_URL} from "../BaseUrl";
import {createServiceCustomization, fetchServicesCustomization} from "../reducers/ServiceCustomizationSlice";
import {useFormik} from "formik";
import {createCityZip, updateCityZip} from "../reducers/CityZipSlice";
import {zipSchema} from "../schemas/zip";
import {workerStatusUpdate} from "../reducers/TeamMemberSlice";


const OrderViewModal = ({close, data}) => {
    const dispatch = useDispatch()
    const confirmRequest = ()=>{

        const res = dispatch(workerStatusUpdate(data))
        if (res) {
            close()
        }
    }

    return (
        <>
            <div id="orderView" className="custom-modal">
                <div className="modal-dialog"></div>
                <div className="modal-content">
                    <div className="modal-header"><div className="text-lg">Service Details</div>
                        <span className="close-modal" onClick={close}>&times;</span>
                    </div>
                    <div className="modal-body">
                        <div className="content-area mb-3 ounded-sm border-1">
                            {/*<div className="service-plan-title">Deep Clean</div>*/}
                            {/*<p>${data.serviceAmount}</p>*/}
                            {/*<p>{data.servicePlanDescription}</p>*/}
                        </div>

                        <div className="w-[100%] rounded-lg border border-emerald-700">
                            <div
                                className="w-[100%] bg-emerald-700 text-white text-base font-semibold font-['Inter'] px-4 py-3">
                                {data.servicePlanName}
                            </div>

                            <div
                                className="m-3 text-black text-lg font-semibold font-['Inter']">
                                ${data.serviceAmount}
                            </div>
                            <div
                                className="m-3 text-neutral-600 text-sm font-medium font-['Inter'] leading-[25px]">
                                {data.servicePlanDescription}
                            </div>
                            <div
                                className="mx-3 mb-3 flex-col justify-start items-start inline-flex">
                                <div className="text-black text-base font-semibold font-['Inter'] py-2">Customized options
                                </div>
     

                            </div>

                        </div>

                    </div>
                    <div className="modal-footer">
                        {/*     <button type="submit" id="cEmailSentBtn" className="btn btn-default"  > Send</button> */}
                         <div
                            className="w-[100px] py-2.5 px-9   bg-gray-200 rounded-[50px] justify-center items-center  inline-flex">
                            <div className="text-black  font-semibold font-['Inter'] tracking-wide" onClick={close}>Close</div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};


export default OrderViewModal;

