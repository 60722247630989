import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
const initialState = {
    loading: false,
    selected: [],
    error: ''
}
const token = localStorage.getItem("token")
const url = `${BASE_URL}/service/categories/`

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}
 

export const searchCategorie = createAsyncThunk(
  "create/categoryService", async (formData) => {
    return axios
    .post(url, formData, config)
    .then((response) => response.data)
  }
)

export const createCategoryService = createAsyncThunk(
  "update/categoryService", async (formData) => {
    // console.log("🚀 ~ file: CategoryServiceSlice.js:31", formData)
    return axios.put(url+formData.serviceTextId, formData, config).then((response) => response.data);
  }
)

export const deleteCategoryService = createAsyncThunk(
  "delete/categoryService", async (data) => {
    return axios
    .delete(url+data.serviceTextId, data, config).then((response) => response.data);
  }
)

export const deleteCategoryServiceItem = createAsyncThunk(
  "delete/categoryService/id", async (data) => {
    return axios 
    .delete(url+data.serviceTextId+'/'+data.id, config).then((response) => response.data);
  }
)

export const deleteCategoryServiceAll = createAsyncThunk(
  "delete/categoryService", async (textId) => {
    return axios
    .delete(`${url}${textId}`, config).then((response) => response.data)
  }
)


export const fetchCategoryService = createAsyncThunk(
    'fetch/categoryService', async (textId) => {
        return axios
        .get(`${url}${textId}`, config)
        .then((response) => response.data)
    }
)


const categoryServiceSlice = createSlice({
    name: "categoryService",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(fetchCategoryService.pending, (state) => {
        state.loading = true;

      });
      builder.addCase(fetchCategoryService.fulfilled, (state, action) => {
        state.loading = false;
        state.selected = action.payload;
        state.error = "";
          console.log('fetchCategoryService.fulfilled');
          console.log(action.payload);
      });
      builder.addCase(fetchCategoryService.rejected, (state, action) => {
        state.loading = false;
        state.selected = [];
      });
      
      builder.addCase(createCategoryService.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createCategoryService.fulfilled, (state, action) => {
        state.loading = false;
        state.selected = action.payload;
        state.error = "";
          // console.log(action.payload);
      });
      builder.addCase(createCategoryService.rejected, (state, action) => {
        state.loading = false;
        state.selected = [];
        // console.log(action);
      });

      builder.addCase(searchCategorie.fulfilled, (state, action) => {
        state.loading = false;
        // state.selected = action.payload;
           state.selected.push(action.payload);
        state.error = "";
       
      });
      builder.addCase(searchCategorie.rejected, (state, action) => {
        state.loading = false;
        state.selected = [];
      });

 
    //   builder.addCase(updateService.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.selected[action.payload.localData] = action.payload;
    //     state.error = "";
    //     console.log(action.payload);
    //   });

    // builder.addCase(updateService.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    //    console.log(action.error);
    // });

      builder.addCase(deleteCategoryServiceAll.fulfilled, (state, action) => {
        state.loading = false;
        state.selected=[]
        state.error = "";
        console.log(action.payload);
         console.log('deleteCategoryServiceAll.fulfilled');
      });

    builder.addCase(deleteCategoryServiceAll.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
       console.log(action.error);
        console.log('deleteCategoryServiceAll.rejected');
    }); 

      builder.addCase(deleteCategoryServiceItem.fulfilled, (state, action) => {
        state.loading = false;
        state.selected.categories.splice(action.payload.localData, 1);
        state.error = "";
        console.log(action.payload);
        console.log('deleteCategoryServiceItem.fulfilled');
      });

    builder.addCase(deleteCategoryServiceItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
       console.log(action.error);
        console.log('deleteCategoryServiceItem.rejected');
    }); 

    },
  });
  


export default categoryServiceSlice.reducer

