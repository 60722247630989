import React, {useState, useEffect} from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {BASE_URL} from "../../BaseUrl";
import {itemRankUpdateApi, deleteService} from "../../reducers/CategorySlice";
import {useDispatch} from "react-redux";
import SuccessComponent from "../../modal/SuccessComponent";
import {getZipStatus} from '../../utility/GetStatusName';
 

const   CardViewForServiceComponent=({each, editUrl, viewUrl}) => {
    const [showCardNavigation, setShowCardNavigation] = useState(false);
    // let location = useLocation();
    const {item} = useParams()
    const dispatch = useDispatch()
    const [successModal, setSuccessModal] = useState(false);
    const navigate = useNavigate();
    
    const handleRankUpdate = (id, rank) => {
        if (rank) {
            dispatch(itemRankUpdateApi({textId: id, rank: rank}))
        }
    }
    // const handleStatus = (id, status) => {
    //     console.log(id, status)
    //     setShowCardNavigation(false)
    //     // dispatch(itemRankUpdateApi({textId: id, status: status}))
    //     setSuccessModal(true)

    // }
    const closeSuccessModal = (url) => {
        setSuccessModal(false)
    };
 

 
 

    return (
        <>

            <div className="card">

                <Link to={viewUrl} className='link'>
                <img src={each.serviceImage && `${BASE_URL}${each.serviceImage}`} className='icon' alt={each.serviceTitle}/> {each.serviceTitle}
                </Link> 

                <span className="fa-solid fa-ellipsis-vertical status-action-btn"
                      onClick={() => setShowCardNavigation(!showCardNavigation)}></span>
                <hr/>
                <div className={`status-text ${each.status}`}>{getZipStatus(each.areaAddStatus)}</div>
                <input type='text' className='qty'
                       onChange={(e) => handleRankUpdate(each.textId, e.target.value)}
                       defaultValue={each.rank}/>

                {showCardNavigation && <>
                    <div className="card-nav">

                        <div className="item edit" onClick={() => {
                            navigate(viewUrl , { state: item })
                        }}><i className="fa-solid fa-pen"></i> <strong>View</strong></div>

                        {/* <div className="item edit" onClick={() => {
                            navigate(editUrl , { state: item })
                        }}><i className="fa-solid fa-pen"></i> <strong>Edit</strong></div> */}

                        {/* /service-area/:item/zone/:id */}
                      

                        {each.areaAddStatus==="No"&&<div className="item edit" onClick={() => {
                            navigate(`/service-area/${item}/zone/${each.serviceTextId}#view` , { state: each })
                        }}><i className="fa-solid fa-pen"></i> <strong>Add Service Area</strong></div>}

                        {each.areaAddStatus==="Yes"&&<div className="item edit" onClick={() => {
                            navigate(`/service-area/${item}/zone/${each.serviceTextId}#view` , { state: each })
                        }}><i className="fa-solid fa-pen"></i> <strong>Edit Service Area</strong></div>}

                        <div className="item unpause" 
                            onClick={() => {
                            const approval = window.confirm("Are you sure you want to delete?")
                            if (approval === true){
                                const res =dispatch(deleteService(each.serviceTextId));
                                 res.then(results => {
                                    // window.location.reload()
                                     if(results.error){
                                        
                                     }
                                }).catch(error => {
                                    alert('error ---- false')
                                });
                                
                                                             
                            }
                            }}><i className="fa fa-trash" aria-hidden="true"></i> <strong>Delete Item </strong>
                        </div>


                        {/* <div className="item edit" onClick={() => {
                            navigate(editUrl , { state: each })
                        }}><i className="fa-solid fa-pen"></i> <strong>View Service Area</strong></div> */}
                        
                        {/* <div className="item draft" onClick={() => handleStatus(each.textId, 'draft')}><i
                            className="fa-sharp fa-solid fa-envelope-open"></i>
                            <strong>Draft</strong></div> */}
                        {/* <div className="item pause" onClick={() => handleStatus(each.textId, 'pause')}><i
                            className="fa-solid fa-pause"></i> <strong>Pause</strong></div> */}
                        {/* <div className="item unpause" onClick={() => handleStatus(each.textId, 'unpause')}><i
                            className="fa-solid fa-pause"></i> <strong>Unpause</strong>
                        </div> */}
                    </div>

                </>
                }
            </div>

            {showCardNavigation &&
                <div className="modal-bg" onClick={() => setShowCardNavigation(!showCardNavigation)}></div>}
            {successModal && <SuccessComponent close={closeSuccessModal}/>}
        </>

    );
};

export default CardViewForServiceComponent;
