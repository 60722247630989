import React from 'react';

const formatNumber = (number) => (number >= 1000 ? (number / 1000).toFixed(1) + 'k' : number);
const ServiceCardComponent = ({data, index}) => {


    return (
        <>
            <div className="col-md-4 col-sm-4 service-card" key={index}>
                <div className="card card-flush rounded-12 mb-0 h-100">
                    <div className="card-body py-4 d-flex justify-content-between align-items-center">
                        <div className="info-txt">
                            <h3>{data.cardTitle}</h3>
                            <div className="i-counter">{formatNumber(data.totalEntry)}  </div>
                        </div>
                        <div
                            className="circle-icon d-flex justify-content-center align-items-center rounded-circle px-2 py-2"
                            style={{width: 50, height: 50}} >
                            <img
                                src={`/svg/${data.cardTitle.toLowerCase().replace(/\s+/g, '-')}.svg`}
                                alt=""
                            />
                        </div>
                    </div>
                    <a href={data.viewAll} className="card-footer border-top-0 d-flex justify-content-between align-items-center text-decoration-none"
                    >
                        <span className='view'>View {data.cardTitle}</span>
                        <i className="fa fa-arrow-right text-secondary"/>

                    </a>
                </div>
            </div>

        </>
    );
}

export default ServiceCardComponent;
