import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    status: false,
    loading: false,
    service: {},
    error: '',
    res: 'n'
}

const url = `${BASE_URL}/service/customize`

const token = localStorage.getItem("token")

const config = {
    headers: {
      Authorization: `Token ${token}`,
    }
}

export const fetchServicesCustomization  = createAsyncThunk(
    'fetch/customize', async () => {
        return axios
        .get(url, config)
        .then((response) => response.data)
    }
)
 
export const updateServiceCustomization = createAsyncThunk(
  "update/customize", async (formData) => {
    console.log(formData)
    return axios
    .put(`${url}${formData.textId}`, formData, config)
    .then((response) => response.data)
  }
)

export const createServiceCustomization = createAsyncThunk(
  "create/customize", async (formData) => {
    console.log(formData)
    return axios
    .post(url, formData, config)
    .then((response) =>  response.data)
  }
)

 

export const deleteServiceCustomization = createAsyncThunk(
  "delete/customize/", async (formData) => {
    return axios
    .delete(`${url}/${formData.textId}`, config)
    .then((response) => { 
    response.data.localData = formData.localData;
    return response.data; 
     })
  }
)


const serviceCustomizationSlice = createSlice({
    name: "customize",
    initialState,
    reducers: {
    updateState(state, action) {
      state.res = action.payload;
    },
  },
    extraReducers: (builder) => {
      // fetchServicesCustomization
      builder.addCase(fetchServicesCustomization.pending, (state) => {
        state.res = 'n';
        state.loading = true;
        // state.service = [];
      });
      builder.addCase(fetchServicesCustomization.fulfilled, (state, action) => {
        state.loading = false;
        state.service = action.payload;
        state.status = true;
        // state.res = 'n';
        // console.log("fetchServicesCustomization.fulfilled") 
      });
      builder.addCase(fetchServicesCustomization.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.error; 
      });
      // createServiceCustomization
      builder.addCase(createServiceCustomization.fulfilled, (state, action) => {
        state.loading = false;
        state.res = action.payload.textId;
        state.service.forms.push(action.payload);
    
        state.error = ""; 
      });
      builder.addCase(createServiceCustomization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; 
        console.log(action);
   
      });

      // deleteServiceCustomization

       builder.addCase(deleteServiceCustomization.fulfilled, (state, action) => {
        state.loading = false;
        // state.service.forms.push(action.payload.data); 
          state.service.forms.splice(action.payload.localData, 1);
      });
      builder.addCase(deleteServiceCustomization.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
  
      });
    },
  });
  

export const { updateState } = serviceCustomizationSlice.actions;
export default serviceCustomizationSlice.reducer