// DroppableComponent.js
import React from 'react';
import { useDrop } from 'react-dnd';
import { useEffect, useState } from 'react'
import { dropElement } from '../../reducers/FormBuilderSlice';
import { useDispatch, useSelector } from 'react-redux'

const DroppableComponentInner = (id) => {
  const [droppedItems, setDroppedItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState(false);
  const dispatch = useDispatch()
  const [{isOver}, drop] = useDrop({
    accept: 'ROOT', // Define the same type here for compatibility
    drop: (item) => {
      item.textId = '';
      // item.push({id:id})
      item.rel = id.index;
      item.isPrice = 'N';
      // item.sizeExists = 'N';
      item.defaultValue = 0;
      item.pricingBy = null;
      item.rank = 100;
      item.optionalSizeUnit = true;
      item.ungroupService = null;
      item.instructionsEndUser = null;
      item.instructionsProvider = null;
     
      if(item.labelType!=='number' && item.btnName!=="textareaField" ){
        item.optionalSize = true;
      }else{
        item.optionalSize = false;
      }
      
      // console.log(item)
      if(item.btnName!=='labelField'){ dispatch(dropElement(item))}
      else {
          setAlertMessage(true)
          console.log('not allow');
          setTimeout(()=>{
            setAlertMessage(false)
          },1000)
      }

        setDroppedItems((prevItems) => [...prevItems, item]); 
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const removeItem = (indexToRemove) => {
    setDroppedItems((prevItems) =>
      prevItems.filter((_, index) => index !== indexToRemove)
    );
  };
  return (
    <div className=''>
    <div className={`${alertMessage?'alert alert-danger':''}`}>{alertMessage && `Not Allowed!!`}</div>
         <div ref={drop}  className='drop-area'>
      {isOver ? 'Drop here' : 'Drag items here'}
    </div>
    </div>
    
    
  );
};

export default DroppableComponentInner;
