import React, { useState } from 'react';

const DynamicCheckboxes = ({data}) => {
console.log(data)
//   const initialCheckboxes = ['checkbox1', 'checkbox2', 'checkbox3']; // Example array of checkbox names
  const initialCheckboxes = data ;// Example array of checkbox names
  const [checkboxes, setCheckboxes] = useState(() => {
    const initialState = {};
    initialCheckboxes.forEach(name => {
      initialState[name.name] = true; // Set initial state for each checkbox to true
    });
    return initialState;
  });

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxes(prevState => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName] // Toggle the checkbox state
    }));
    console.log('---',checkboxName)
  };

  return (
    <div>
      {initialCheckboxes.map(name => (
        <label key={name.name}>
          <input
            type="checkbox"
            // checked={checkboxes[name.name]}
            onChange={() => handleCheckboxChange(name.name)}
          />
          {name.name}
        </label>
      ))}
    </div>
  );
};

export default DynamicCheckboxes;
