import React, { useState } from 'react';

const ServicePlanAddComponent = ({handleChange, handleSetValues }) => {

  const [textId, setTextId] = useState('New Plan Title');
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');
  const [active, setActive] = useState('true');

    const handleInputChange = (e) => {
        let value = e.target.value;
        setTextId(value.replace(/\s/g, '_'));
        setTitle(e.target.value);
    };

  return (
      <div className="add-new-service-plan">
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="service_plan"
                    id={textId}
                    value={textId}
                    onChange={(e)=>{
                       handleSetValues(textId,details);
                        if(e.target.checked){
                            setActive('false')
                        } else {
                            setActive('true')
                        }
                        handleChange(e)
                       
                        //  setValues({ ...values, [textId]:details});
                    }}
                />
                {active==='true' ?
                    <input  type='text' className="form-check-label service-plan-add" placeholder='New Plan Title'
                                  value={title} onChange={handleInputChange}/>:
                    <label className="form-check-label" >{title}</label>
                }

            </div>


          
         <div className={`input-area ${active==='false'? 'show':' '}`}>
              <strong className="field-label desc">Description<span>*</span></strong>
              <textarea
              id={textId}
              name={textId}
              placeholder="Type description here..."
              value={details}
              onChange={(e)=>{setDetails(e.target.value);handleChange(e)}}
          />
        </div>
      </div>
  );
};

export default ServicePlanAddComponent;
