import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    allRequest: [],
    profile:'',
    error: ''
}

const url = `${BASE_URL}/settings`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchCategoryRequest = createAsyncThunk(
    'fetch/categoryRequest', async () => {
        return axios
        .get(`${url}/workerCategoryAcceptReject/`, config)
        .then((response) => response.data)
    }
)

export const changeCategoryRequestStatus = createAsyncThunk(
    "update/categoryRequest", async (formData) => {
        return axios
        .post(`${url}/workerCategoryAcceptReject/`, [formData], config)
        .then((response) => console.log(response.data))
    }
)


const categoryRequestSlice = createSlice({
    name: "CategoryRequest",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(fetchCategoryRequest.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchCategoryRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.allRequest = action.payload.category_list;
          if(action.payload.category_list) {
              // action.payload.category_list.forEach((item) => {
              //     if (item.status !== 'Approved') {
              //         state.allRequest.push(item);
              //     }
              // });
          }
        state.error = "";
      });
      builder.addCase(fetchCategoryRequest.rejected, (state, action) => {
        state.loading = false;
        state.allRequest = [];
        state.error = action.error;
      });

      builder.addCase(changeCategoryRequestStatus.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(changeCategoryRequestStatus.fulfilled, (state, action) => {
        state.loading = false;
        // state.allRequest = action.payload.category_list;
        state.error = "";
      });
      builder.addCase(changeCategoryRequestStatus.rejected, (state, action) => {
        state.loading = false;
        // state.allRequest = [];
        state.error = action.error;
      });



    },
  });
  


export default categoryRequestSlice.reducer


 
