import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    user: null,
    error: ''
}


const token = localStorage.getItem("token")
const textId = localStorage.getItem("textId")

const url = `${BASE_URL}/admin/user/${textId}`

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchUser = createAsyncThunk(
    'fetch/user', async () => {
        return axios
        .get(url, config)
        .then((response) => response.data)
    }
)


const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(fetchUser.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = "";
      });
      builder.addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.user = {};
        state.error = action.error;
      });
    },
  });
  


export default userSlice.reducer