import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../BaseUrl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import React from "react";

const initialState = {
    loading: false,
    title: '',
    breadcrumb: []
}
const localDataSlice = createSlice({
    name: "formbuilder",
    initialState,
    reducers: {
        pageTitle(state, action) {
            state.title = action.payload;
        },

        breadcrumbTree(state, action) {
            let data= action.payload;
            // console.log(typeof action.payload.length)
            if('number'=== typeof action.payload.length){
                  data = action.payload[0];
                action.payload[1].forEach(currentObject => {
                    const key = Object.keys(currentObject)[0];
                    data[key] = currentObject[key];
                });
                console.log(data)
            }

            let fullLink = '';
            state.breadcrumb = data;
            {
                Object.keys(data).map((item,index) => {
                    fullLink += '/' + data[item];
                    state.breadcrumb[item] = (index<3 && data[item]  ? fullLink : '')
                  // console.log(index)
                })
            }

        }
    }
});


export const {pageTitle, breadcrumbTree} = localDataSlice.actions;
export default localDataSlice.reducer

