import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { useState } from "react";
// import { updateElement } from '../../reducers/FormBuilderSlice'; 
 

const TextFieldComponent = ({ data, index, handleChange }) =>{
 
  return (
    <>
       <div className='from-group'>
          <label>Label</label>
          <input className="form-control" value={data.label} type='text' onChange={(e) => handleChange('label', e.target.value)}/>
      </div>
    </>
  );
}

export default TextFieldComponent;
