import React, { useState, useEffect } from 'react';

const convertData = (data) => {
  const newData = {};
  data.forEach((item) => {
    const { stateTitle, cityTitle, zip } = item;
    if (!newData[stateTitle]) {
      newData[stateTitle] = {};
    }
    if (!newData[stateTitle][cityTitle]) {
      newData[stateTitle][cityTitle] = [];
    }
    newData[stateTitle][cityTitle].push(zip);
  });
  return newData;
};

 
const ServiceZoneComponent = ({ data }) => {
  const [newData, setNewData] = useState({}); 

  useEffect(() => {
    if (data.length > 0) {
      setNewData(convertData(data));
    }
  }, [data]);

  return (
    <div className="service-zone-container">
      <div className='title'>Service Zones </div>

      {Object.keys(newData).map((state) => (
        <div key={state} className="service-state">
          <sotong>State</sotong>: {state}
         
            {Object.keys(newData[state]).map((city) => (
             <div className='service-city'><sotong> City:</sotong>  {city} 
             <div className='service-zip'><sotong>Zip Code:</sotong> {newData[state][city].join(', ')}</div>
             </div>
            ))}
       
        </div>
      ))}
    </div>
  );
};

export default ServiceZoneComponent;
