import React, { useState } from 'react';

const SelectServiceItemComponent = ({item, checked, index, handleChange}) => {

  const [active, setActive] = useState(checked);

    const handleInputChange = (e) => {
        setActive(active => !active); // Toggle the active state
        handleChange(e);
    };

    return (
      <div className="form-row">
          <input className="form-check" type="checkbox" id={`index${index}`}
                 name='sub_items' value={item.subItemTextId} checked={active}
                 // checked={serviceDetails.itemArray[index]}
                 onChange={handleInputChange}/>
          <label htmlFor={`index${index}`}>{item.subItemTextTitle}</label>
      </div>
  );
};

export default SelectServiceItemComponent;
