import React from 'react';


const OrderManagementComponent = ({data}) => {
    return (
        <>

                    <div className="col-md-6 col-sm-6 mb-md-0 mb-4">
                        <div className="card card-flush rounded-12 mb-0 h-100">
                            <div className="card-body py-5 px-5 d-flex justify-content-between align-items-center">
                                <div className="left d-flex gap-4 align-items-center">
                                    <div
                                        className="circle-icon d-flex justify-content-center align-items-center rounded-circle px-2 py-2"
                                        style={{width: 72, height: 72}}
                                    >
                                        <img src="./svg/acc.svg" alt=""/>
                                    </div>
                                    <div className="info-txt">
                                        <h3>Services</h3>
                                        <div className="i-counter">12 items</div>
                                    </div>
                                </div>
                                <div className="right">
                                    <button className="btn py-0 px-3 rounded-pill border-success">
                                        Manage
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


        </>
    );
}

export default OrderManagementComponent;
