import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    unauthorized: true,
    loading: false,
    data: [],
    notice: [],
    stageValue: [],
    count: 0,
    error: ''
}

const url = `${BASE_URL}/service`

const token = localStorage.getItem("token")
const textId = localStorage.getItem("textId")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchAllStageValue = createAsyncThunk(
    'fetch/GetTemplateStageValue', async () => {
        return axios
        .get(`${url}/AllNotificationTemplateKeyValue/`, config)
        .then((response) => response.data)
    }
)

export const fetchNotificationTemplateData = createAsyncThunk(
    'fetch/GetTemplate', async () => {
        return axios
        .get(`${url}/GetAllNotificationTemplate/`, config)
        .then((response) => response.data)
    }
)

// export const fetchNotificationBellCount = createAsyncThunk(
//     'fetch/NotificationBellCount', async () => {
//         return axios
//         .get(`${url}/${textId}/notificationBellCount/`, config)
//         .then((response) => response.data)
//     }
// )

export const fetchNotificationBellCount = createAsyncThunk(
  'fetch/NotificationBellCount', async () => {
    try {
      const response = await axios.get(`${url}/${textId}/notificationBellCount/`, config);
      return response.data;
    } catch (error) {      
      return Promise.reject(error.response.status);  
    }
  }
);


export const markAllRead = createAsyncThunk(
    'fetch/MarkAllRead', async () => {
        return axios
        .get(`${url}/UpdateAllNotificationStatus/`, config)
        .then((response) => response.data)
    }
)

export const addNotificationTemplatePost = createAsyncThunk(
    "create/SaveTemplate", async (data) => {
        console.log('data', data)
        const cUrl = `${url}/SaveNotificationTemplate/`;
        return axios
            .post(cUrl, data, config)
            .then((response) => response.data)
    }
)


export const updateNotificationTemplatePut = createAsyncThunk(
    "update/UpdateTemplate", async (data) => {
        const cUrl = `${url}/UpdateNotificationTemplate/`;
        return axios
            .put(cUrl, data, config)
            .then((response) => response.data)
    }
)

export const readNotification = createAsyncThunk(
    "update/readNotification", async (data) => {
       
        const cUrl = `${url}/UpdateNotificationStatus/`;
        return axios
            .put(cUrl, data, config)
            .then((response) => response.data)
    }
)

// export const updateCity = createAsyncThunk(
//   "update/city", async (formData) => {
//     return axios
//     .put(`${url}${formData.textId}`, formData, config)
//     .then((response) =>{ 
//     response.data.localData = formData.localData;
//     console.log("🚀 ~ file: CitySlice.js:59 ~ .then ~ formData:", formData)
//     return response.data; 
//      } 
//   )
//   }
// )

export const fetchNotificationData = createAsyncThunk(
    'fetch/NotificationData', async () => {
        return axios
        .get(`${url}/${textId}/GetAllNotification/`, config)
        .then((response) => response.data)
    }
)


const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {

        updateBellCount(state, action) {
            state.count = state.count+1;
            console.log('bell count incrememt!!')
        },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchNotificationData.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchNotificationData.fulfilled, (state, action) => {
        state.loading = false;
        state.notice = action.payload.results;
        state.count = action.payload.notificationCount;
        state.error = "";
      });
      builder.addCase(fetchNotificationData.rejected, (state, action) => {
        state.loading = false;
        state.notice = [];
        state.error = action.error;
      });
      builder.addCase(fetchNotificationTemplateData.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchNotificationTemplateData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.results;
        state.error = "";
      });
      builder.addCase(fetchNotificationTemplateData.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error;
      });
      builder.addCase(markAllRead.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(markAllRead.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload.results){
        state.notice = action.payload.results;}
        state.error = "";
        console.log(action.payload.results)
      });
      builder.addCase(markAllRead.rejected, (state, action) => {
        state.loading = false;
        state.notice = [];
        state.error = action.error;
      });

      builder.addCase(fetchAllStageValue.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchAllStageValue.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload.results){
          console.log('ssssssssssssss', action.payload)
          state.stageValue = action.payload.results.notificationStages;
        }
        state.error = "";
      });
      builder.addCase(fetchAllStageValue.rejected, (state, action) => {
        state.loading = false;
        state.stageValue = [];
        state.error = action.error;
      });
      
      builder.addCase(updateNotificationTemplatePut.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateNotificationTemplatePut.fulfilled, (state, action) => {
        state.loading = false;
        state.data[1] = action.payload.results.notificationStages;
        // state.cities.push(action.payload);
        state.error = "";
      });
      builder.addCase(updateNotificationTemplatePut.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      builder.addCase(addNotificationTemplatePost.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(addNotificationTemplatePost.fulfilled, (state, action) => {
        state.loading = false;
         
       
        state.data.push(action.payload.results);
        state.error = "";
      });
      builder.addCase(addNotificationTemplatePost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      builder.addCase(fetchNotificationBellCount.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchNotificationBellCount.fulfilled, (state, action) => {
        state.loading = false;
        state.unauthorized= true;
        state.count =action.payload.results;
        state.error = "";
      });
      builder.addCase(fetchNotificationBellCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        if(action.error.message){
          state.unauthorized= false
        }
      });

      // builder.addCase(readNotification.pending, (state) => {
      //   state.loading = true;
      // });
      // builder.addCase(readNotification.fulfilled, (state, action) => {
      //   state.loading = false;
         
      //   console.log('ooooooooo',action.payload)
      //   state.notice[action.payload.data.index]='Y';
      //   state.error = "";
      // });
      // builder.addCase(readNotification.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error;
      // });
    },
  });
  
export const { updateBellCount } = notificationSlice.actions;

export default notificationSlice.reducer

 
