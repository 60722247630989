import React from 'react';
import {useEffect, useState} from 'react'
import CommonHeader from './CommonHeader'

const FooterComponent = ({show}) => {
  
    return (
        <footer className={!show ? 'content selected' : 'content'}>
            ©2024 Help Abode Admin Panel. All Rights Reserved.
        </footer>
    );
};

export default FooterComponent;