//  import { Form, Button } from "react-bootstrap";
import {Form, Modal, Button, Image} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import React, {useState, useEffect} from "react";
//  import axios from "axios";
import {BASE_URL} from "../BaseUrl";
import {createServiceCustomization, fetchServicesCustomization} from "../reducers/ServiceCustomizationSlice";
import {useFormik} from "formik";
import {createCityZip, updateCityZip} from "../reducers/CityZipSlice";
import {zipSchema} from "../schemas/zip";
import {workerStatusUpdate} from "../reducers/TeamMemberSlice";


const ApproveWorkerModal = ({close, data}) => {
    const dispatch = useDispatch()
    const confirmRequest = ()=>{

        const res = dispatch(workerStatusUpdate(data))
        if (res) {
            close()
        }
    }

    return (
        <>
            <div className="custom-modal approve-worker">
                <div className="custom-dialog close-modal"></div>

                <div className="modal-content">

                    <div className="modal-body">
                        <img src="/svg/worker-approved.svg" alt=""/>
                        <div className='message'>Successfully approved the provider</div>
                        <Button type="submit" className="btn btn-success rounded-pill px-4" onClick={()=>confirmRequest()}> Ok, got it! </Button>

                    </div>

                </div>
            </div>
        </>
    );
};


export default ApproveWorkerModal;

