import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useNavigate} from "react-router-dom";
// import CustomDataTable from '../../components/CustomDataTable';
import {Button, Image} from 'react-bootstrap';
import ServiceItemAddForm from './ServiceItemAddForm';
// import FormBuilder from './FormBuilder';
import {BASE_URL} from '../../BaseUrl';
import NavBarUpdate from "./NavBarUpdate";
import Layout from '../../components/Layout';
import ServiceItemConfiguration from './ServiceItemConfiguration';
import {
    createCategory,
    updateCategory,
    deleteCategory,
    fetchServiceItem,
    itemRankUpdateApi, successConfirmation
} from '../../reducers/CategorySlice';
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import {addChildInServiceItem} from "../../reducers/NavDataSlice";
import LoadingComponent from "../../modal/LoadingComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import SuccessModalComponent from "../../modal/SuccessModalComponent";
import CardNavigationForItemComponent from "../../component/items/CardNavigationForItemComponent";
// import {  itemRankUpdateApi, fetchServiceItem } from '../../reducers/FormBuilderSlice';
// import {getStatusName} from '../../utility/GetStatusName';

const ServiceItemList = () => {
    const {
        activeItems,
        nav,
        loading,
        ref,
        itemAddStatus,
        items,
        success,
        error,
        status
    } = useSelector((state) => state.category)

    const [configuration, setConfiguration] = useState(true);
    const [show, setShow] = useState(false);
    const [editId, setEditId] = useState('');
    const [newItemAddStatus, setNewItemAddStatus] = useState('nur');
    const navigate = useNavigate();
    const handleClose = () =>  setEditId('')
    const handleShow = () => setShow(true);
    const handleConfig = (textId) => setNewItemAddStatus(true);
    let location = useLocation();
    if (newItemAddStatus === true && itemAddStatus === true) {
        setNewItemAddStatus(false);
        navigate('./' + ref, {state: {mess: 'newItem'}});
    }
    const handleSuccess = () => dispatch(successConfirmation());
    const handleEdit = (id) => {setEditId({...items[id], index: id,})};

    // if(typeof categories!=='undefined' && query){
    // items = categories.filter(item=>item.categoryTextId.toLowerCase().includes(query.toLowerCase()))
    // }
    // console.log(items)
    // console.log(SideBarData[1].child)
// {SideBarData[1].child.length <2 &&

//   items.map((item) =>(

//     SideBarData[1].child.push( {
//         name: item.title,
//         link: "/service-items/"+item.textId,
//         rank: item.rank,
//         icon: "approved-member.png",
//         child: [],
//       }))
//   )}

    //  console.log(items)
    const dispatch = useDispatch()


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData)
        dispatch(createCategory(data))
        // handleClose(true);
    }

    const handleRankUpdate = (id, rank) => {
        if (rank) { dispatch(itemRankUpdateApi({textId:id, rank:rank})) }
    }
    
    if (nav) { dispatch(addChildInServiceItem({'nav': items, 'rank': 1})); }

    useEffect(() => {
        if (location.hash === '#add-item') setShow(true);
        // const urlArr = location.pathname.split("/")
        dispatch(fetchServiceItem())
        dispatch(breadcrumbTree({'Item Listing ': ''}));

        document.title = 'Service Item Listing';
    }, [dispatch, location])


    return (
        <>
            <Layout page_title={'Item Listing '} component={
                <>
                    {/*<NavBarUpdate />*/}
                    {/* {show ? null :   <div className="search-header-area">
          <Button variant="primary" onClick={handleShow}> Add Category </Button>
        <input name="search" placeholder="Search by Category" type="text" className="form-control" value={query? query : ''} onChange={(e)=> setQuery(e.target.value)}/>
        </div>}
         {query && <span>{query}</span>} */}


                    <div className='top-btn-area'>
                        <div>
                        <Link to='./add-item' className="new-configuration-btn add-new"> Add Service Item </Link></div>
                        {!loading && items.map((item, index) => (
                            <CardNavigationForItemComponent item={item} index={index} edit={handleEdit}  configurationUrl={`/service-items/${item.textId}/configuration#root`}  viewUrl={`/service-items/${item.textId}#root`} editUrl={`/service-items/edit-item/${item.textId}#root`} root={'items'}/>
                        ))
                        }
                    </div>

                     

                    {/*{editId && <ServiceItemAddForm data={editId} close={handleClose} />}*/}


                    {loading && <LoadingComponent message={''}/>}
                    {/*{success && <SuccessComponent close={handleSuccess}/>}*/}
                    {error && <ErrorComponent close={handleSuccess} error={error}/>}
                </>
            }></Layout>
        </>
    )
}

export default ServiceItemList;