import DataTable, { createTheme } from "react-data-table-component";
// import CustomModelComponent from "../component/DataNotAvailableComponent";
// import "../assets/css/table.css";

const ProviderDataTable = ({ columns, data }) => {
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  // console.log(data)
  // console.log(columns)

  createTheme(
    "tableTheme",
    {
      text: {
        primary: "#666",
        secondary: "#000",
      },
      background: {
        default: "#fff",
      },
      context: {
        background: "#f00",
        text: "#000",
      },
      divider: {
        default: "#D9D9D9",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );
    const customStyles = {
        div: {
            style: {
                background: '#f00 !important',
            },
        },
    };

    const handleSort = (column, sortDirection) => {
        // Implement your sorting logic here and update the 'data' accordingly
    };

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      // selectableRowsNoSelectAll={true}
      paginationPerPage={5} // Number of data items per page
      // paginationComponentOptions={paginationOptions}
      theme="tableTheme"

      onSort={handleSort}
    />
  );
};

export default ProviderDataTable;
