import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    approvedTeamMembers: [],
    error: ''
}

const url = `${BASE_URL}/worker`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchApprovedTeamMembers = createAsyncThunk(
    'fetch/approvedTeamMembers', async () => {
        return axios
        .get(`${url}/approved/`, config)
        .then((response) => response.data)
    }
)


const ApprovedTeamMembersSlice = createSlice({
    name: "approvedTeamMembers",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(fetchApprovedTeamMembers.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchApprovedTeamMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.approvedTeamMembers = action.payload;
        state.error = "";
      });
      builder.addCase(fetchApprovedTeamMembers.rejected, (state, action) => {
        state.loading = false;
        state.approvedTeamMembers = [];
        state.error = action.error;
      });
    },
  });


  export default ApprovedTeamMembersSlice.reducer