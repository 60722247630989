import React from "react";
import logo from "../assets/images/logo.png";
import category from "../assets/images/category.png";
import {useDispatch, useSelector} from "react-redux";
import { Link, NavLink } from "react-router-dom"; 
// import SideBarData from "../data/leftSideBar";
// import zone from "../data/zone";
import SideBarData from "../data/leftSideBar";
import { ArrowRight } from 'react-bootstrap-icons';
function CommonLeftSideBar() {
    const { navData } = useSelector((state) => state.navData);
    // console.log(navData);
  return (
    <>
      <div className="left-side-bar">
        {/* <div className="logo"><Link to="/dashboard" className="item">
          <img src={logo} alt="My logo" /> </Link >
        </div> */}
        <ul className="level-1">
          {navData && navData.map((item) => (
            <li key={item.rank} id={"nav" + item.rank}>
              <NavLink to={item.link} className="item">
                  <ArrowRight />

                {/*<span className="icon">*/}
                {/*   <img src={category} alt="icon" /> */}
                {/*</span>*/}
                <span className="item-name">{item.name}</span>
              </NavLink >
              <ol className="level-2">
                {item.child.map((ch) => (
                    // console.log('each:',ch.name)
                  <li key={ch.rank} id={"nav" + ch.rank}>
                    <NavLink  to={ch.link} className="item">
                      <span className="item-name">{ch.name}</span>

                    </NavLink>
                  </li>
                ))}
              </ol>
            </li>
          ))}
        </ul>

      </div>
    </>
  );
}

export default CommonLeftSideBar;
