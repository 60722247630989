import { Form, Modal, Button } from "react-bootstrap";

const FieldsetBuilderForm = ({ fieldsetBuilderData, handleClose, show, handleSubmit }) => {
 
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
        className="fieldset-form-modal"
      >
      
        <Modal.Header closeButton>
          <Modal.Title> Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
         {fieldsetBuilderData.id != null ? (
              <Form.Control
                type="hidden"
                name="id"
                defaultValue={fieldsetBuilderData.id}
              ></Form.Control>
            ) : (
              <></>
            )}
            <Form.Label htmlFor="fieldId">Field Id</Form.Label>
            <Form.Control
              type="text"
              name="fieldId"
              placeholder="Field Id"
              defaultValue={fieldsetBuilderData.fieldId}
            ></Form.Control>

            <Form.Label htmlFor="label">Field Label</Form.Label>
            <Form.Control
              type="text"
              name="label"
              placeholder="Field Label"
              defaultValue={fieldsetBuilderData.label}
            ></Form.Control>

            <Form.Label htmlFor="areaTextId">Field Type</Form.Label>
            <Form.Select>
              <option>select one</option>
              <option> select two</option>
            </Form.Select>

            <Form.Label htmlFor="priceVariationJson">Data Type</Form.Label>
            <Form.Select>
              <option>select one</option>
              <option> select two</option>
            </Form.Select>

            <Form.Label htmlFor="delaultData">Delault Data</Form.Label>
            <Form.Control
              type="text"
              name="delaultData"
              placeholder="Delault Data"
              defaultValue={fieldsetBuilderData.delaultData}
            ></Form.Control>

            <Form.Label htmlFor="delaultData">Placeholder</Form.Label>
            <Form.Control
              type="text"
              name="placeholder"
              placeholder="placeholder"
              defaultValue={fieldsetBuilderData.placeholder}
            ></Form.Control>

            <Button type="submit" className="mt-2">
              Submit
            </Button>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FieldsetBuilderForm;
