import React from 'react';
import {useForm, useFieldArray} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux'
import {useState} from "react";
import {removeAttributeSubItemElement, updateSubItemElement, removeSubItemElement, removeAttributeOptionsubItem, updateSubItemElementOption} from '../../../reducers/FormBuilderSlice';
import UnGroupServiceComponent from "./UnGroupServiceComponent";
import ConfigurationPriceComponent from "./ConfigurationPriceComponent";
import PricingByComponent from './PricingByComponent';
import AttributeSizeComponent from './AttributeSizeComponent';
import RadioFieldComponent from './RadioFieldComponent';
import SelectFieldComponent from './SelectFieldComponent';
import ComboNumberFieldComponent from './ComboNumberFieldComponent';


const Controls = ({data, px, cx, reset}) => {
    const {subItemCurrentData} = useSelector((state) => state.formBuilderData)
    const [addSize, setAddSize] = useState('');
    const [show, setShow] = useState('');
    const [label, setLabel] = useState('Label Title');
    const {register, handleSubmit, control, formState: {errors}} = useForm();


    const dispatch = useDispatch()

    const handleShow = (column, val) => {
        // dispatch(updateElement([index, column, val]))
    };

    const handleChange = (column, val) => {
        // console.log('from handleChange: ',column,val)
        dispatch(updateSubItemElement([px, column, val, cx]))
    };
    const handleChangeOption = (current, value) => {
        console.log('from handleChange: ',current, value)
        // dispatch(updateSubItemElementOption({lavel:px, attr:cx, option:current,value:value}))
    };
    const handleOptionRemove = (current) => {
        // console.log('  handle Option Remove: ', column, val, current)
        dispatch(removeAttributeOptionsubItem([px, cx, current]))
    };
    // console.log(px, cx)
    const handleRemove = (px, cx) => {

        if(subItemCurrentData[px].controls.length>1){
            dispatch(removeAttributeSubItemElement([px, cx]))
            
            // alert('if', px, cx)
        }
        else {
            dispatch(removeSubItemElement(px))
            //  alert('if', px, cx)
        }
        // {res && subItemCurrentData[item].controls.splice(subItem, 1)}
    };

    // console.log(data)
    // if(data.btnName === 'textField')

//optional-area-size
    return (
        <>
         <div className={`form-group ${data.measurement ? 'optional-area-size':''}`}>
            <span className='remove edit-delete-btn' onClick={() => handleRemove(px, cx)}></span>



            {data.btnName === 'textField' && <>
                <label>{data.label}</label>
                <input name={data.label} className={`form-control optional-size`} defaultValue={data.options}
                    type={data.labelType} onChange={(e) => handleChange('options', e.target.value)}/>
            </>}

            {data.btnName === 'selectField' && show !== true && <>
                {/*  <div className='form-group'>
                     <span className='remove edit-delete-btn' onClick={() => handleRemove(px, cx)}></span> */}
                    {/* <div className={`${isBundleItem==='N' && data.isPrice==='Y'&&"is-unit-select"}`}>  */}
                    <label>{data.label}</label>
                    {/* <select className={`form-select`} name={data.label} defaultValue={data.value}
                            onChange={(e) => handleChange('options', [e.target.value])}>
                        {data.options.map((option, r) => (
                            <option value={option.title}> {option.title}</option>
                        ))}
                    </select> */}
                    {data &&<SelectFieldComponent data={data} handleChangeOption={handleChangeOption} handleOptionRemove={handleOptionRemove} reset={reset}/>}
                      {/* {data.isPrice==='Y'?<PricingByComponent data={data} index={px} handleChange={handleChange}/>:handleChange('pricingBy', null)} */}
                    {/* </div> */}
                    {/*   {data.optionalSize&&<AttributeSizeComponent data={data} index={px} handleChange={handleChange}/>} */}
                    {/* <UnGroupServiceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/> */}
                {/*      <ConfigurationPriceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/>
                 </div> */}
            </>
            }

            {data.btnName === 'checkboxField' && show !== true && <>
                {/* <div className='form-group'>
                    <span className='remove edit-delete-btn' onClick={() => handleRemove(px, cx)}></span> */}
                    {/* <div className={`${isBundleItem==='N' && data.isPrice==='Y'&&"is-unit-select"}`}>  */}
                        <label>{data.label} </label>
                        {typeof(data.options) !=='string' && data.options.map((option, r) => (
                            <div className='checked-field'>
                                <span className='remove-option'  onClick={()=>{handleOptionRemove(r)}}><i className="fa fa-times" aria-hidden="true"></i></span>
                                <input type={data.labelType} id={`${data.label}_${r}`} name={data.label} value={option.title} checked={option.isActive}
                                      
                                       onChange={(e) =>(e.target.checked)? handleChangeOption(r, true):handleChangeOption(r, null)}
                                    />
                                <label className='span' htmlFor={`${data.label}_${r}`}>{option.title}</label>
                            </div>
                        ))}
                        {/* {data.isPrice==='Y'?<PricingByComponent data={data} index={px} handleChange={handleChange}/>:handleChange('pricingBy', null)} */}
                      {/*</div> */}
                     {/* {data.optionalSize&&<AttributeSizeComponent data={data} index={px} handleChange={handleChange}/> } */}
                    {/* <UnGroupServiceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/>*/}
                     {/* <ConfigurationPriceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/> 
                </div> */}
                </>
            }

            {data.btnName === 'radioField' && show !== true && <> 
                {/* <div className='form-group'>
                    <span className='remove edit-delete-btn' onClick={() => handleRemove(px, cx)}></span> */}
                    {/* <div className={`${isBundleItem==='N' && data.isPrice==='Y'&&"is-unit-select"}`}>  */}
                        <label>{data.label} </label>
                        <RadioFieldComponent data={data} handleChangeOption={handleChangeOption} handleOptionRemove={handleOptionRemove} reset={reset}/>
                         {/* {data.isPrice==='Y'?<PricingByComponent data={data} index={px} handleChange={handleChange}/>:handleChange('pricingBy', null)} */}
                     {/*</div> */}
                     {/* {data.optionalSize&&<AttributeSizeComponent data={data} index={px} handleChange={handleChange}/>} */}
                    {/*<UnGroupServiceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/>*/}
                    {/* <ConfigurationPriceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/>
                </div> */}
                </>
            }


            {show !== true && data.btnName === 'comboNumberField' && <> 
                {/* <div className='form-group'>
                    <span className='remove edit-delete-btn' onClick={() => handleRemove(px, cx)}></span> */}
                  
                    {/* <div className={`${isBundleItem==='N' && data.isPrice==='Y'&&"is-unit-select"}`}>  */}
                        <label>{data.label}  </label>
                        {/* <select className={"form-control"} name={data.label} defaultValue={data.value}
                                onChange={(e) => handleChange('limit', e.target.value)}>
                            {Array.from({length: parseInt(data.limit) + 1}, (_, index) => (
                                <option key={index}
                                        value={data.value != null ? (data.value + (index )) : (index )}>{data.value} {index}</option>
                            ))}
                        </select> */}
                        <ComboNumberFieldComponent data={data}   handleChange={handleChange} reset={reset}/>
                        {/* {data.isPrice==='Y'?<PricingByComponent data={data} index={px} handleChange={handleChange}/>:handleChange('pricingBy', null)} */}
                     {/* </div> */}
                     {/* {data.optionalSize&&<AttributeSizeComponent data={data} index={px} handleChange={handleChange}/>} */}
                    {/*<UnGroupServiceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/>*/}
                    {/* <ConfigurationPriceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/>
                </div> */}
                </>
            }
            {/* value={`${option}----`} */}

            {data.btnName === 'textareaField' && show !== true && <> 
                {/* <div className='form-group'>
                    <span className='remove edit-delete-btn' onClick={() => handleRemove(px, cx)}></span> */}
                    {/* <div className={`${isBundleItem==='N' && data.isPrice==='Y'&&"is-unit-select"}`}>  */}
                        <label>{data.label} </label>
                        <textarea className={"form-control"} defaultValue={data.options} rows="4" cols="50" name={data.label}
                                onChange={(e) => handleChange('options', e.target.value)}></textarea>
                                 {/* {data.isPrice==='Y'?<PricingByComponent data={data} index={px} handleChange={handleChange}/>:handleChange('pricingBy', null)} */}
                     {/*</div> */}
                     {/* {data.optionalSize&&<AttributeSizeComponent data={data} index={px} handleChange={handleChange}/>} */}
                    {/*<UnGroupServiceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/>*/}
                    {/* <ConfigurationPriceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/>
                </div> */}
                </>
            }
            {/* <div className=''>nur</div> */}

            {data.measurement && <select className="measurement-form form-select optional-size-option">
                {data.measurement[0].option.map((item)=>( <option key={item} value={item}>{item}</option>))}
            </select>}

            {data.optionalSize&&<AttributeSizeComponent data={data} index={px} handleChange={handleChange}/>}
            {data.btnName !== 'textField' && <ConfigurationPriceComponent data={data} index={[px, cx]} callFrom='subConfiguration'/>}
            </div>
        </>
    );
}

export default Controls;
