import {Form, Modal, Button, Image} from "react-bootstrap";
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
 
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
// import {CKEditor} from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import {categorySchema} from "../../schemas/category";
import {useFormik} from "formik";
// import {createCategory, successConfirmation, updateCategory} from '../../reducers/CategorySlice'
// import {fetchServices} from "../../reducers/ServiceItemSlice";
import { addNotificationTemplatePost} from "../../reducers/NotificationSlice";
// import LoadingComponent from "../../modal/LoadingComponent";
// import ErrorComponent from "../../modal/ErrorComponent";
// import SuccessComponent from "../../modal/SuccessComponent";
const userType=[
                {name:'ADMIN',vakue:'Admin'}, 
                {name:'ENDUSER',vakue:'End User'}, 
                {name:'PROVIDER',vakue:'Service Provider'}, ]

const AddNewTemplateForNotification = ({data, stageArr, close}) => {

    const [breadcrumb, setBreadcrumb] = useState(false);
    const [sender, setSender] = useState(false);
    const [recever, setRecever] = useState(false);
    const [itemAddStatus, setItemAddStatus] = useState(false);
 
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState('');

    const {item, category} = useParams();
    const navigate = useNavigate(); 
console.log(stageArr)

    const {values, errors, touched, handleBlur, handleChange, handleSubmit, setValues} = useFormik({
        initialValues: data,
        onSubmit: (values, action) => {

            const res = dispatch(addNotificationTemplatePost(values));
            res.then(results => {
               console.log('results', results)
               close();
            }).catch(error => {
                alert('error ---- false')
            });

        },
        //  validationSchema: categorySchema,

    });
    // console.log("🚀 values:", values)

     
    return (
        <>
        <div className="wrap-content"> 
            <Form onSubmit={handleSubmit} className="form-data">
                <div className="section-two-column  notification-form">

                     
                        
                            <div className="field-row"><label className="label">Title Template</label>
                             
                            <input name="titleTemplate" placeholder="Category Name" type="text" className="border w-100 border-slate-700 p-2 rounded-md" value={values.titleTemplate} onChange={handleChange} ></input>
                            </div>
                        
                        <div className="field-row">
                            <label> Notification To  </label>
                            <select className={`form-select`} name="notificationTo" defaultValue={values.notificationTo}
                             onChange={(e)=>{
                                setSender(e.target.value)
                                setValues({...values, notificationTo: e.target.value})
                                return handleChange;
                            }}>
                                <option value=''> select  </option>
                                <option value={`ADMIN`} disabled={recever==='ADMIN'?true:false}> Admin</option>
                                <option value={`ENDUSER`} disabled={recever==='ENDUSER'?true:false}> End User</option>
                                <option value={`PROVIDER`} disabled={recever==='PROVIDER'?true:false}> Service Provider</option>
                                  
                            </select> 
                        </div>

                        <div className="field-row">
                            <label> Notification From </label>
                            <select className={`form-select`} name="notificationFrom" defaultValue={values.notificationFrom}
                            onChange={(e)=>{
                                setRecever(e.target.value)
                                setValues({...values, notificationFrom: e.target.value})
                                return handleChange;
                            }}>
                                <option value=''> select  </option>
                                <option value={`ADMIN`} disabled={sender==='ADMIN'?true:false}> Admin</option>
                                <option value={`ENDUSER`} disabled={sender==='ENDUSER'?true:false}> End User</option>
                                <option value={`PROVIDER`} disabled={sender==='PROVIDER'?true:false}> Service Provider</option>
                                  
                            </select> 
                        </div>

                        <div className="field-row">
                            <label> Stagec Title   </label>
                            <select className={`form-select`} name="stageTitle" defaultValue={values.stageTitle} onChange={handleChange}>
                                <option value=''> select  </option>
                                {stageArr.map((item) => (
                                    <option value={item.textId}> {item.title}</option>))}

                            </select> 
                            
                        </div>


                        <div className="field-row"><label className="label">Category Description</label>
                            <textarea className="form-control description" name="messageTemplate"
                                      value={values.messageTemplate} onChange={handleChange} rows="4"
                                      cols="50"></textarea>
                            {/*<CKEditor editor={ClassicEditor} details={editorData} onChange={handleChangea}  />*/}

                            

                        </div>
            
                        <div className='field-row'> 
                        <Form.Check type="switch" id="status" label="Status" name="status" defaultChecked={values.status} value={values.status} onChange={handleChange}/>
                       
                        </div>
             


                    <div className="save-option">
                        {/* <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft  </button> */}
                        {/* <button className="save-btn">Save</button> */}
                        <button className="save-btn">{data.update===true?'Updated':'Save'}</button>
                        <button className="save-btn" onClick={()=>close()}>Close</button>
                    </div>

                </div>
            </Form>
        </div>
        </> 
    );
};

export default AddNewTemplateForNotification;
