import { Form, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState} from "react";
import { fetchCity} from "../../reducers/CitySlice";
// import { fetchArea } from "../../reducers/AreaSlice";
import { createCityZip, updateCityZip } from "../../reducers/CityZipSlice";
import { zipSchema } from "../../schemas/zip";
import { useFormik } from "formik";

const CityZipForm = ({ data, close, show }) => {

  const [error, setError] = useState(null);
  const feachCityData = useSelector((state) => state.city);
  // const feachAreaData = useSelector((state) => state.area);
  const dispatch = useDispatch();
 
const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
     initialValues: data,
     onSubmit: (values, action) => {
       if(data.update == true){
          console.log("🚀 ~ file: CityZipForm.jsx:21 ~ CityZipForm ~ data:", values)
          dispatch(updateCityZip(values));
       }else{
        console.log(values)
          dispatch(createCityZip(values));
       }
       close(true);
     },
     validationSchema: zipSchema,
     handleChange: (values, action) => {
      console.log(values)
     },
     handleBlur: (values, action) => {
      console.log('handleBlur')
     }
    
   });
//  console.log(data)
   
  useEffect(() => {
    dispatch(fetchCity());
    // dispatch(fetchArea());
  }, []);
      
  return (
    <>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={true}
        className="service-form-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Zip Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
             <Form.Control type="hidden" name="id" defaultValue={data.id}  value={values.id}></Form.Control>
             
            {/* <Form.Select aria-label="Default select example" className="form-control" name="areaTextId" value={values.areaTextId} onChange={handleChange} onBlur={handleBlur} >
              <option value=''>Select Area</option>
              {feachAreaData.areas.map(({textId,title},index) => <option value={textId} >{title}</option>)}</Form.Select>
            {errors.areaTextId && <p className="red">{errors.areaTextId}</p>} */}
           
            <Form.Select aria-label="Default select example" className="form-control" name="cityTextId" value={values.cityTextId} onChange={handleChange} onBlur={handleBlur}>
              <option value=''>Select City</option>
              {feachCityData.cities.map(({ textId, title }, index) => <option value={textId} >{title}</option>)}</Form.Select>
              {errors.cityTextId && <p className="red">{errors.cityTextId}</p>}

               <Form.Control type="number" name="rank" placeholder="Number" value={values.rank} onChange={handleChange} onBlur={handleBlur}></Form.Control>
            
           
            <Form.Control type="text" name="zip" placeholder="Zip Code" value={values.zip} onChange={handleChange} onBlur={handleBlur}></Form.Control>
            {errors.zip && <p className="red">{errors.zip}</p>}

            <Button type="submit" className="mt-2"> Submit </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CityZipForm;
