import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
 



// interface UsersState {
//   entities: []
//   loading: 'idle' | 'pending' | 'succeeded' | 'failed'
// }
 const initialState = {
    loading: false,
    data: [],
    error: '',
    success: false,
    res: false,
} 

const url = `${BASE_URL}/settings/attributeGroupManagement/`

const token = localStorage.getItem("token")
const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}

export const fetchAttributeGroup = createAsyncThunk(
    'fetch/attributeGroup', async () => {
        return axios
        .get(url, config)
        .then((response) => response.data)
    }
)
 

 export const createAttributeGroup = createAsyncThunk(
  "create/attributeGroup", async (formData) => {
    return axios
    .post(url, formData, config)
    .then((response)=> {
      console.log("🚀 ~ file: AttributeGroupSlice.js:43 ~ .then ~ response:", response)
      return response.data;
    }
      ) 
  }
)
 

export const updateAttributeGroup = createAsyncThunk(
  "update/attributeGroup", async (formData) => {
    return axios
    .put(`${url}${formData.textId}`, formData, config)
    .then((response) =>{ 
    response.data.localData = formData.localData;
    console.log("🚀 ~ file: AttributeGroupSlice.js:59 ~ .then ~ formData:", formData)
    return response.data; 
     } 
  )
  }
)

export const deleteAttributeGroup = createAsyncThunk(
  "delete/attributeGroup", async (formData) => {
    return axios
    .delete(`${url}${formData.textId}`, config)
    .then((response) =>{ 
    return formData; 
     })
  }
)

const attributeGroupSlice = createSlice({
    name: "attributeGroup",
    initialState,
    reducers: {
    updateState(state, action) {
      state.res = action.payload;
    },
    removeSuccessMessage(state, action) {
      state.success = action.payload;
    },
  },
    extraReducers: (builder) => {
      builder.addCase(fetchAttributeGroup.pending, (state) => {
        state.loading = true;
        state.success = false;
      });
      
      builder.addCase(fetchAttributeGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
         
      });

      builder.addCase(fetchAttributeGroup.rejected, (state, action) => {
        state.loading = false;
        // state.data = [];
        state.error = action.error;
        if(action.error.code === 'ERR_BAD_REQUEST'){  
        
          }
           
      });
       
      builder.addCase(createAttributeGroup.pending, (state, action) => {
        state.loading = true;
        state.success = false;
      }); 
       
      builder.addCase(createAttributeGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data.push(action.payload);
        state.error = "";
      }); 

      builder.addCase(createAttributeGroup.rejected, (state, action) => {
      state.loading = false;
      // state.data = [];
      state.error = action.error;
     
    });

      
    builder.addCase(updateAttributeGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.data[action.payload.localData] = action.payload;
      state.error = "";
      console.log(action)
    });
 
    builder.addCase(updateAttributeGroup.rejected, (state, action) => {
      state.loading = false;
      // state.data = [];
      state.error = action.error;
 
    });
    
    builder.addCase(deleteAttributeGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.data.splice(action.payload.localData, 1);
      state.error = "";
    });
        
    builder.addCase(deleteAttributeGroup.rejected, (state, action) => {
      state.loading = false;
      // state.data = [];
      state.error = action.error;
 
    });
    },
  });
  



export const { updateState, removeSuccessMessage } = attributeGroupSlice.actions;
export default attributeGroupSlice.reducer