
import React, {useEffect, useState} from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
 
// import { updateElement } from '../../reducers/FormBuilderSlice'; 
 

const AttributeSizeComponent = ({ data, index, handleChange }) =>{
    const [selected, setSelected] = useState(data.optionalSizeUnit?data.optionalSizeUnit:'sqft');
    const [optionalSize, setOptionalSize ] = useState(null);
    const [show, setshow] = useState(true);
    const handelShow=()=> {setshow(show? false: true);
        handleChange('optionalSize', null)
        handleChange('optionalSizeUnit',null)

    }
//    if(data.optionalSize==='undefined'){
//     handleChange('optionalSizeUnit',true)
//     handleChange('optionalSize', true)
//    }
console.log('ssssssssssss:', data)
 
  return (
    <>
        <div className='from-group optional-area-size'>
           {show&&<> <span className='toogle-icon remove' onClick={() => handelShow()}><i className="fa fa-times" aria-hidden="true"></i>
                {/* {show? <i className="fa fa-eye-slash" aria-hidden="true"></i>:<i className="fa fa-eye" aria-hidden="true"></i>} */}
                </span>
              
            <label>Size </label>
            <input name='optionalSize' className={`form-control optional-size`} defaultValue={data.optionalSize}
                type='number' onChange={(e) => {
                    handleChange('optionalSize', e.target.value)
                    setOptionalSize(e.target.value)
                    if(e.target.value>0){
                        handleChange('optionalSizeUnit', selected)
                    } else{
                        handleChange('optionalSizeUnit', true)
                         
                    }
                      }}/>
            <select className="form-select optional-size-option" defaultValue={selected}
                onChange={(e) => {handleChange('optionalSizeUnit', e.target.value);
                setSelected(e.target.value)}}>
                {/* <option key='fm' value=''>Sqft/Sqmt</option> */}
                <option key='sqft' value='sqft'>Sqft</option>
                <option key='sqmt' value='sqmt'>Sqmt</option>
            </select></>}
            
        </div>
 
    </>
  );
}

export default AttributeSizeComponent;
