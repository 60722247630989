
import React from 'react';
import {useForm, useFieldArray} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux'
import {useState} from "react"; 
  
// import AttributeSizeComponent from './AttributeSizeComponent';
// import RadioFieldComponent from './RadioFieldComponent';


const ViewControls = ({data, px, cx}) => {
    const {subItemCurrentData} = useSelector((state) => state.formBuilderData)
    const [addSize, setAddSize] = useState('');
    const [show, setShow] = useState('');
    const [label, setLabel] = useState('Label Title');
    const {register, handleSubmit, control, formState: {errors}} = useForm();


    const dispatch = useDispatch() 


    return (
        <>
        <div className='form-group'>
            <label>{data.label} </label>
            {data.btnName === 'textField' &&
             
                <input name={data.label} className={`form-control`} defaultValue={data.options}
                                type={data.labelType} />
                      
                }

            {data.btnName === 'selectField' && show !== true &&
                <select className={`form-select`} name={data.label} defaultValue={data.value}>
                    {data.options.map((option, r) => (
                        <option value={option.title}> {option.title}</option>
                    ))}
                </select> 
            }

            {data.btnName === 'checkboxField' && show !== true &&
                <>
                    {typeof(data.options) !=='string' && data.options.map((option, r) => (
                        <div className='checked-field'>
                             
                            <input type={data.labelType} id={`${data.label}_${r}`} name={data.label} value={option.title} checked={option.isActive} />
                            <label className='span' htmlFor={`${data.label}_${r}`}>{option.title}</label>
                        </div>
                    ))}
                </>
            }

            {data.btnName === 'radioField' && show !== true &&
                <>
                {typeof(data.options) !=='string' && data.options.map((option, r) => (
                <div className='radio-field'>
                    <input type={data.labelType} id={`${data.label}_${r}`} name={data.label} value={option.title} />
                    <label className='span' htmlFor={`${data.label}_${r}`}>{option.title}</label>
                 
                </div>
    
))}
                </>
            }


            {show !== true && data.btnName === 'comboNumberField' &&
               
                <select className={"form-control"} name={data.label} defaultValue={data.limit} >
                    {Array.from({length: parseInt(data.limit) + 1}, (_, index) => (
                        <option key={index}
                                value={data.value != null ? (data.value + (index )) : (index )}>{data.value} {index}</option>
                    ))}
                </select> 
               
            }
           

                {data.btnName === 'textareaField' && show !== true &&
                <textarea className={"form-control"} defaultValue={data.options} rows="4" cols="50" name={data.label}></textarea> }
            </div>
        </>
    );
}

export default ViewControls;
