 import React from 'react';
 import {useState, useEffect} from "react";

const ComboNumberFieldComponent =  ({data, handleChange, reset}) => {
    const [active, setActive] = useState(data.limit);
    // const [options, setOptions] = useState([]);
    
 useEffect(() => {
        setActive(data.limit)
  }, [reset]);
 

 return (
<>

<select
      className="form-select"
      name={data.label}
      defaultValue={active} // Use controlled component approach
      onChange={(e) => {
        handleChange('limit', e.target.value);
        setActive(e.target.value);
      }}>

      {Array.from({length: parseInt(data.limit) + 1}, (_, index) => (
            <option key={index} value={index}>{data.options} {index}</option>
    ))}
    </select>
    
</>
 )
}

export default ComboNumberFieldComponent;