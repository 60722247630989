import { useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import { fetchApprovedTeamMembers } from '../../reducers/ApprovedTeamMemberSlice'
import { Form } from 'react-bootstrap'
import { changeTeamMemberStatus } from '../../reducers/PendingTeamMemberSlice'
import Layout from '../../components/Layout'

const ApprovedTeamMemberList = () => {
    
    const approvedTeamMembers = useSelector((state) => state.approvedTeamMembers)
    const dispatch = useDispatch()

    const columns = [
        {name: "ID", sortable: true, cell: (row) => row.id},
        {name: "TextId", sortable: true, cell: (row) => row.textId},
        {name: "FranchiseTextId", sortable: true, cell: (row) => row.franchiseTextId},
        {name: "FirstName", sortable: true, cell: (row) => row.firstName},
        {name: "LastName", sortable: true, cell: (row) => row.lastName},
        {name: "Email", sortable: true, cell: (row) => row.email},
        {name: "Phone", sortable: true, cell: (row) => row.phone},
        {name: "Status", sortable: true, cell: (row) => <>
        <Form.Select aria-label={`${row.status}`} onChange={(e) => {
            const formData ={
                textId: row.textId,
                status: e.target.value
            }
            const approval = window.confirm("Are you sure you want to change the status?")
            if(approval){
                dispatch(changeTeamMemberStatus(formData))
               
            }
        }}>
            <option selected disabled>{row.status}</option>
            <option value='Pending'>Pending</option>
            <option value='Pre Approved'>Pre Approved</option>
            <option value="Approved">Approved</option>
        </Form.Select>
        </>},
       
    ]

    useEffect(() => {
        dispatch(fetchApprovedTeamMembers())
    }, [dispatch])
    

  return (
    <Layout component={
        <>
    {approvedTeamMembers.loading && <div>Loading ...</div>}
    {!approvedTeamMembers.PendingTeamMemberList && approvedTeamMembers.error ? <div>Error: {approvedTeamMembers.error}</div> : null}
    {!approvedTeamMembers.loading ? 
       <CustomDataTable data={approvedTeamMembers.approvedTeamMembers} columns={columns}/>
  : null }
  </>
    }></Layout>
  )
}

export default ApprovedTeamMemberList