import React, {useEffect, useRef, useState} from 'react'; 
import {useLocation, useParams} from "react-router-dom";
import { fetchProviderOrders } from '../../reducers/TeamMemberSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import {Form, Modal, Button, Image} from "react-bootstrap";
import Layout from '../../components/Layout'
import OrderListComponent from "../../component/order/OrderListComponent";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
  
import {getUniqueData} from "../../utility/FilterDataTable";
const ProviderOrders = ({order}) => {
        // const {order} = useSelector((state) => state.teamMembers)
    const {item} = useParams()
    const location = useLocation()
    const dispatch = useDispatch()
    const [isRequest, setIsRequest] = useState('');
    const [filterBy, setFilterBy] = useState('');
    const [filterByProdider, setFilterByProdider] = useState('');
    const [activeCalender, setActiveCalender] = React.useState(false);

    const [query, setQuery] = useState('');
    const [startDate, setStartDate] = useState(false);
    const [endDate, setEndDate] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState(false);
    const today = new Date()
    const year = today.getFullYear();
    const month = today.getMonth();  

    const day = String(today.getDate()).padStart(2, '0'); 
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNames[month];

    const handleSelect=(date) =>{
        setStartDate(date.selection.startDate)
        setEndDate(date.selection.endDate)
        // setSelectedDate(`${startDate}-${endDate}`)
        setSelectedDate(`${moment(new Date(date.selection.startDate)).format("DD MMM YYYY")} - ${moment(new Date(date.selection.endDate)).format("DD MMM YYYY")}`)
        // setActiveCalender(false)
    }
    const selectionRange = {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection',
    }
     
    useEffect(() => {
        // dispatch(fetchProviderOrders('omarfaruk222375@gmail.com'));
         
    },[isRequest]);

    let filteredProvider = order.filter(item => item['userName']?.toLowerCase().includes(filterByProdider.toLowerCase()))
    let uniqueData = filteredProvider.filter(item => item['status']?.toLowerCase().includes(filterBy.toLowerCase()))
 
    console.log(uniqueData)
    // console.log('kkkkkkkkkkk', uniqueData)
 
    return (
     
                    <>
                    <div className='w-full h-[50px] relative'>
                    <div className='header-top-content top-position'>
                    <div className="search-header-area">
                        <div
                         className="w-[350px] ">
                            <div className="w-full px-[15px] py-[10px] m-[10px] bg-white rounded-[6px] border border-gray-200 justify-between items-center inline-flex cursor-pointer" onClick={()=>setActiveCalender(true)}>
                                <div className="">{selectedDate || activeCalender?selectedDate:` ${day} ${monthName} ${year}`} </div>
                                <div className="w-4 h-4 ml-[1px] relative"><i className="fa fa-calendar" aria-hidden="true"></i></div>
                            </div>
                         {activeCalender &&
                        // <Calendar className='absolute top-[40px] z-2 border-1'
                        //           ranges={[selectionRange]}
                        //     onChange={handleSelect}
                        // />
                        <div className='relative date-wrapper'>
                            <div className='offset-layer ' onClick={()=> setActiveCalender(false)}></div>
                        <DateRangePicker  
                                         ranges={[selectionRange]}
                                         onChange={handleSelect}/>
                        </div>
                    }
                    </div>
                     <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                                 value={filterByProdider} onChange={(e) => {
                        setFilterByProdider(e.target.value);
                    }}>
                        <option value=''>Filter By User</option>
                        {Object.values(getUniqueData('userName', order)).map(key => (<>{key &&
                            <option key={key} value={key}>{key}</option>}</>))}

                    </Form.Select>
                     {/* <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                                 value={filterByProdider} onChange={(e) => {
                        setFilterByProdider(e.target.value);
                    }}>
                        <option value=''>Filter By Provider</option>
                        {Object.values(getUniqueData('workerName', order)).map(key => (<>{key &&
                            <option key={key} value={key}>{key}</option>}</>))}

                    </Form.Select> */}
                    <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                                 value={query} onChange={(e) => {
                        setFilterBy(e.target.value); 
                    }}>
                        <option value=''>Filter By status</option>
                        {Object.values(getUniqueData('status', order)).map(key => (<>{key &&
                            <option key={key} value={key}>{key}</option>}</>))} 
                    </Form.Select>
                    </div>
                </div>
                </div>

                    <OrderListComponent data={uniqueData} />
                    </>
               
    );
}

 

export default ProviderOrders;