import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../BaseUrl";

const initialState = {
    loading: false,
    dashboardData: [],
    this_month_order: false,
    last_month_order: false,
    cardList: false,
    provider_list: false,
    success: false,
    error: '',
}

const url = `${BASE_URL}/dashboard`;
const token = localStorage.getItem("token")
const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchDashboard = createAsyncThunk(
    'fetch/Dashboard', async () => {
        return axios
            .get(url, config)
            .then((response) => response.data)
    }
)

const dashboardSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        successConfirmation(state, action) {
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDashboard.pending, (state) => {
            state.dashboardData = [];
            state.this_month_order = false;
            state.last_month_order = false;
            state.cardList = false;
            state.provider_list = false;
            state.success = false;
            state.loading = true;
        });

        builder.addCase(fetchDashboard.fulfilled, (state, action) => {
            state.loading = false;
            state.dashboardData = action.payload;
            state.cardList = action.payload.cardList;
            state.this_month_order = action.payload.this_month_order;
            state.last_month_order = action.payload.last_month_order;
            state.provider_list = action.payload.provider_list;
            state.error = "";
        });

        builder.addCase(fetchDashboard.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});


export const { successConfirmation } = dashboardSlice.actions;
export default dashboardSlice.reducer