import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { authRequest } from '../reducers/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { saveFCMTokenPost } from '../reducers/AuthSlice';
import {saveFCMToken} from '../utility/FcmTokenSaveVsRemove'; 
import LoadingComponent from "../modal/LoadingComponent";

 
  import { ToastContainer, toast } from 'react-toastify';
  // import 'react-toastify/dist/ReactToastify.css';
  import '../assets/tostify/ReactToastify.css';

const Login = () => {
  const navigate = useNavigate()
  const {loading} = useSelector((state) => state.auth);
  const [remember, setRemember] = useState('dd');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const dispatch = useDispatch();
  const notify = () => toast("Wow so easy!"); 
 
       
  // const handleRemember = (e) => setRemember(e.target.checked===true?true:false);
  const handleRemember = (e) => {setRemember(e.target.checked===true?true:false)}
  // console.log('remember remember:',remember);
  const handleOnBlur = (e) => setEmail(e.target.value);

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)
    // console.log(data)


    if(data.email!=='' && data.password!==''){
   
    let res = dispatch(authRequest(data));
    res.then((results)=>{ 
      if(results.error){
        // console.log('error', results.error)
         toast.error("something went wrong, please try again!", results.error); 
      }else{
        if(remember){
           localStorage.setItem(data.email, data.password)
        }else {
          localStorage.removeItem(data.email)
        }
        toast.success("Login success", results); 
        const res2= dispatch(saveFCMTokenPost(saveFCMToken()));
        res2.then(()=>{
          localStorage.removeItem("fcmToken");
          })
        setTimeout(()=> window.location.replace('/dashboard'),2000);

        // alert('login Done!!!!')
      }
    })
    res.catch((error) => {
      toast.error("Login Error", error); 
      console.error('Promise rejected:', error);
    });
  }


    // if(res){
    //     console.log('login')
    //     // window.location.replace('/');
    // }
  }
   useEffect(() => {
      localStorage.removeItem("textId")
      localStorage.removeItem("token") 
      localStorage.removeItem("expireDate") 
  }, []);
   useEffect(() => {
     const local = localStorage.getItem(email);
     setPass(local)
     setRemember(true)
  }, [email]);
  return (
    <div className="d-flex align-items-center justify-content-center" style={{height: '100vh'}}>

     
     
        {loading && <LoadingComponent message={''}/>}
        <Form onSubmit={handleSubmit} className='login-form'>
            <h3>Admin Login</h3>
              {/* <button onClick={notify}>Notify! {email} || {pass}|| {remember===true&&'tttttttt'}</button> */}
             <ToastContainer className="ToastContainer"/>
            <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control type="text" name='email' placeholder="Email Address" onBlur={(e)=>handleOnBlur(e)}/>
            {/*<Form.Text className="text-muted"> We'll never share your email with anyone else. </Form.Text>*/}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name='password' placeholder="Password" value={pass}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox" >
            <Form.Check type="checkbox" label="Remember Password"  checked={remember} onClick={(e)=>handleRemember(e)}/>
            </Form.Group>
            <Button variant="primary" type="submit">Login</Button>

             {/*<span>date:02 Sepetember at 12.45 PM</span>*/}
        </Form>
    </div>
      );
    }

export default Login;