

import React, { useState } from 'react';

function SubItemsList({ data, values, hadelQuickView, handleSub_items }) {
  const [selectedSubItem, setSelectedSubItem] = useState(values);

  const handleCheckboxChange = (item) => {

      handleSub_items(item.target.value, item.target.checked)
//    if (item.target.checked) {
//   // console.log(item.target.value);
//   setSelectedSubItem([...selectedSubItem, item.target.value]);

//        handleSub_items([...selectedSubItem, item.target.value])
// } else {
//   setSelectedSubItem(selectedSubItem.filter(val => val !== item.target.value));
//   handleSub_items(selectedSubItem.filter(val => val !== item.target.value))
// }
  };

//   const handleViewClick = (item) => {
//     // Handle view logic here, e.g., open a modal or navigate to another page
//   };
  // console.log('--------------',values)
  return (
    <ol>
      {data.map((item, index) => (
        <li key={item.textId}>
            <div className="form-row">
                <input name='sub_items' type="checkbox" id={`sub_item_${item.textId}`} value={item.textId} onChange={(e) => {
                  handleCheckboxChange(e);
                  // handleSub_items()
                  }} />
                {/* <input type="checkbox" id={`index${index}`} value={item.textId} checked={selectedSubItem === item.textId} onChange={(e) => {setSelectedSubItem(e.target.value);handleCheckboxChange(e)}} /> */}
                <label htmlFor={`sub_item_${item.textId}`}>{item.title}</label>
                <div className="view" onClick={() => hadelQuickView(item)}>
                    <i className="fa fa-eye" aria-hidden="true"></i> View
                </div>
          </div>
          {item.subItems && <SubItemsList data={item.subItems} values={values} hadelQuickView={hadelQuickView} handleSub_items={handleSub_items}/>}
        </li>
      ))}
    </ol>
  );
}

export default SubItemsList;