 export function getStatusName(name) {
    if (name === 'Y') {
        return "Active";
    } else if (name === 'N') {
        return "In Active";
    } else if (name === 'D') {
        return "Draft";
    }  else if (name === 'P') {
        return "Pause";
    }
    else {
        return '...';
    }
}

 export function getZipStatus(name) {
    if (name === 'Yes') {
        return "[Active]";
    } else if (name === 'No') {
        return "[Remain Area]";
    } else if (name === 'N') {
        return "[Draft]";
    } else {
        return '...';
    }
}