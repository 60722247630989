import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import { Button } from 'react-bootstrap'
import Layout from '../../components/Layout'
import { createRole, updateRole, deleteRole, fetchRoles } from '../../reducers/AdminRole'
import RoleForm from './RoleForm'

const Role = () => {
  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const role = useSelector((state) => state.adminRole)
    const dispatch = useDispatch()

    const [ roleData, setRoleData ] = useState({
        update: false,
        id: null,
        textId: null,
        title: null
      })

    const handleSubmit = (event) => {
        event.preventDefault();
    
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData)
    
        dispatch(createRole(data))
        window.location.reload()
      }
  
      const handleUpdate = (event) => {
        event.preventDefault();
    
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData)
        dispatch(updateRole(data))
        window.location.reload()
      }
  
    useEffect(() => {
        dispatch(fetchRoles())
    }, [dispatch])

    const columns = [
        {name: "ID", sortable: true, cell: (row) => row.id},
        {name: "TextId", sortable: true, cell: (row) => row.textId},
        {name: "Title", sortable: true, cell: (row) => row.title},
        {name: "Update", cell: (row) => <Button onClick={() => {
          setRoleData((roleData) => ({
            ...roleData,
            update: true,
            id: row.id,
            textId: row.textId,
            title: row.title,
          }))
          setShow(true)
        }}>Update</Button>},
        {name: "Delete", cell: (row) => <Button variant="danger" onClick={() => {
          const approval = window.confirm("Are you sure you want to delete?")
          if (approval === true){
            dispatch(deleteRole(row.textId))
            window.location.reload()
          }
        }}>Delete</Button>},
    ]
  return (
    <Layout component={
      <>
        <Button variant="primary" onClick={handleShow}>
        Add Role
        </Button>

        <RoleForm roleData={roleData} handleClose={handleClose} handleSubmit={roleData.update ? handleUpdate : handleSubmit} show={show}/>
    
        {role.loading && <div>Loading ...</div>}
        {!role.loading && role.error ? <div>Error: {role.error}</div> : null}
        {!role.loading ? 
           <CustomDataTable data={role.roles} columns={columns}/>
      : null }
    </>
    }></Layout>
  )
}

export default Role