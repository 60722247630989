import {Form, Modal, Button, Image} from "react-bootstrap";
// import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
 
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState, useRef} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {faqSchema} from "../../../schemas/faq";
import {useFormik} from "formik";
// import {createCategory, successConfirmation, updateCategory} from '../../reducers/CategorySlice'
// import {fetchServices} from "../../reducers/ServiceItemSlice";
import { addQuestion, updateQuestion } from "../../../reducers/FaqSlice";
// import LoadingComponent from "../../modal/LoadingComponent";
// import ErrorComponent from "../../modal/ErrorComponent";
// import SuccessComponent from "../../modal/SuccessComponent";

const appType=[
    {name:'Admin',value:'Admin'}, 
    {name:'EndUser',value:'EndUser'}, 
    {name:'Provider',value:'Provider'}, 
]
// const QuestionCategory=[
//     {name:'Ordering',value:'Order Related Question'}, 
//     {name:'PRICE',value:'Service Price  Question'}, 
//     {name:'Provider',value:'Provider'}, 
// ]

const AddQuestionComponent = ({data, QuestionCategory, close}) => {

    // const [sender, setSender] = useState(false);
    // const [recever, setRecever] = useState(false);
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState();
    const [exitError, setExitError] = useState();

    const editorRef = useRef(null);
    const [editorHeight, setEditorHeight] = useState(300);
    let isDragging = false;
    let startY, startHeight; 

  const handleResize = (event) => {
    if (isDragging) {
      const deltaY = event.clientY - startY;
      const newHeight = Math.max(startHeight + deltaY, 300); // Set a minimum height
      setEditorHeight(newHeight);
      editorRef.current.ui.view.element.style.height = `${newHeight}px`;
    }
  };
    const handleMouseDown = (event) => {
    if (event.clientY >= editorRef.current.ui.view.element.offsetTop + editorRef.current.ui.view.element.offsetHeight - 10) {
      isDragging = true;
      startY = event.clientY;
      startHeight = editorHeight;
    }
  };

    const handleMouseUp = () => {
    isDragging = false;
  };
      const handleChangea = (event, editor) => {
        const details = editor.getData();
        setEditorData(details);
        setValues({...values, answer: details})
    };

    const {values, errors, handleChange, handleSubmit, setValues} = useFormik({

        initialValues: data,

        onSubmit: (values, action) => {
            if(values.status===true){
                values=  {...values,status:'Y'}
                }else{
                   values=  {...values,status:'N'}
            }

            const res = dispatch(data.update===true?updateQuestion(values):addQuestion(values));
            res.then(results => {
                console.log(results)
               if(results.error) {setExitError('Something went wrong. Please try again!'); 

               }
               else {
                close(results.payload.results); 
               }
            }).catch(error => {
                alert('error ---- false')
            });

        },
         validationSchema: faqSchema,

    });
    // console.log("🚀 values:", values)

     
    return (
        <> <div className="wrap-content">
            <Form onSubmit={handleSubmit} className="form-data">
                <div className="section-two-column faq-form">
                  
                        <div className="w-full text-black text-xl font-medium font-['Inter']">Edit Question and Answer </div>
                        <hr className="border" />
                        
                        {exitError && <div className="alert alert-danger">
                              {exitError} 
                            </div>}

                       
                            <div className="field-row">
                                <label className="label">Question Title</label>
                             
                            <input name="question" placeholder="Question" type="text" className="border w-100 border-slate-700 p-2 rounded-md" value={values.question} onChange={handleChange} ></input>
                                 {errors.question && <p className="red">{errors.question}</p>} 
                            </div>
                       
                        
                            <div className="field-row"><label className="label">Answer</label>
                               {/* <textarea className="form-control description" name="answer" placeholder='Answer'
                                  value={values.answer}
                                  onChange={handleChange} rows="4" cols="50"></textarea> */}
                                  <textarea className="editorData" name="answer" defaultValue={values.answer} onChange={handleChange}></textarea>
                                   <CKEditor editor={ClassicEditor} details={editorData} data={values.answer} onChange={handleChangea} />
                                   {errors.answer && <p className="red">{errors.answer}</p>} 
                            </div>
                        
                        
                        <div className="field-row">
                            <label> Question  Category  </label>
                            <select className={`form-select`} name="faqCategory" defaultValue={values.faqCategory}
                             onChange={(e)=>{
                                // setSender(e.target.value)
                                setValues({...values, faqCategory: e.target.value})
                                return handleChange;
                            }}>
                                <option value=''> select  </option>
                                {QuestionCategory && QuestionCategory.map((item)=>(
                                    <option value={item}> {item}</option>
                                ))}
                                  
                            </select> 
                             {errors.faqCategory && <p className="red">{errors.faqCategory}</p>} 
                        </div>

                        <div className="field-row">
                            <label> FAQ Destination </label>
                            <select className={`form-select`} name="faqDestination" defaultValue={values.faqDestination}
                            onChange={(e)=>{
                                // setRecever(e.target.value)
                                setValues({...values, faqDestination: e.target.value})
                                return handleChange;
                            }}>
                                <option value=''> select </option>
                                {appType && appType.map((item)=>(
                                    <option value={item.name}> {item.value}</option>
                                ))}
                            </select> 
                            {errors.faqDestination && <p className="red">{errors.faqDestination}</p>} 
                        </div>

                        <div className='checked-field'>
                            {/* <label className="label">Status</label><br /> 
                            <input type="radio" id='state_y' name='status' value={`Y`}
                                   onChange={handleChange}/> <label htmlFor='state_y'>Yes</label> &nbsp; &nbsp; */}

                            {/* <input type="radio" id='state_n' name='status' value={`N`}
                                   onChange={handleChange}/> <label htmlFor='state_n'>No</label> */}

                            <Form.Check type="switch" id="status" label="FAQ Question Status" name="status" defaultChecked={values.status} value={values.status} onChange={handleChange}/>
                      
                       <br /> </div>

                      
                            <div className="field-row"><label className="label">Question Rank</label>
                             
                            <input name="rank" placeholder="number" type="number" className="border w-100 border-slate-700 p-2 rounded-md" value={values.rank} onChange={handleChange}></input>
                            </div>
                      
    

                       

                     


                    <div className="save-option">
                        {/* <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft  </button> */}
                        <button className="save-btn">{data.update===true?'Updated':'Save'}</button>
                        <button className="save-btn" onClick={()=>close()}>Close</button>
                    </div>

                </div>
            </Form>
        </div>
        </> 
    );
};

export default AddQuestionComponent;
