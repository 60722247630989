import React, {Component, useEffect, useState} from 'react';
import AreasListComponent from "./AreasListComponent";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import {changeTeamMemberStatus } from "../../reducers/TeamMemberSlice";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useParams, } from "react-router-dom";
import {BASE_URL} from "../../BaseUrl";
import CategoriesModal from "../../modal/CategoriesModal";
import CategoryCardComponent from "./CategoryCardComponent";
import ApproveWorkerModal from "../../modal/ApproveWorkerModal";

import WorkerApproveComponent from './WorkerApproveComponent';

const CategoryRequested = ({profile, activeTab}) => {
    const {category, zoneList, loading, error} = useSelector((state) => state.teamMembers)
    const dispatch = useDispatch()
    const {location} = useLocation()
    const {item} = useParams()
    const [model, setModel] = useState(false);
    const [data, setData] = useState(false);
    const handleClose = () => setModel(false)

    const formData = {
        textId: profile.textId,
        status: 'Verified',
        categoryList:category,
        zoneList:zoneList
    }
    // const formData = {
    //     ...zoneList, // Spread the existing object
    //     textId: profile.textId,
    //     status: 'Verified'
    // };

    const approveWorkerSubmit = ()=>{
        const res = dispatch(changeTeamMemberStatus(formData))
        if (res) {
            setModel(true)
        }
    }
    console.log('formData:', formData)
 


    return (
        <>
            <div className="requested-category bg-white rounded-12 mt-4">
                {/* about */}
                <div className="category">
                    <div className="mb-3 strong">
                        Categories Requested During the Registration Process
                    </div>
                    <div className="category-lists d-flex gap-3 flex-wrap mb-4 curser-pointer">
                        {!loading && category.length > 0 && category.map((item) => (
                            <CategoryCardComponent data={item}/>
                        ))}
                    </div>
                </div>
                <hr className="divider"/>
                {/* service areas */}
                <div className="service-areas mt-3">
                    <div className="mb-3 strong">Service Areas</div>
                    <div className="areas"> 
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            {!loading && zoneList.length > 0 && zoneList.map((item, index) => (
                                <AreasListComponent data={item} zoneIndex={index} zoneTextId={index} workerTextId={item.workerTextId}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        <WorkerApproveComponent profile={profile} />
        </>
    );

}

export default CategoryRequested;