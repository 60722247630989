import React, {useEffect, useState} from "react";
import CommonLeftSideBar from "./CommonLeftSideBar";
import CommonHeader from "./CommonHeader";
import {Container} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from 'react-router-dom';
import PageHeader from './PageHeader';
import Firebase from '../firebase/Firebase';
// import SideBarData from "../data/leftSideBar";
import {pageTitle} from "../reducers/LocalDataSlice";
import {appendNavBar, navItemAdd} from "../reducers/NavDataSlice";
import NavigationBar from "./NavigationBar";
import {ArrowRight} from "react-bootstrap-icons";
import BreadcrumbTextComponent from "./BreadcrumbTextComponent";
import FooterComponent from "./FooterComponent";
import {fetchNotificationBellCount} from "../reducers/NotificationSlice";

const Layout = ({component, page_title, top_content}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    // const { navData } = useSelector((state) => state.navData);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    // const {items} = useSelector((state) => state.category)
    const handleNav = () => setShow(show === false ? true : false);
    const {title, breadcrumb} = useSelector((state) => state.localData)
    const {count, unauthorized} = useSelector((state) => state.notification);
    // const expireDate = localStorage.getItem("expireDate");
    // const currentTime = new Date().getTime();

    // console.log('date----:', date)
// const extendedExpirationTime = new Date(expirationTime.getTime() + 3600000);

    useEffect(() => {
        if (page_title) {
            document.title = page_title;
        } else {
            // document.title = 'Help Abode Admin updated from project';
            // page_title = document.title;
        }
        const token = localStorage.getItem("token");
        // const expireDate = localStorage.getItem("expireDate");
        if (token === 'undefined' || token == null) {
            console.log("token not found!!");
            navigate('/login')
        }
 

        // if (expireDate < currentTime) { 
        //     navigate('/logout')
        // }
        dispatch(pageTitle(''));
        // dispatch(appendNavBar(SideBarData))
    }, [dispatch]);

        if(unauthorized===false){
            // alert('got it!!!!!!!!')
            window.location.assign('/logout');
        }

    useEffect(() => {
        const res = dispatch(fetchNotificationBellCount());
        res.then((results) => {   
            if(results.error){
            // console.log('results in layout::',results.error)
            }
            
        })
            res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [count]); 

    return (
        <>
            <header>
               
                <div className="header-wrapper">
                    <div className={show ? 'menu-icon selected' : 'menu-icon'} onClick={handleNav}>
                        <div className='centered'>
                            {show ? <>
                                    <div className="hr_bar"></div>
                                    <div className="hr_bar"></div>
                                    <div className="hr_bar"></div>
                                </> :
                                <img src='/images/menu.svg' alt=""/>}
                        </div>
                    </div>
                    <div className="logo"> Help Abode - Dashboard</div>
                    <CommonHeader/>
                </div>
            </header>
            <Container>
                {!show && <NavigationBar/>}
                <div className={!show ? 'wrapper selected' : 'wrapper'}>
                    <div className="header-section">
                        <span className="page-title">{page_title?page_title:document.title}</span>
                        <BreadcrumbTextComponent breadcrumb={breadcrumb}/>
                        {top_content && <div className="header-top-content">{top_content}</div>}
                    </div>
                    <div className="content-block">{component}</div>
                </div>
                <FooterComponent show={show}/>
                 <Firebase />
            </Container>

        </>
    );
};

export default Layout;
