import React from 'react';
import {Card, Button} from 'react-bootstrap';
import {BASE_URL} from "../../BaseUrl";


function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    };

    const formattedDate = date.toLocaleDateString('en-GB', options);
    return formattedDate;
}

const HeaderComponent = ({profile}) => {

    return (
        <>
            <div className="w-[100%] justify-start items-start gap-3 flex mb-5">
                <div
                    className="grow shrink basis-0 self-stretch p-4 bg-white rounded-xl border border-gray-200 flex-col justify-start items-start gap-3 inline-flex">
                    <div className="justify-center items-center gap-4 inline-flex">
                        {/*<img className="w-[120px] h-[120px] rounded-full border-4 border-white"*/}


                        <img
                            src={(profile.user_data.image ? `${BASE_URL}${profile.user_data.image}` : "/svg/account.svg")}
                            alt={profile.firstName} className="w-[120px] h-[120px] rounded-full border-4 border-white"/>

                        <div className="flex-col justify-center items-start gap-1 inline-flex">
                            <div
                                className="text-black text-lg font-semibold font-['Inter']">{profile.user_data.firstName} {profile.user_data.lastName}</div>
                            <div
                                className="text-stone-500 text-sm font-normal font-['Inter'] leading-tight"> {profile.user_data.email}
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch h-[0px] border border-zinc-300 opacity-[50%]"></div>
                    <div className="self-stretch justify-between items-center inline-flex">

                        {profile.user_summary.totalSpent &&
                            <div className="pr-4 flex-col justify-center items-start gap-1 inline-flex">
                                <div className="text-stone-500 text-sm font-normal font-['Inter'] leading-tight">
                                    Total Spent
                                </div>
                                <div
                                    className="text-black text-base font-normal font-['Inter']">${profile.user_summary.totalSpent}</div>
                            </div>}
                        {profile.user_summary.lastOrder &&
                            <div className="pr-4 flex-col justify-center items-start gap-1 inline-flex">
                                <div className="text-stone-500 text-sm font-normal font-['Inter'] leading-tight">
                                    Last Order
                                </div>
                                <div
                                    className="text-black text-base font-normal font-['Inter']">{profile.user_summary.lastOrder}</div>
                            </div>}

                        {/*<div className="pr-4 flex-col justify-center items-start gap-1 inline-flex">*/}
                        {/*    <div className="text-stone-500 text-sm font-normal font-['Inter'] leading-tight">Last*/}
                        {/*        Order*/}
                        {/*    </div>*/}
                        {/*    <div className="text-black text-base font-normal font-['Inter']">1 week ago</div>*/}
                        {/*</div>*/}

                        {profile.user_summary.totalOrders &&
                            <div className="flex-col justify-center items-start gap-1 inline-flex">
                                <div className="text-stone-500 text-sm font-normal font-['Inter'] leading-tight">Total
                                    Orders
                                </div>
                                <div
                                    className="text-black text-base font-normal font-['Inter']">{profile.user_summary.totalOrders}</div>
                            </div>}
                    </div>
                </div>
                <div
                    className="p-4 bg-white rounded-xl border border-gray-200 flex-col justify-start items-start gap-4 inline-flex">
                    <div className="self-stretch h-[35px] flex-col justify-start items-start gap-4 flex">
                        <div className="text-black text-base font-semibold font-['Inter']">Contact Info</div>
                        <div className="self-stretch h-[0px] border border-neutral-100 opacity-[25%]"></div>
                    </div>
                    <div className="flex-col justify-start items-start gap-2 flex">
                        <div className="text-black text-sm font-medium font-['Inter']">Contact info</div>
                        <div className="flex-col justify-start items-start gap-3 flex">
                            {profile.user_data.email && <div className="justify-start items-center gap-3 inline-flex">

                                <i className="w-4 h-4 relative fa fa-envelope-o" aria-hidden="true"></i>
                                <div
                                    className="text-neutral-600 text-sm font-normal font-['Inter']">
                                    {profile.user_data.email}
                                </div>
                            </div>}
                            {profile.user_data.phone && <div className="justify-start items-center gap-3 inline-flex">
                                <i className="w-4 h-4 relative fa fa-phone" aria-hidden="true"></i>
                                <div className="text-neutral-600 text-sm font-normal font-['Inter']">
                                    {profile.user_data.phone}
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="w-[267px] h-[0px] border border-neutral-100 opacity-[25%]"></div>
                    {profile && <div className="flex-col justify-start items-start gap-2 flex">
                        <div className="text-black text-sm font-medium font-['Inter']">Address</div>
                        <div className="justify-start items-start gap-2.5 inline-flex">
                            <div className="text-neutral-600 text-sm font-normal font-['Inter'] leading-snug">
                                {profile.address_data && (
                                    profile.address_data.map((item) => (
                                        <>
                                        {console.log(item)}
                                            {/* {item.textId === profile.user_data.endUserAddressTextId && (
                                                <div key={index} className='irem-row'>
                                                    {item.addressLine1 && <span>{item.addressLine1}</span>}
                                                    {item.addressLine2 && <span>, {item.addressLine2}</span>}
                                                    {item.city && <span>, {item.city}</span>}
                                                    {item.state && <span>, {item.state}</span>}
                                                    {item.countryIso2Code && <span>, {item.countryIso2Code}</span>}
                                                </div>
                                            )} */}
                                        </>
                                    ))
                                )}
                            </div>
                            {/*<img className="w-3.5 h-3.5 rounded-[75px]" src="https://via.placeholder.com/14x14"/>*/}
                        </div>
                    </div>}
                </div>
            </div>
        </>
    );
};

export default HeaderComponent;
