// ModalComponent.jsx
import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';

const CustomModelComponent = ({message, data}) => {
    return (
        <>
            <div className='not-data-section'>
                <strong className='message'>There are no records to display...</strong>
            </div>
        </>
    );
};

export default CustomModelComponent;
