import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
 
import { useDispatch, useSelector } from "react-redux";
 
import {fetchAllStageValue, fetchNotificationData, markAllRead} from "../../reducers/NotificationSlice";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import Layout from "../../components/Layout";
import NotificationModalSideBar from "../../component/notifications/NotificationModalSideBar";

const NotificationList = () => {
  const [show, setShow] = useState(false);
  const [zipShow, setZipShow] = useState(false);
 
  const [alertSuccess, setAlertSuccess] = useState(false);
  const handleShow = () => setShow(true);

  const manageZipClose = () => setZipShow(false);
  const manageZipShow = () => setZipShow(true);

  const {count} = useSelector((state) => state.notification);
  const dispatch = useDispatch(); 
// const count = 23;
  // console.log(feachData.notice)

  useEffect(() => {
    dispatch(fetchNotificationData());
    // dispatch(fetchAllStageValue());
     dispatch(breadcrumbTree({'Notification': ''}));
    
  }, []); 
  return (
    <Layout page_title={'Notifications Listing'}
      component={
        <div className="w-full bg-white pt-3 flex-col justify-start items-start gap-3 inline-flex">
          <div className="w-full h-7 px-5 justify-between items-center inline-flex">
    <div className="px-3 py-1  items-center gap-2 flex">
       {count>0 &&  <div className="text-center px-3 py-1  text-white text-sm font-semibold font-['Inter'] leading-tight cursor-pointer bg-emerald-700 rounded-3xl justify-start">{count} new notifications</div>}
    </div>
    <div className="justify-start items-center gap-2.5 flex">
        <div className="justify-start items-center gap-6 flex">
            <div className="text-right text-emerald-700 text-base font-medium font-['Inter'] cursor-pointer" onClick={()=>{
              const res=dispatch(markAllRead());
                    res.then(results => {
                       
                        alert('yes')
                        }).catch(error => {
                            alert('error ---- false')
                        });
                 
            }}>Mark all as Read</div>
            {/* <div className="text-right text-emerald-700 text-base font-medium font-['Inter']">Notification settings</div> */}
        </div>
    </div>
</div>
<div className="w-full h-[0px] border border-gray-200"></div>
          <NotificationModalSideBar/>
        </div>
      }
    ></Layout>
  );
};

export default NotificationList;
