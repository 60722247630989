import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import { Button } from 'react-bootstrap'
import Layout from '../../components/Layout'
import { createPermission, deletePermission, fetchPermissions, updatePermission } from '../../reducers/AdminPermission'
import PermissionForm from './PermissionForm'


const PermissionList = () => {
  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const permission = useSelector((state) => state.adminPermission)
    const dispatch = useDispatch()

    const [permissionData, setPermissionData] = useState({
        updateStatus: false,
        id: null,
        adminRoleTextId: null,
        adminModuleTextId: null,
        read: null,
        create: null,
        update: null,
        delete: null,
    })

    const handleSubmit = (event) => {
        event.preventDefault();
    
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData)
    
        dispatch(createPermission(data))
        window.location.reload()
      }
  
      const handleUpdate = (event) => {
        event.preventDefault();
    
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData)
        dispatch(updatePermission(data))
        window.location.reload()
      }
  
    useEffect(() => {
        dispatch(fetchPermissions())
    }, [dispatch])

    const columns = [
        {name: "ID", sortable: true, cell: (row) => row.id},
        {name: "AdminRoleTextId", sortable: true, cell: (row) => row.adminRoleTextId},
        {name: "AdminModuleTextId", sortable: true, cell: (row) => row.adminModuleTextId},
        {name: "Read", sortable: true, cell: (row) => (
          row.read ? "Yes" : "No"
        )},
        {name: "Create", sortable: true, cell: (row) => (
          row.create ? "Yes" : "No"
        )},
        {name: "Update", sortable: true, cell: (row) => (
          row.update ? "Yes" : "No"
        )},
        {name: "Delete", sortable: true, cell: (row) => (
          row.delete ? "Yes" : "No"
        )},
        {name: "Update", cell: (row) => <Button onClick={() => {
          setPermissionData((permissionData) => ({
            ...permissionData,
            updateStatus: true,
            id: row.id,
            adminRoleTextId: row.adminRoleTextId,
            adminModuleTextId: row.adminModuleTextId,
            read: row.read,
            create: row.create,
            update: row.update,
            delete: row.delete,
          }))
          setShow(true)
        }}>Update</Button>},
        {name: "Delete", cell: (row) => <Button variant="danger" onClick={() => {
          const approval = window.confirm("Are you sure you want to delete?")
          if (approval === true){
            dispatch(deletePermission(row.id))
            window.location.reload()
          }
        }}>Delete</Button>},
    ]

    return (
    <Layout component={
      <>
    <Button variant="primary" onClick={handleShow}>
        Add Permission
        </Button>

        <PermissionForm permissionData={permissionData} handleClose={handleClose} handleSubmit={permissionData.updateStatus ? handleUpdate : handleSubmit} show={show}/>
    
        {permission.loading && <div>Loading ...</div>}
        {!permission.loading && permission.error ? <div>Error: {permission.error}</div> : null}
        {!permission.loading ? 
           <CustomDataTable data={permission.permissions} columns={columns}/>
      : null }
    </>
    }></Layout>
  )
}

export default PermissionList