
import 'react-datepicker/dist/react-datepicker.css';
import React, {useEffect, useState} from "react";
import { useDispatch,useSelector } from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import {Link} from "react-router-dom";
import {fetchActiveServiceItem} from "../../reducers/CategorySlice";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import DatePicker from "react-datepicker";
import ExportFile from "../../component/utility-component/ExportFile";
import CustomDatePickerInput from '../../component/CustomDatePickerInput';
import {fetchCategoryRequest, changeCategoryRequestStatus} from "../../reducers/CategoryRequestSlice";
// import Button from 'react-bootstrap/Button';
import {filterDataTable3Col, filterDataTable4Col} from "../../utility/FilterDataTable";
import LoadingComponent from "../../modal/LoadingComponent";  

const CategoryRequestList = () => {
    const [startDate, setStartDate] = useState(new Date());
    const { allRequest, loading, error } = useSelector((state) => state.categoryRequest)
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [filterByStatus, setFilterByStatus] = useState('');
    const [query, setQuery] = useState('');
 
    const columns = [
        // {name: "ID", sortable: true, cell: (row) => row.id},
        // {name: "TextId", sortable: true, cell: (row) => row.textId},
        // {name: "FranchiseTextId", sortable: true, cell: (row) => row.franchiseTextId},
        {name: "Category Title", sortable: true, cell: (row) => row.categoryTitle},
        {name: "Email", sortable: true, cell: (row) => row.email},
        {name: "Worker Name", sortable: true, cell: (row) => row.workerName},
        // {name: "Worker TextId ", sortable: true, cell: (row) => row.workerTextId},
        {name: "Status", sortable: true, cell: (row) => <span className={`status ${row.status}`}> {row.status}</span>},
        {name: "Status", sortable: true, cell: (row) => <>
                <Form.Select aria-label={`${row.status}`} onChange={(e) => {
                    const formData = {
                        status: e.target.value,
                        workerTextId: row.workerTextId,
                        franchiseTextId: row.franchiseTextId,
                        categoryTitle: row.categoryTitle,
                        categoryTextId: row.categoryTextId,
                    }
                    const approval = window.confirm("Are you sure you want to change the status?")
                    if (approval) {
                        const res = dispatch(changeCategoryRequestStatus(formData))
                        if(res){dispatch(fetchCategoryRequest())}
                    }
                }}>
                    <option selected disabled>{row.status}</option>
                    <option value='Pending'>Pending</option>
                    <option value="Approved">Approved</option>
                    <option value='Rejected'>Rejected</option>
                    <option value='Pause'>Pause</option>
                </Form.Select>
            </>
        },
        // {name: "Update", cell: (row) => <Link to={`/provider/${row.workerTextId}`}
        //                                       className="btn btn-default"> Details</Link>},
    
    ]

    useEffect(() => {
        let res=dispatch(fetchCategoryRequest())
        if(res){
            dispatch(breadcrumbTree({'Item':'request', ' Category ':'category'}));
            document.title = 'Provider Listing';
        }
    }, [dispatch])
    let tableData = allRequest;

    let filteredProvider = allRequest.filter(item => item['status']?.toLowerCase().includes(filterByStatus.toLowerCase()))
    tableData = filteredProvider.filter(item => item['categoryTitle']?.toLowerCase().includes(query.toLowerCase()))
    let tableData2 = filteredProvider.filter(item => item['workerName']?.toLowerCase().includes(query.toLowerCase()))
    let tableData3 = filteredProvider.filter(item => item['workerTextId']?.toLowerCase().includes(query.toLowerCase()))
    const uniqueData = filterDataTable3Col('requestCategoryId',tableData, tableData2, tableData3);

  return (
    <Layout page_title={'Category Request List '}
     component={
        <>
       {loading && <LoadingComponent message={''}/>}
    {/*{!teamMembers && error ? <div>Error: {error}</div> : null}*/}
    {!loading ?
        <div className='data-table-wrapper order-table'>
 

                            <div className="w-[100%] flex gap-2.5 relative mb-3">
                                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                                    <input name="search" placeholder={'Search by Services Title or customer...'}
                                           type="text"
                                           className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                                           value={query ? query : ''}
                                           onChange={(e) => {
                                               setQuery(e.target.value)
                                           }}/>
                                    <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search"
                                                                                          aria-hidden="true"></i>
                                    </div>
                                    <div
                                        className="export-btn absolute right-0 top">
                                        <ExportFile data={uniqueData} fileName={'Category-request-list'}/>
                                    </div>
                                </div>

                            </div>
       <CustomDataTable data={uniqueData} columns={columns}/>
        </div>
  : null }
  </>
    } top_content={
            <div className="search-header-area"> 
                    <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                                 value={filterByStatus} onChange={(e) => {
                        setFilterByStatus(e.target.value);
                        
                    }}>
                        <option value=''>Filter By Status</option> 
                        <option value='Pending'>Pending</option>
                        <option value="Approved">Approved</option>
                        <option value='Rejected'>Rejected</option>
                        <option value='Pause'>Pause</option>
                    </Form.Select>

                    {/* <DatePicker
                showIcon
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
            />  */}
                </div>

                
          
        }
         ></Layout>
  )
}

export default CategoryRequestList