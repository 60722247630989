import React from 'react';
// import { Card, Button } from 'react-bootstrap';
import {CSVLink} from "react-csv";
// function keyToTitle(str) {
//       const result = str.replace(/([a-z])([A-Z])/g, '$1 $2');
//     return result.charAt(0).toUpperCase() + result.slice(1);
// }
const ExportFile = ({ data, fileName, headersData }) => {
    const jsonObject = [];
    let header=''
    // const headers= [];
    if (data) {
        data.map((order ,index) => {
            if(index ===0){
                header = Object.keys(order) 
            }
            jsonObject.push(Object.values(order));
        });
       
        
    }
 
    //  if(header){header.map((item) =>  headers.push({ "label": keyToTitle(item), "key": item }));}
 
// console.log(headers)
// console.log(headersData)
    return (
        <CSVLink data={jsonObject} filename={`${fileName}.csv`} headers={header}>
            <div className=' px-6 py-2.5 bg-neutral-100 rounded-lg justify-center items-center gap-2 flex'>
            <i className="w-5 h-5 relative fa fa-download" aria-hidden="true"></i>
            <button className="text-neutral-600 text-base font-semibold font-['Inter']">Export
            </button>
            </div>
        </CSVLink>
    );
};

export default ExportFile;

//
// const headers = ['Order Id','User Name','EndUser Id' ]
// const jsonObject = [];
// if (uniqueData) {
//     // Select only the desired properties for each order
//     data.map((order, index) => {
//         const filteredOrder = {
//             orderTextId: order.orderTextId,
//             userName: order.userName,
//             endUserTextId: order.endUserTextId
//         };
//
//         jsonObject.push(Object.values(filteredOrder));
//     });
// }
