import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import category from "../assets/images/category.png";
import {useDispatch, useSelector} from "react-redux";
import {pageTitle} from "../reducers/LocalDataSlice";
 
import React, {useEffect, useState} from "react";

export default function NotDataFound(data) {
 
    return (
        
        <div className="w-[100%] h-[250px] bg-white rounded-xl justify-center items-center inline-flex">
            
            <div className="text-black text-lg font-medium font-['Inter']"> There are no records to display.........</div>
        </div> 

        // <div>
        //     <img className="logo" src={`${logo}`} /> 
        //     <strong>No Results Found</strong>
        //  <div className='no-data'> Data not available now</div>
        //  </div>
    )
}