import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../../components/CustomDataTable";
import CityZipForm from "./CityZipForm";
import {
  fetchCityZip,
  createCityZip,
  updateCityZip,
  deleteCityZip,
} from "../../reducers/CityZipSlice";
import { Button } from "react-bootstrap";
import Layout from "../../components/Layout";

const CityZipList = () => {
  const feachData = useSelector((state) => state.cityZip);
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState('');
  const handleClose = () => {
  setShow(false)
  setCityZipData(() => ({update: false}))};
  const handleShow = () => setShow(true);
  
    

  let tableData =feachData.cityZip;
  tableData = feachData.cityZip.filter(item=>item.cityTextId.toLowerCase().includes(query.toLowerCase())) 
  const dispatch = useDispatch();


  const [cityZipData, setCityZipData] = useState({
    update: false,
    id: null,
    cityTextId: null,
    rank: null,
    zip: null,
  });

 
 

  useEffect(() => {
    dispatch(fetchCityZip());
  }, [dispatch]);

  const columns = [
   
    { name: "ID", sortable: true, cell: (row) => row.id },
    { name: "CityTextId", sortable: true, cell: (row) => row.cityTextId },
    // { name: "AreaTextId", sortable: true, cell: (row) => row.areaTextId },
    { name: "Zip", sortable: true, cell: (row) => row.zip },
    {
      name: "Update",
      cell: (row) => (
        <Button
          onClick={(e) => {
           let localData = null;
           feachData.cityZip.map((e, index)=>{if(e.id === row.id) {return localData = index; }});

            setCityZipData((cityZipData) => ({
              ...cityZipData,
              update: true,
              id: row.id,
              cityTextId: row.cityTextId,
              // areaTextId: row.areaTextId,
              zip: row.zip,
              localData:localData
            }));
            setShow(true);
            // console.log(e)
          }}
        >
          Update
        </Button>
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <Button
          variant="danger"
          onClick={() => {
            const approval = window.confirm("Are you sure you want to delete?");
            if (approval === true) {
              
                let updateIndex = '';
                feachData.cityZip.map((e, index)=>{
                  if(e.id === row.id) {return updateIndex = index;}
                });
                const data ={};
                data.id =  row.id;
                data.localData = updateIndex.toString();
 
              dispatch(deleteCityZip(data));
              // window.location.reload();
            }
          }}
        >
          Delete
        </Button>
      ),
    },
  ];
 

  return (
    <Layout
      component={
        <>
        <div className="search-header-area">
         <Button variant="primary" onClick={handleShow}> Add CityZip </Button>
        <input name="search" placeholder="Search by City" type="text" className="form-control" value={query? query : ''} onChange={(e)=> setQuery(e.target.value)}/>
        </div>
          {query && <span>{query}</span>}
          {show && <CityZipForm data={cityZipData} close={handleClose} show={show} />}
          <br />
          {feachData.loading && <div>Loading ...</div>}
          {feachData.error ? <div className="alert alert-danger">Error: {feachData.error}</div> : null}
          {typeof feachData.success!='undefined' && feachData.success===true?<div className="alert alert-success">Success: Your Request has been successfully updated</div>:null}
           
          {!feachData.loading ? (
            <div className="section-single-column zip-code"><CustomDataTable data={tableData} columns={columns} /></div>
          ) : null}
        </>
      }
    ></Layout>
  );
};

export default CityZipList;
