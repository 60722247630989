import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CityForm from "./CityForm";
import ManageZipForm from "./ManageZipForm";
import { useDispatch, useSelector } from "react-redux";
import { citySchema } from "../../schemas/city";
// import { userSchema } from "./schemas/city";
import {
  createCity,
  deleteCity,
  fetchCity,
  updateCity,
  removeSuccessMessage
} from "../../reducers/CitySlice";

import CustomDataTable from "../../components/CustomDataTable";
import Layout from "../../components/Layout";

const CityList = () => {
  const [show, setShow] = useState(false);
  const [zipShow, setZipShow] = useState(false);
  const [query, setQuery] = useState('');
  const [alertSuccess, setAlertSuccess] = useState(false);
  const handleShow = () => setShow(true);

  const manageZipClose = () => setZipShow(false);
  const manageZipShow = () => setZipShow(true);

  const feachData = useSelector((state) => state.city);
  const dispatch = useDispatch();
  let tableData = feachData.cities;
  const handleClose = () => {
    setShow(false)
   setCityData(() => ({
              update: false
            }))};

//  if(typeof feachData.success!='undefined' && feachData.success===true) {
//   setTimeout(dispatch(removeSuccessMessage(false)), 800);
//   }
 
  tableData = feachData.cities.filter(item=>item.title.toLowerCase().includes(query.toLowerCase())) 

  const [cityData, setCityData] = useState({
    update: false,
    id: null,
    textId: null,
    title: null,
    // areaTextId: null,
    preBufferHour: null,
    priceVariationJson: null,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    // alert("citi ma console");
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    const isValid = await citySchema.isValid(data);

    console.log(isValid);
    if(isValid){
    dispatch(createCity(data));
    handleClose(true);}
    // window.location.reload();
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData);
    
  let updateIndex = '';
  feachData.cities.map((e, index)=>{
    if(e.id === cityData.id) {return updateIndex = index;}
   
  });
 
    data.localData =  updateIndex;
 
    dispatch(updateCity(data));
      handleClose(true);
      
  };

  useEffect(() => {
    dispatch(fetchCity());
  }, []);

  const columns = [
    { name: "ID", sortable: true, cell: (row) => row.id },
    { name: "TextId", sortable: true, cell: (row) => row.textId },
    { name: "Title", sortable: true, cell: (row) => row.title },
    // { name: "AreaTextId", sortable: true, cell: (row) => row.areaTextId },
    {
      name: "PriceVariationJson",
      sortable: true,
      cell: (row) => row.priceVariationJson,
    },
    { name: "PreBufferHour", sortable: true, cell: (row) => row.preBufferHour },
    { name: "Created", sortable: true, cell: (row) => row.created },
    {
      name: "Update",
      cell: (row) => (
        <Button
          onClick={() => {
              let localData = null;
           feachData.cities.map((e, index)=>{ if(e.id === row.id) {return localData = index; }});
          setCityData((cityData) => ({
              ...cityData,
              update: true,
              id: row.id,
              textId: row.textId,
              title: row.title,
              // areaTextId: row.areaTextId,
              preBufferHour: row.preBufferHour,
              priceVariationJson: row.priceVariationJson,
              localData:localData
            }));
            setShow(true);
          }}
        >
          Update
        </Button>
      ),
    },
    //  {
    //   name: "Manage",
    //   cell: (row) => (
    //     <Button
    //       onClick={() => {
    //         setCityData((cityData) => ({
    //           ...cityData,
    //           update: true,
    //           id: row.id,
    //           textId: row.textId,
    //           title: row.title,
    //         }));
    //          setZipShow(true);
    //       }}
    //     >
    //       manage
    //     </Button>
    //   ),
    // },
    {
      name: "Delete",
      cell: (row) => (
        <Button
          variant="danger"
          onClick={() => {
            const approval = window.confirm("Are you sure you want to delete?");
            if (approval === true) {
              
                let updateIndex = null;
                feachData.cities.map((e, index)=>{
                  if(e.textId === row.textId) {return updateIndex = index;}
                });
                const data ={};
                data.textId =  row.textId;
                data.localData =  updateIndex;
 
              dispatch(deleteCity(data));
            }
          }}
        >
          Delete
        </Button>
      ),
    },
  ];
  return (
    <Layout
      component={
        <> 
           <div className="search-header-area">
          <Button variant="primary" onClick={handleShow}> Add City </Button>
        <input name="search" placeholder="Search by City Title" type="text" className="form-control" value={query? query : ''} onChange={(e)=> setQuery(e.target.value)}/>
        </div>
         {query && <span>{query}</span>}
{show && <CityForm data={cityData} close={handleClose} handleSubmit={cityData.update ? handleUpdate : handleSubmit} show={show}/>}

{show && <ManageZipForm cityData={cityData} manageZipClose={manageZipClose} handleSubmit={cityData.update ? handleUpdate : handleSubmit} zipShow={zipShow}/> }
 
          {feachData.loading && <div>Loading ...</div>}
          {feachData.error ? <div className="alert alert-danger">Error: {feachData.error}</div> : null}
          {typeof feachData.success!='undefined' && feachData.success===true?<div className="alert alert-success">Success: Your Request has been successfully updated</div>:null}
          {!feachData.loading ? (
          
            <div className="section-single-column city-data"><CustomDataTable data={tableData} columns={columns} /></div>
          ) : null}
        </>
      }
    ></Layout>
  );
};

export default CityList;
