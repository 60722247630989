import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import {fetchProfile, changeTeamMemberStatus, fetchCategoryServiceWithAreas, } from '../../reducers/TeamMemberSlice'
import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import {Link, useParams} from "react-router-dom";
import CustomModelComponent from "../../component/CustomModelComponent";
import CustomModal from "../../modal/CustomModal";
import SuccessModalComponent from "../../modal/SuccessModalComponent";
import HeaderComponent from "../../component/provider/HeaderComponent";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import GeneralInfo from "../../component/provider/GeneralInfo";
import UserOrders from "../../component/provider/ProviderOrders";
import CategoryRequested from "../../component/provider/CategoryRequested";
import BusinessInfo from "../../component/provider/BusinessInfo";
// import {item} from

const ProfileDetails = () => {
    const [activeTab, setActiveTab] = useState('profile')
    const {profile, loading, error} = useSelector((state) => state.teamMembers)
    const dispatch = useDispatch()
    const {item} = useParams()
    const handleClick = (e) => setActiveTab(e);
    const handleNext = (e) => setActiveTab('category');
    const handleNextInfo = (e) => setActiveTab('info');


    useEffect(() => {
        dispatch(breadcrumbTree({}));
        dispatch(fetchProfile(item))
        dispatch(fetchCategoryServiceWithAreas(item))
    }, [dispatch])

    // console.log(profile.photoIdExpirationDate)
    return (
        <Layout page_title={''}
                component={
                    <>
                           <div className="card-body">

                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">Full Name</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 <div className="text-muted mb-0">{profile.firstName} {profile.lastName}</div>
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">Email</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 <div className="text-muted mb-0">{profile.email}</div>
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">Phone</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 <div className="text-muted mb-0">{profile.phone}</div>
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">Present Status</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 <div className="text-muted mb-0">{profile.status}</div>
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">country Code</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 <div className="text-muted mb-0">{profile.countryCode}</div>
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">photo Id Type</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 <div className="text-muted mb-0">{profile.photoIdType}</div>
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">photo Id No</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 <div className="text-muted mb-0">{profile.photoIdNo}</div>
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">photo Id Expiration Date</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 <div className="text-muted mb-0">{profile.photoIdExpirationDate}</div>
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">Address</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 {profile.addressJsonWhileVerification && <div
                                                     className="text-muted mb-0"> {Object.keys(profile.addressJsonWhileVerification).map((key) => (
                                                     <div key={key}>
                                                         {`${key}: ${profile.addressJsonWhileVerification[key]}`}
                                                     </div>
                                                 ))}</div>}
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">employeeType</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 {profile.employeeType}
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">created Data</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 {profile.created}
                                             </div>
                                         </div>
                                         <div className="row hr">
                                             <div className="col-sm-3">
                                                 <div className="mb-0">status</div>
                                             </div>
                                             <div className="col-sm-9">
                                                 <Form.Select aria-label={`${profile.status}`} onChange={(e) => {
                                                     const formData = {
                                                         textId: profile.textId,
                                                         status: e.target.value
                                                     }
                                                     const approval = window.confirm("Are you sure you want to change the status?")
                                                     if (approval) {
                                                         dispatch(changeTeamMemberStatus(formData))

                                                     }
                                                 }}>

                                                     <option defaultValue={profile.status}>{profile.status}</option>
                                                     <option value='SSN Required'>SSN Required</option>
                                                     <option value='Selfie  Required'>PSelfie Required</option>
                                                     <option value="Mailing Address Required">Mailing Address Required</option>
                                                     <option value="Processing">Processing</option>
                                                     <option value="Verified">Verified</option>
                                                     <option value="Pause">Pause</option>
                                                 </Form.Select>
                                             </div>
                                         </div>
                                         {/*<CustomModal />*/}
                                         {/*<SuccessModalComponent />*/}
                                     </div>
                    </>
                }></Layout>
    );
}

export default ProfileDetails;