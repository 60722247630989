import {Form, Modal, Button, Image} from "react-bootstrap";
import Layout from '../../components/Layout';
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
import { serviceSchema} from "../../schemas/service";
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {useFormik} from "formik";
import {breadcrumbTree} from '../../reducers/LocalDataSlice'
import {fetchServices, createService} from "../../reducers/ServiceItemSlice";
import {appendNewServices, fetchServiceWithCategory, successConfirmation} from '../../reducers/CategorySlice';
import ServicePlanAddComponent from "../../component/ServicePlanAddComponent";
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import QuichView from './component/QuichView';
import SubItemsList from './component/SubItemsList'
import SubCategotyList from './component/SubCategotyList'
 


const ServiceAddForm = ({}) => {
    // const {loading, error, categories, serviceList, configuration, title} = useSelector((state) => state.category)
    const {configuration_data, loading, error, success} = useSelector((state) => state.serviceItem);
    const [breadcrumb, setBreadcrumb] = useState(false);
    const [iamgeError, setIamgeError] = useState(false);
    const [activePlan, setActivePlan] = useState('perUnit');
    const [selected, setSelected] = useState(false);
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState('');
    const [thumbIcon, setIcon] = useState(false);
    const [thumbImage, setImage] = useState(false);
    const [subItemTextId, setSubItemTextId] = useState(false);
    const [subItemTitle, setSubItemTitle] = useState(false);
    const [ defaultData, setDefaultData] = useState(configuration_data);

    // let location = useLocation();
    // const loca = location.pathname.split("/");
    // const token = localStorage.getItem("token")
    const {item, category} = useParams();
    const navigate = useNavigate();
    const [clickCount, setClickCount] = useState(0);

    const [successModal, setSuccessModal] = useState(false);
    const handleSuccess = ()=>dispatch(successConfirmation());
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        handleSuccess()
        // console.log('successModal', successModal)
        navigate(successModal);
    };
    const closeModal = () => {
        setSubItemTextId(false)
        setSubItemTitle(false)
    };    
    const hadelQuickView = (param) => {
        setSubItemTextId(param.textId)
        setSubItemTitle(param.title)
    };

    const handleSub_items = (item,checked) => { 
        if(checked){
            setValues({...values, sub_items:[...values.sub_items,item]});
          
        }
        else{
            setValues({ ...values, sub_items: values.sub_items.filter(val => val !== item) });
        }
        
        return handleChange;
  };
    const handleSub_categoty = (item, checked) => {
        // setValues({...values, sub_categories:[...values.sub_categories,item]});
        // defaultData.category_list.
        // setDefaultData({ ...defaultData.category_list, checked: defaultData.category_list.filter(val => val.textId === item.textId) });
         if(checked){
            setValues({...values, sub_categories:[...values.sub_categories,item]});
        }
        else{
            setValues({ ...values, sub_categories: values.sub_categories.filter(val => val !== item) });
        }
        return handleChange;
    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const imageType = /^image\//;
        if (imageType.test(file.type)) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImage(e.target.result);
            };

            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };


        const handleSetValues = (id, details) => {
            setValues({ ...values, [id]:details});
        };


 
    const {values, errors,  handleChange, handleSubmit, setErrors, setValues} = useFormik({

        initialValues: {
            // serviceItemTextId: item,
            attributeGroupTextId: item,
            sub_categories: [],
            title: null,
            rank: null,
            status: null,
            shortDescription: null,
            service_plan:null,
            sub_items: [],
            pricingBy: 'perUnit',
            image: null,
            areaSizeUnit: null,
            // Bundle: 'Bundle Short Description',
        },

        onSubmit: async (values, action) => {
            // const dataImage = {}
            // console.log('submit::', values.sub_items.length, values.sub_categories.length)
            
            if(values.sub_items.length>0){
            const res = dispatch(createService(values));
            res.then((results)=> {
              
                if(results.error){
                    console.log('results', results.error)
                }else {
                    openSuccessModal(`/service/${item}`);
                }
            }).catch(error => {
                console.error('Error:', error);
            });
        }else {
         
            // setErrors({...errors,sub_categories:'Select a Sub Category',sub_items:'Select a Configuration'})
        if(values.sub_items.length<1){
            setErrors({...errors, sub_items:'Select a Configuration'})
        }
        
        // if(values.sub_categories.length<1){
        //     setErrors({...errors, sub_categories:'Select a Sub Category'})
        // }            
        }
 
        },
        
         validationSchema: serviceSchema,

    });
    // if(values.sub_categories.length ===0){
    //     setErrors({...errors,sub_categories:'Select a Sub Category'})
    // }
    // if(values.sub_items.length ===0){
    //     setErrors({...errors,sub_items:'Select a Configuration'})
    // }
    // console.log("🚀 defaultData:", defaultData)
    // console.log("🚀 configuration_data:", configuration_data)
    // console.log("🚀 sub_items:", values.sub_items)
    // // console.log("🚀 values:", values)
    // console.log("🚀 errors:", errors)


    useEffect(() => {
        // let url = (loca.length > 3 ? loca[loca.length - 1] : 'root')
        dispatch(fetchServices(`${item}`))
        document.title = ''
        dispatch(breadcrumbTree({
            'Service ': 'service',
            // [title]: subItem
        }));

    }, [dispatch])
    // if (title) {
    //     document.title = ''
    //     dispatch(breadcrumbTree({
    //         'Service ': 'service',
    //         [title]: subItem
    //     }));
    // }

    return (
        <Layout component={
            <>
                <Form onSubmit={handleSubmit} encType="multipart/form-data" className="form-data">
                    <div className="section-two-column add-service">
                        <div className="left-block w100-200"> 
                            <div className="field-row"><label className="label">Service Name</label>
                                <Form.Control type="text" name="title" placeholder="Service Name" value={values.title}
                                              onChange={handleChange}></Form.Control>
                                {errors.title && <p className="red">{errors.title}</p>}
                            </div>

                            <div className="field-row"><label className="label">Service Description</label>
                                <textarea className="form-control description" name="shortDescription"
                                          value={values.shortDescription}
                                          onChange={handleChange} rows="4" cols="50"></textarea>
                                {/*<CKEditor editor={ClassicEditor} details={editorData} onChange={handleChangea}/>*/}
                                {errors.shortDescription && <p className="red">{errors.shortDescription}</p>}
                            </div>


                            <div className="pricing-tab">
                                <div className="input-area">
                                    <strong className="field-label">Pricing By</strong>
                                    <div className="form-group">
                                        <label htmlFor='perUnit'
                                                className={` form-check-inline ${activePlan === 'perUnit' && 'active'}`}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id="perUnit"
                                                checked={activePlan === 'perUnit'}
                                                name="pricingBy"
                                                value="perUnit"
                                                onChange={(e) => {
                                                    setActivePlan('perUnit');
                                                    setValues({
                                                        ...values,
                                                        pricingBy: e.target.value,
                                                        service_plan:null,
                                                        areaSizeUnit: null
                                                    });
                                                }}/>

                                            <span className="form-check-label">Per Unit</span>
                                        </label>

                                        <label htmlFor='areaSize'
                                                className={`form-check-inline areaSize ${activePlan === 'areaSize' && 'active'}`}>
                                            <input className="form-check-input" type="radio" id='areaSize'
                                                    name='pricingBy' value='areaSize'
                                                    checked={activePlan === 'areaSize'}
                                                    onChange={(e) => {
                                                        setActivePlan('areaSize');
                                                        setValues({...values, pricingBy: e.target.value,service_plan:null});
                                                    }}/>
                                            <span className="form-check-label">Area / Size</span>
                                        </label>

                                        <label htmlFor='bundle'
                                                className={`form-check-inline ${activePlan === 'bundle' && 'active'}`}>

                                            <input className="form-check-input" type="radio" id='bundle'
                                                    name='pricingBy' value='bundle'
                                                    checked={activePlan === 'bundle'}
                                                    onChange={(e) => {
                                                        setActivePlan('bundle');
                                                        setValues({
                                                            ...values,
                                                            pricingBy: e.target.value,
                                                            service_plan:[],
                                                            areaSizeUnit: null
                                                        });
                                                    }}/>
                                            <span className="form-check-label">Bundle</span>
                                        </label>
                                    </div>
                                    {activePlan === 'areaSize' && <div className='size-type'>
                                            <label htmlFor='sqft' className={`form-check-inline ${selected ==='sqft' && 'selecetd'}`}>
                                            <input className="form-check-input" type="radio" id='sqft'
                                                name='areaSizeUnit' value='sqft'
                                                checked={selected ==='sqft'}
                                                onChange={(e)=>{
                                                setSelected('sqft');
                                                setValues({ ...values, areaSizeUnit: e.target.value });
                                            }}/>

                                            <span className="form-check-label">Sqft</span>
                                        </label>
                                        <label htmlFor='Sqmt' className={`form-check-inline ${selected==='Sqmt' && 'selecetd'}`}>
                                            <input className="form-check-input" type="radio" id='Sqmt'
                                                    name='areaSizeUnit' value='Sqmt'
                                                    checked={selected==='Sqmt'}
                                                    onChange={(e)=>{
                                                    setSelected('Sqmt');
                                                    setValues({
                                                            ...values,
                                                            areaSizeUnit: e.target.value
                                                        });
                                                    
                                                    }}/>
                                            <span className="form-check-label">Sqmt</span>
                                        </label>
                                    </div>}
                                </div>
                                 
                            </div>
                            <div className="dragged-form-item">
                                {activePlan !=='bundle' &&
                          
                                <div className="form-item">
                                    <div className="input-area">
                                        <div className="add-new-level">
                                            <strong className="field-label">Service Plan</strong>
                                            <span className='add-new-plan' onClick={() => {
                                                setClickCount((prevCount) => prevCount + 1);
                                            }}><i className="fa fa-plus-circle"></i> Add New</span>
                                        </div>

                                        <div className="level bronze">

                                            {success &&
                                                configuration_data.service_plan.length > 0 &&
                                                configuration_data.service_plan.map((item) => {
                                                    return item.isDefault && (
                                                        <div className="service-plan" key={item.textId}>
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="service_plan"
                                                                        id={item.textId}
                                                                        value={item.textId}
                                                                        onChange={handleChange}
                                                                    />

                                                                    <label className="form-check-label"
                                                                           htmlFor={item.textId}>
                                                                        {item.title}
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div className="input-area">
                                                                <strong
                                                                    className="field-label desc">Description <span>*</span></strong>
                                                                <textarea
                                                                    id={item.textId}
                                                                    name={item.textId}
                                                                    placeholder="Type description here..."
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                               

                                            {Array.from({length: clickCount}).map((_, index) => (
                                                // <AppendedComponent key={index} />
                                                <ServicePlanAddComponent key={index} handleChange={handleChange} handleSetValues={handleSetValues}/>
                                            ))}

                                        </div>

                                    </div>
                                </div>}
                             
                                {subItemTextId && <QuichView subItem={subItemTextId} subItemTextTitle={subItemTitle} close={closeModal}/>}
                            </div>
                        </div>
                        <div className="right-block w100-200">
                            <div className="items-category">
                                <div className='title'>Select Service Items </div>

                                <div className="form-item category-items">

                                    {/* <ol className="form-item category-items">
                                        {configuration_data && configuration_data.sub_items.length > 0 && configuration_data.sub_items.map((item, index) => (
                                            <li className="form-row layer1">
                                                <input className="form-check" type="checkbox" id={`index${index}`} name='sub_items' value={item.textId} onChange={handleChange} />
                                                <label htmlFor={`index${index}`}>{item.title}</label>
                                                <div className="view" onClick={()=>{setSubItemTextId(item.textId);setSubItemTitle(item.title);}}> <i className="fa fa-eye" aria-hidden="true"></i> View  </div>
                                                {item.subItems.length>0 && <ol>
                                                    {item.subItems.map((layer1, index) => (
                                                        <li className="form-row layer2">
                                                            <input className="form-check" type="checkbox" id={`index${index}`} name='sub_items' value={layer1.textId} onChange={handleChange} />
                                                            <label htmlFor={`index${index}`}>{layer1.title}</label>
                                                            <div className="view" onClick={()=>{setSubItemTextId(layer1.textId);setSubItemTitle(layer1.title);}}> <i className="fa fa-eye" aria-hidden="true"></i> View  </div>
                                                            {layer1.subItems.length>0 && <ol>
                                                                {layer1.subItems.map((layer2, index) => (
                                                                    <li className="form-row layer3">
                                                                        <input className="form-check" type="checkbox" id={`index${index}`}
                                                                        name='sub_items' value={layer2.textId} onChange={handleChange} />
                                                                        <label htmlFor={`index${index}`}>{layer2.title}</label>
                                                                        <div className="view" onClick={()=>{setSubItemTextId(layer2.textId);setSubItemTitle(layer2.title);}}> <i className="fa fa-eye" aria-hidden="true"></i> View  </div>
                                                                        {layer2.subItems.length>0 && <ol>
                                                                            {layer2.subItems.map((layer3, index) => (
                                                                                <li className="form-row layer4">
                                                                                    <input className="form-check" type="checkbox" id={`index${index}`} name='sub_items' value={layer3.textId} onChange={handleChange} />
                                                                                    <label htmlFor={`index${index}`}>{layer3.title}</label>
                                                                                    <div className="view" onClick={()=>{setSubItemTextId(layer3.textId);setSubItemTitle(layer3.title);}}> <i className="fa fa-eye" aria-hidden="true"></i> View  </div>
                                                                                    </li>))}
                                                                        </ol>}
                                                                    </li>))}
                                                    </ol>}
                                                    </li>))}      
                                        </ol>}
                                        </li>))}
                                        {errors.sub_items && <p className="red">{errors.sub_items}</p>}
                                    </ol> */}

                                    {configuration_data && configuration_data.sub_items.length > 0 && <SubItemsList data={configuration_data.sub_items} values={values.sub_items} hadelQuickView={hadelQuickView} closeModal={closeModal} handleSub_items={handleSub_items}/>}

                                    {/* {errors.sub_items && <p className="red">{errors.sub_items}</p>} */}
                                    {errors.sub_items && <p className="red">{errors.sub_items}</p>}
                                </div> 
                            </div>
                            {/* <div className="items-category">
                                <div className='title'>Select Category </div>

                                <div className="form-item category-items">
                                    {configuration_data && configuration_data.category_list.length > 0 && <SubCategotyList data={configuration_data.category_list} values={values.sub_categories} category={category} closeModal={closeModal} handleSub_categoty={handleSub_categoty}/>}
                                    {errors.sub_categories && <p className="red">{errors.sub_categories}</p>}
                                </div>
                                </div> */}

                            <div className="category">
                            <strong className="title">Service Image</strong>
                            {!thumbImage && !values.image &&<span className="info">Add image for the service item</span>}
                            
                            <div className="img-view" style={{backgroundImage: `url(${thumbImage?thumbImage:(`${BASE_URL}/${values.image}`)})`}}> 
                            
                                <label id="drop-area" className="drop-image">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                     
                                <input type="file" hidden name="image" onChange={(e) => {
                                    handleImageChange(e);
                                    setValues({...values, image: e.target.files[0],});
                                    return handleChange
                                }} accept="image/*"/>
                                </label>

                                
                                        {!thumbImage && !values.image && <><div className="canvas"><img src={uploadImgIcon} alt=""/></div>
                                        <span className="drag-drop">Drop image here, or browse</span><br />
                                    <span className="support-image">Support: JPG, JPEG, PNG</span></>}
                                    
                            </div>
                       
                        </div>

                            {/* <div className="category">
                                <strong className="title">Service Image</strong>
                                <span className="info">Add image for the service </span>
                                <label id="drop-area">
                                    <input type="file" hidden name="image" onChange={(e) => {
                                        handleImageChange(e);
                                        setValues({...values, image: e.target.files[0],});
                                        return handleChange
                                    }} accept="image/*"/>
                                    <div className="img-view">

                                        <div className="canvas"><img src={thumbImage ? thumbImage : uploadImgIcon}
                                                                     alt=""/>
                                        </div>

                                        <span className="drag-drop">Drop image here, or browse</span>
                                        <span className="support-image">Support: JPG, JPEG, PNG</span>
                                    </div>
                                </label>

                            </div> */}


                        </div>


                        <div className="save-option">
                            <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft
                            </button>
                            <button className="save-btn">Save</button>
                        </div>

                    </div>
                </Form>
                {loading && <LoadingComponent message={''}/>}
                {error && <ErrorComponent close={handleSuccess}/>}
                {successModal && <SuccessComponent close={closeSuccessModal}/>}
            </>}></Layout>

    );
};

export default ServiceAddForm;
