import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    permissions: [],
    error: ''
}

const url = `${BASE_URL}/admin/user/permission`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchPermissions = createAsyncThunk(
    'fetch/permissions', async () => {
        return axios
        .get(url, config)
        .then((response) => response.data)
    }
)


const permissionsSlice = createSlice({
    name: "permissions",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload;
        state.error = "";
      });
      builder.addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false;
        state.permissions = [];
        state.error = action.error;
      });
    },
  });
  


export default permissionsSlice.reducer


export const createPermission = createAsyncThunk(
  "create/permissions", async (formData) => {
    return axios
    .post(url, formData, config)
    .then((response) => response.data)
  }
)

export const updatePermission = createAsyncThunk(
  "update/permissions", async (formData) => {
    return axios
    .put(`${url}/${formData.id}`, formData, config)
    .then((response) => response.data)
  }
)


export const deletePermission = createAsyncThunk(
  "delete/permissions", async (id) => {
    return axios
    .delete(`${url}/${id}`, config)
    .then((response) => response.data)
  }
)