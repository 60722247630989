import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
// import CustomDataTable from '../../components/CustomDataTable';
import {Button, Image} from 'react-bootstrap';
// import CategoryAddForm from './CategoryAddForm'; 
import AreaConfiguration from './AreaConfiguration';
// import FormBuilder from './FormBuilder';
import {BASE_URL} from '../../BaseUrl';
// import NavBarUpdate from "./NavBarUpdate";
import Layout from '../../components/Layout';
// import ServiceItemConfiguration from './ServiceItemConfiguration';
import {
    successConfirmation, fetchItemWiseService
} from '../../reducers/ServiceSlice';
import {breadcrumbTree} from '../../reducers/LocalDataSlice';
import LoadingComponent from "../../modal/LoadingComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import CardViewComponent from "../../component/items/CardViewComponent";
import ServiceCardViewComponent from "../../component/items/ServiceCardViewComponent";
import {getZipStatus} from '../../utility/GetStatusName';


const ServiceListByItemPage = () => {
    // const {categories, serviceList, loading, error, success, itemAddStatus} = useSelector((state) => state.category)
    const {services, loading, error, success} = useSelector((state) => state.service)
    const [serviceTextId, setServiceTextId] = useState(false);
    const param  = useParams();
    const navigate = useNavigate();
    const item = param.item;
    const dispatch = useDispatch()

 

    const handleSuccess = () => dispatch(successConfirmation());
  
    let {location ,state} = useLocation();
    // const loca = location.pathname.split("/");
    // const textId = loca[loca.length - 1];
    // const personaTitle = state.item.title;
    console.log(state)
    
    let navTree=[];
    
    if(state!==null){const { title, textId } = state; navTree = {...state.navTree, [title]: textId };}
    
    
        
    useEffect(() => {
        // let url = (loca.length > 3 ? loca[loca.length - 1] : 'root')
   
        const res = dispatch(fetchItemWiseService(item))
        res.then((results) => {
            // dispatch(fetchServices(`${url}`))
            console.log(results)
            console.log(res)
     
        //   dispatch(breadcrumbTree({'Service Area':''}));
            // dispatch(breadcrumbTree([{'Service ': 'service-area'}, results.payload.pageBreadcrumb]));
            dispatch(breadcrumbTree(state===null?{'Category List':item}:navTree));
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [dispatch, location])


  

    return (
        <>

            <Layout page_title={'Service Listing'} component={
                <>


                    {/* {!serviceTextId && <button onClick={handleCategory}  type="button" className="add-new-btn"> Add New Item</button>} */}
                    <fieldset className='top-btn-area'>

                        {services && services.map((item) => (
                            <>
                                {/*<ServiceCardViewComponent item={item} />*/}
                                <div className="card area" onClick={(e) => {
                                    // setServiceTextId(item.serviceTextId)

                                }} rel={item.serviceTextId}><span className='link'
                                 onClick={() => { navigate(`./zone/${item.serviceTextId}` , {
                                    state: {
                                    title: item.serviceTitle,  
                                    textId: item.serviceTextId,
                                  
                                },
                            })
                        }}>
                            <img src={`${BASE_URL}${item.image}`} className='icon' alt={item.serviceTitle}/>{item.serviceTitle}
                        </span>
                                    <hr/>

                                    <span className='qty'>{item.rank}</span>
                                    <div className={`status-text ${item.status}`}>{item.status}</div>
                                    {/* <div className={`status-text ${item.status}`}>{getZipStatus(item.status)}</div> */}
                                    <input type='text' className='qty' defaultValue={item.rank} disabled/>

                                </div>
                            </>
                        ))}
                    </fieldset>


                    {/*{serviceTextId &&*/}
                    {/*    <AreaConfiguration serviceTextId={serviceTextId} close={handleClose}*/}
                    {/*                       newItemAddStatus={handleConfig}/>}*/}

                    {loading && <LoadingComponent message={''}/>}
                    {/*{success && <SuccessComponent close={handleSuccess}/>}*/}
                    {error && <ErrorComponent close={handleSuccess}/>}

                </>
            }></Layout>
        </>
    )
}

export default ServiceListByItemPage;