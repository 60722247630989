import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../BaseUrl";

const initialState = {
    loading: false,
    allRequest: [],
    profile: '',
    error: ''
}

const url = `${BASE_URL}/settings`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchServiceRequest = createAsyncThunk(
    'fetch/serviceRequest', async () => {
        return axios
            .get(`${url}/workerServiceAcceptReject/`, config)
            .then((response) => response.data)
    }
)


export const changeServiceRequestStatus = createAsyncThunk(
    "update/categoryRequest", async (formData) => {
        console.log(formData)
        return axios
            .post(`${url}/workerServiceAcceptReject/`, [formData], config)
            .then((response) => console.log(response.data))
    }
)

// export const changeServiceRequestStatus = createAsyncThunk(
//     "update/serviceRequest", async (formData) => {
//         return axios
//         .put(`${url}/status/${formData.textId}/`, [formData], config)
//         .then((response) => console.log(response.data))
//     }
// )


const serviceRequestSlice = createSlice({
    name: "ServiceRequest",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchServiceRequest.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchServiceRequest.fulfilled, (state, action) => {
            state.loading = false;
            state.allRequest = [];
            if(action.payload.service_list){
            action.payload.service_list.forEach((item) => {
                if (item.status !== 'Approved') {
                    state.allRequest.push(item);
                }
            });}
            state.error = "";
        });
        builder.addCase(fetchServiceRequest.rejected, (state, action) => {
            state.loading = false;
            state.allRequest = [];
            state.error = action.error;
        });
    },
});


export default serviceRequestSlice.reducer


 
