 
// import Layout from "../../components/Layout";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import ViewControls from './ViewControls'; 
import {quickViewSubItem} from "../../../reducers/ServiceItemSlice";
 
import {useFormik} from "formik";
// import LoadingComponent from "../../modal/LoadingComponent";
// import SuccessComponent from "../../modal/SuccessComponent";
// import ErrorComponent from "../../modal/ErrorComponent"; 

const QuichView = ({close, subItem, subItemTextTitle}) => {
    const {dropitems, isBundleItem, subData, parent_config, loading, error, success} = useSelector((state) => state.formBuilderData)
    const dispatch = useDispatch();
    const [configuration_data, setConfiguration_data] = useState([]);
    const {state, location } = useLocation();
    const [droppedItems, setDroppedItems] = useState([]);
    const {item} = useParams();
    const navigate = useNavigate()
    // const urlArr = location.pathname.split("/");
    const [successModal, setSuccessModal] = useState(false);
  
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        setSuccessModal(false)
        navigate(successModal);
    }; 
 

    useEffect(() => {
        // dispatch(fetchConfiguration(item))
        setConfiguration_data([])
        const res = dispatch(quickViewSubItem(subItem));
        res.then((results) => {   
            setConfiguration_data(results.payload.service_data)
            // alert('ddddddddddd')
            //  setConfiguration_data[...results.pa]
        })
            res.catch((error) => {
            console.error('Promise rejected:', error);
        });
        
    }, [dispatch])

    return (
         <>
         <div id="subItem" className="custom-modal sub-item-configuration">
        <div className="offset-layer" onClick={close}></div>
        <div className="modal-content">
            <div className="modal-header">   
                {/* <h2>Modal for sample</h2> */}
                <span >{subItemTextTitle}</span>
                <span className="close-modal" onClick={close}>&times;</span>
            </div>
            <div className="modal-body">
               
   

            {state && <legend className="config-title">{state.title} </legend>}
                {configuration_data && configuration_data.length > 0 && configuration_data.map((item, index) => (
                    <>
                        <fieldset className="field-builder subitem-manage px">
                            <legend>{item.label}</legend>
 
                         

                            {item.btnName === 'labelField' ? (
                                <>
                                    {item.controls === undefined ? null : item.controls.map((dataItem, dataIndex) => (
                                        <ViewControls data={dataItem} px={''} cx={''}/>
                                    ))}
                                </>
                            ) : null}
                        </fieldset>
                    </>
                ))}
         </div>
            <div className="modal-footer">
                {/* <button type="submit" id="cEmailSentBtn" className="btn btn-default"  > Send</button> */}
                {/* <span className="btn close-modal" onClick={close}> Close  </span> */}
                </div>
        </div>
    </div>
             </>
    );
}

export default QuichView;

