import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    unauthorized: true,
    loading: false,
    data: [],
    notice: [],
 
    count: 0,
    error: ''
}

const url = `${BASE_URL}/settings`

const token = localStorage.getItem("token")
// const textId = localStorage.getItem("textId")

const config = {
    headers: {
        Authorization: `Token ${token}`,
        "content-type": "multipart/form-data",
    }
}


 

export const fetchServiceIdentity = createAsyncThunk(
    'fetch/FetchIdentity', async () => {
        return axios
        .get(`${url}/identity/`, config)
        .then((response) => response.data)
    }
)
 
export const updateIdentity = createAsyncThunk(
    "settings/updateIdentity", async (formData) => {
        if (typeof formData.image !== 'object' || formData.image === null) {
            formData.image = '';
        }
        // console.table(formData)
        const cUrl = `${url}/identity/`
        return axios
            .put(cUrl, formData, config)
            .then((response) => {
                // response.data.updateData = formData;
                return response.data
            })
    }
)

export const createNewIdentity = createAsyncThunk(
    "settings/createIdentity", async (formData) => {
       
        // console.log(formData)
        return axios
            .post(`${url}/identity/`, formData, config)
            .then((response) => response.data
            )
    }
)

export const deleteQuction = createAsyncThunk(
    "delete/identityQuctionDelete", async (formData) => {
      // console.log(formData)
        return axios
            .delete(`${url}/Identity/${formData.id}`,  config)
            .then((response) => formData)
    }
)

   

const identitySlice = createSlice({
    name: "identity",
    initialState,
    reducers: {

        updateBellCount(state, action) {
            state.count = state.count+1;
            // console.log('bell count incrememt!!')
        },
    },
    extraReducers: (builder) => {
       

      builder.addCase(fetchServiceIdentity.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchServiceIdentity.fulfilled, (state, action) => {
        state.loading = false;
         
      //  console.log(action.payload)
        state.data= action.payload.results;
        // state.Identity_category= action.payload.Identity_category;
        state.error = "";
      });
      builder.addCase(fetchServiceIdentity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });


      builder.addCase(createNewIdentity.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createNewIdentity.fulfilled, (state, action) => {
        state.loading = false;
         
      //  console.log(action.payload.results)
        state.data.push(action.payload.results);
        state.error = "";
      });
      builder.addCase(createNewIdentity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });


      builder.addCase(updateIdentity.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateIdentity.fulfilled, (state, action) => {
        state.loading = false;
        state.data[action.payload.results.localData] = action.payload.results;
        state.error = "";
        console.log(action.payload.results)
 
      });
      builder.addCase(updateIdentity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      builder.addCase(deleteQuction.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(deleteQuction.fulfilled, (state, action) => {
        state.loading = false;     
        state.data.splice(action.payload.localData, 1);
        state.error = "";
      });
      builder.addCase(deleteQuction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      

      
    },
  });
  
// export const { updateBellCount } = IdentitySlice.actions;

export default identitySlice.reducer

 
