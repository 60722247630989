import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert  from 'react-bootstrap/Alert';
import { createUser } from '../../reducers/AuthSlice'; 
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
 const arr =[')','(','@','#','$','/','%','|','.','"',"'"]
  const Registration = () => {
const [state, setState] = useState({
    textId: "",
    password: "",
    firstName: "",
    lastName: "",
    countryIso2Code: "",
    phone: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log('length:'+ value.charAt(value.length-1))
    console.log(arr[value.charAt(value.length-1)]+ ' : from arr: '+ value)
    // if(arr.indexOf(value.charAt(value.length-1)) !== -1) {
    //    value = '-';
    //    console.log('iiiiiiiiii')
    // }
    console.log(arr)
    console.log(arr['/'])
    setState((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };
 
  const user = useSelector((state) => state.auth)
  const dispatch = useDispatch();
    // {console.log(user)}
 

      const submit = (event) => {
    event.preventDefault();
     console.log(state);
    // // alert("citi ma console");
    // const formData = new FormData(event.target);
    // const data = Object.fromEntries(formData);
    dispatch(createUser(state));
  
     setState((prevProps) => ({
      ...prevProps,
      textId: "",
    password: "",
    firstName: "",
    lastName: "",
    countryIso2Code: "",
    phone: "",
    }));
  };

  return (
    <Layout
      component={
        <>
      {user.res && <Alert bsStyle="success"><strong> Thank you! </strong>Your account has been successfully created.</Alert>}
  
  <Form onSubmit={submit}>
      <Form.Group className="mb-3" controlId="textId">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" name="textId" placeholder="Enter email" value={state.textId} onChange={handleInputChange}/>
        {state.textId}
      </Form.Group>

      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password"  name="password" placeholder="Password" value={state.password} onChange={handleInputChange}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="firstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control type="text" name="firstName"  placeholder="First Name" value={state.firstName} onChange={handleInputChange}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="lastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control type="text" name="lastName" placeholder="Last Name" value={state.lastName} onChange={handleInputChange}/>
      </Form.Group>

     <Form.Group className="mb-3"  controlId="countryIso2Code" value={state.countryIso2Code} onChange={handleInputChange}>
        <Form.Label>Disabled select menu</Form.Label>
         <Form.Select name="countryIso2Code">
            <option value='US'>US</option>
            <option value="BD">BD</option>
    </Form.Select>
      </Form.Group>

    <Form.Group className="mb-3" controlId="phone">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control type="number" name="phone" placeholder="Phone Number" value={state.phone} onChange={handleInputChange} />
    </Form.Group>

      <Button variant="primary" type="submit"> Submit </Button>
    </Form></>
          
      }
    ></Layout>
  );
}

export default Registration;