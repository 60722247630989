import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as Drag } from '../../assets/images/drag.svg';
const DraggableComponent = ({ btnName, label, dropTitle, labelType, controls, options ,limit, value }) => {
  const [, drag] = useDrag({
    type: 'ROOT', // Define the type here
    item: { btnName, label, labelType, controls, options, value,limit },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <>
    <div className={`${btnName} list ${drag} `} ref={drag}>
      {/* <img src={Drag} /> */}
      <span className="list-name">{dropTitle}</span>
    </div>
    
    {/* <div style={{ border: '1px dashed #000', padding: '10px' }}>
       {label}
    </div> */}
    </>
  );
};

 
export default DraggableComponent;
